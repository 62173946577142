import React from 'react';
import { Profil } from '../../services/OrganizationalService';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';


interface BadgeProps {
  badgecolor: string;
  smallavatarsize: number;
}

const BadgeNotification = styled(Badge)<BadgeProps>(({ badgecolor, smallavatarsize }) => ({
  '& .MuiBadge-badge': {
    color: badgecolor,
    '&::after': {
      position: 'absolute',
      top: -1,
      left: 6,
      width: smallavatarsize,
      height: smallavatarsize,
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(1)',
      opacity: 0,
    },
    '20%': {
      opacity: 1,
    },
    '100%': {
      transform: 'scale(1.8)',
      opacity: 0,
    },
  },
}));

interface SmallAvatarProps {
  smallavatarsize: number;
}

const SmallAvatar = styled(Avatar)<SmallAvatarProps>(({ smallavatarsize, theme }) => ({
  width: smallavatarsize,
  height: smallavatarsize,
  border: `2px solid ${theme.palette.background.paper}`,
}));

interface Props {
  profil: Profil;
  badgeColor: string;
  icon: JSX.Element;
  tooltip: string;
}

export default function CustomBadge(props: Props) {
  const smallAvatarSize = 22;
  const avatarSize = 80;

  return (
    <Tooltip title={props.tooltip}>
      <BadgeNotification
        badgecolor={props.badgeColor}
        smallavatarsize={smallAvatarSize}
        overlap='circular'
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        badgeContent={
          <SmallAvatar smallavatarsize={smallAvatarSize}>
            {props.icon}
          </ SmallAvatar>
        }
      >
        <Avatar
          alt={props.profil.first_name + ' ' + props.profil.last_name}
          src={props.profil.photo}
          sx={{ width: avatarSize, height: avatarSize }}>
          {props.profil.first_name[0].toUpperCase() + props.profil.last_name[0].toUpperCase()}
        </Avatar>
      </BadgeNotification>
    </Tooltip>
  );
}