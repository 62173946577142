import React from 'react'
import moment from 'moment'
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import Typography from '@mui/material/Typography';


export default function CalendarToolbar(toolbar: any) {

  const goPreviousMonth = () => {
    toolbar.date.setMonth(toolbar.date.getMonth() - 1);
    toolbar.onNavigate('prev');
  };

  const goNextMonth = () => {
    toolbar.date.setMonth(toolbar.date.getMonth() + 1);
    toolbar.onNavigate('next');
  };

  const goPreviousYears = () => {
    toolbar.date.setMonth(toolbar.date.getMonth() - 12);
    toolbar.onNavigate('prev');
  };

  const goNextYear = () => {
    toolbar.date.setMonth(toolbar.date.getMonth() + 12);
    toolbar.onNavigate('next');
  };

  const goToday = () => {
    const now = new Date();
    toolbar.date.setMonth(now.getMonth());
    toolbar.date.setYear(now.getFullYear());
    toolbar.onNavigate('current');
  };

  const label = () => {
    const date = moment(toolbar.date);
    return (
      <span><b>{date.format('MMMM')}</b><span> {date.format('YYYY')}</span></span>
    );
  };

  return (
    <div>
      <Typography align='center' variant='h5' sx={{ my: 4 }}>{label()}</Typography>

      <Grid
        container
        direction='row'
        justifyContent='center'
        alignItems='center'
        sx={{ mb: 2 }}
      >
        <Grid item sx={{ mr: 1 }}>
          <IconButton size='large' color='primary' onClick={goPreviousYears} sx={{ fontSize: { xs: 'medium', sm: 'large' } }}>
            <KeyboardDoubleArrowLeftIcon fontSize='inherit' />
          </IconButton>
        </Grid>

        <Grid item>
          <IconButton size='large' color='primary' onClick={goPreviousMonth} sx={{ fontSize: { xs: 'medium', sm: 'large' } }}>
            <KeyboardArrowLeft fontSize='inherit' />
          </IconButton>
        </Grid>

        <Grid item sx={{ mx: 1 }}>
          <Button variant='contained' onClick={goToday} sx={{ fontSize: { xs: 'small', sm: 'medium' } }}>
            Today
          </Button>
        </Grid>

        <Grid item>
          <IconButton size='large' color='primary' onClick={goNextMonth} sx={{ fontSize: { xs: 'medium', sm: 'large' } }}>
            <KeyboardArrowRight fontSize='inherit' />
          </IconButton>
        </Grid>

        <Grid item sx={{ ml: 1 }}>
          <IconButton size='large' color='primary' onClick={goNextYear} sx={{ fontSize: { xs: 'medium', sm: 'large' } }}>
            <KeyboardDoubleArrowRightIcon fontSize='inherit' />
          </IconButton>
        </Grid>
      </Grid>
    </div >
  );
}