import React from 'react';
import { Navigate } from 'react-router-dom';
import { accountService } from '../services/Service';
import { useObservable } from 'micro-observables';


interface Props {
  authorization?: string;
  content: JSX.Element;
}

export const ProtectedRoute = ( props: Props): JSX.Element => {

  const account = useObservable(accountService.account);

  if (!account) {
    return <Navigate to='/login' />;
  } else {
    if (!props.authorization || account.authorizations.includes(props.authorization) || account.is_superuser) {
      return props.content;
    } else {
      return <Navigate to='/home' />;
    }
  }
};

export default ProtectedRoute;