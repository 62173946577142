import React, { useState, useEffect, useMemo, StrictMode, createContext } from 'react'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { BrowserRouter } from 'react-router-dom';
import AppRouter from './common/AppRouter';
import axios from "axios";
import './App.css'
import { CssBaseline } from '@mui/material/';

axios.defaults.baseURL = import.meta.env.VITE_REACT_API_URL;

const getDesignTokens = (mode: any) => ({
  palette: {
    mode,
    ...(mode === 'light'
      ? {
        // palette values for light mode
        primary: {
          main: '#000000',
        },
      }
      : {
        // palette values for dark mode
        background: {
          default: '#222222',
          paper: '#222222',
        },
        primary: {
          main: '#ef2322',
        },
      }),
  },
});

export type modeType = 'light' | 'dark';

export interface IColorModeContext {
  mode: modeType;
  handleSwitchMode: () => void;
}

export const ColorModeContext = createContext<IColorModeContext>({
  mode: 'light',
  handleSwitchMode: () => { },
});

/**
 * The main component of the application.
 * It handles the theme mode, notification permission, subscription, and routing.
 */
export default function App() {

  /**
   * The current mode of the application (light or dark).
   */
  const [mode, setMode] = useState<modeType>('light');

  /**
   * The theme object generated based on the current mode.
   */
  const theme = useMemo(() => createTheme(getDesignTokens(mode)), [mode])

  useEffect(() => {
    /**
     * Load the theme from local storage or set the default theme to light.
     */
    const storeTheme = (localStorage.getItem('theme'));
    if (storeTheme) {
      (storeTheme === 'light')
        ? setMode('light')
        : setMode('dark');
    } else {
      setMode('light');
      localStorage.setItem('theme', 'light');
    }

    /**
     * Request permission for notifications.
     */
    // Notification.requestPermission().then(function (result) {
    //   console.log(result); // 'granted'
    // });

    /**
     * Test subscription if the numpad 0 button is pressed.
     */
    // window.addEventListener('keydown', function (e) {
    //   if (e.key === '0') {
    //     const subscription = localStorage.getItem('subscription');
    //     axios.post('/api/subscribe/', subscription);
    //   }
    // });

    /**
     * Subscribe to push notifications when the service worker is ready.
     */
    // navigator.serviceWorker.ready.then(function (registration) {
    //   if (!registration.pushManager) {
    //     console.log('Push manager unavailable.');
    //     return;
    //   }

    //   registration.pushManager.subscribe({
    //     userVisibleOnly: true, // Always show notifications
    //     applicationServerKey: import.meta.env.VITE_VAPID_PUBLIC_KEY
    //   })
    //     .then(function (subscription) {
    //       console.log('Subscribed after refresh:', subscription.endpoint);
    //       // store subscription on local storage
    //       localStorage.setItem('subscription', JSON.stringify(subscription));
    //     })
    //     .catch(function (error) {
    //       console.error('Unable to subscribe to push.', error);
    //     });
    // })

    return () => {
      // Clean up any event listeners or subscriptions
    };
  }, []);

  /**
   * Handle the switch mode button click event.
   * It toggles between light and dark mode and updates the theme and local storage accordingly.
   */
  const handleSwitchMode = () => {
    if (mode === 'light') {
      localStorage.setItem('theme', 'dark');
      setMode('dark');
    } else {
      localStorage.setItem('theme', 'light');
      setMode('light');
    }
  };

  /**
   * The value object to be passed to the ColorModeContext provider.
   * It contains the current mode and the handleSwitchMode function.
   */
  const value = { mode, handleSwitchMode };

  return (
    <StrictMode>
      <ColorModeContext.Provider value={value}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <BrowserRouter>
            <AppRouter />
          </BrowserRouter>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </StrictMode>
  )
}