
import React, { useState, useEffect } from 'react'
import { useObservable, useMemoizedObservable } from 'micro-observables';
import { organizationalService, holidaysService } from '../../services/Service';
import { Profil } from '../../services/OrganizationalService';
import DesktopCalendar from './DesktopCalendar';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Avatar from '@mui/material/Avatar';
import Checkbox from '@mui/material/Checkbox';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { css } from '@emotion/css';


export default function HolidaysCalendar() {

  const users = useObservable(organizationalService.profils);
  const holidaysValidate = useMemoizedObservable(() => holidaysService.getHoldaysAccepted(), []);
  const [userList, setUserList] = useState<number[]>([]);

  useEffect(() => {
    holidaysService.fetchHolidays();
    organizationalService.fetchProfils();
  }, []);

  const handleClick = (id: number) => {
    setUserList(prevState => prevState.includes(id) ? prevState.filter(val => val !== id) : [...prevState, id]);
  };

  const handleSelectAll = () => {
    if (userList.length == users.length) {
      setUserList([]);
    } else {
      const l: number[] = [];
      users.forEach((user: Profil) => l.push(user.id));
      setUserList(l);
    }
  };

  return (
    <div>
      <DesktopCalendar holidays={holidaysValidate.filter(holiday => !userList.includes(holiday.creator))} />

      <Accordion sx={{ my: 2 }} >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} >
          <Typography variant='h5' sx={{ mx: 2 }}>Utilisateurs</Typography>
        </AccordionSummary>
        <AccordionDetails className={css`max-height: 50vh; overflow: auto`}>
          <MenuList>
            <MenuItem onClick={() => { handleSelectAll() }}>
              <ListItemAvatar sx={{ mr: 3 }} />
              <ListItemText
                primary={
                  <Stack
                    direction='row'
                    justifyContent='space-between'
                    alignItems='flex-start'
                    spacing={2}
                  >
                    <Typography variant='h5' textAlign='left' sx={{ mx: 2, fontSize: { xs: '1rem', sm: '1.2rem', md: '1.4rem' } }}>
                      Tous
                    </Typography>
                    <Checkbox checked={userList.length === 0} />
                  </Stack>
                }
              />
            </MenuItem>
            {users.map((user: Profil, index: number) => (
              <MenuItem key={index} onClick={() => { handleClick(user.id) }}>
                <ListItemAvatar sx={{ mr: 3 }} >
                  <Avatar
                    alt='avatar'
                    src={user.photo}
                    sx={{ width: 50, height: 50 }} />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Stack
                      direction='row'
                      justifyContent='space-between'
                      alignItems='flex-start'
                      spacing={2}
                    >
                      <Typography variant='h5' textAlign='left' sx={{ fontSize: { xs: '1rem', sm: '1.2rem', md: '1.4rem' } }}>
                        {user.first_name + ' ' + user.last_name}
                      </Typography>
                      <Checkbox checked={!userList.includes(user.id)} />
                    </Stack>
                  }
                />
              </MenuItem>
            ))}
          </MenuList>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}