import React, { useState, Fragment } from 'react';
import { useObservable, useMemoizedObservable } from 'micro-observables';
import { accountService, expensesService } from '../../services/Service';
import { Expense, Fee } from '../../services/ExpensesService';
import { AlertMessage } from '../../common/dto';
import { formatError } from '../../common/formatError';
import ExpenseFormular from './ExpenseFormular';
import dayjs from 'dayjs';
import Alert from '@mui/material/Alert';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import Box from '@mui/material/Box';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import Divider from '@mui/material/Divider';
import DownloadIcon from '@mui/icons-material/Download';
import EditIcon from '@mui/icons-material/Edit';
import Fab from '@mui/material/Fab';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import InfoIcon from '@mui/icons-material/Info';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Pagination from '@mui/material/Pagination';
import Typography from '@mui/material/Typography';


export default function ListView() {

  const account = useObservable(accountService.account);
  const expensesRequested = useMemoizedObservable(() => expensesService.getExpensesRequested(), []);
  const expensesValidate = useObservable(expensesService.getExpensesValidated());

  const [openHelp, setOpenHelp] = useState(false);
  const [openDetail, setOpenDetail] = useState<number>(-1);
  const [openEditor, setOpenEditor] = useState<number>(-1);
  const itemsPerPage = 25;
  const [page, setPage] = useState(1);
  const [noOfPages] = useState(Math.ceil(expensesValidate.length / itemsPerPage));
  const [message, setMessage] = useState<AlertMessage>({ message: '', severity: undefined });

  const handleChange = (event: React.ChangeEvent<any>, value: number) => {
    setPage(value);
  };

  const openHelpWindow = () => {
    setOpenHelp(true);
  };

  const closeHelpWindow = () => {
    setOpenHelp(false);
  };

  const openDetailWindow = (index: number) => {
    setOpenDetail(index);
  };

  const closeDetailWindow = () => {
    setOpenDetail(-1);
  };

  const openEditorWindow = (index: number) => {
    setOpenEditor(index);
  };

  const closeEditorWindow = () => {
    setOpenEditor(-1);
  };

  const downloadPDF = async (id: number, date: string) => {
    const creator = account?.first_name + '_' + account?.last_name;

    try {
      await expensesService.downloadPDF(id, date, creator.replace(' ', '_'));
    } catch (err: any) {
      setMessage({ message: formatError(err), severity: 'error' });
    }
  }

  const downloadDocuments = async (id: number) => {
    const creator = account?.first_name + '_' + account?.last_name;
    
    try {
      await expensesService.downloadDocuments(id, creator.replace(' ', '_'));
    } catch (err: any) {
      setMessage({ message: formatError(err), severity: 'error' });
    }
  }

  const signRequest = async (request: Expense) => {
    try {
      await expensesService.signRequest(request);
    } catch (err: any) {
      setMessage({ message: formatError(err), severity: 'error' });
    }
  }

  if (!account) {
    return <div />
  }

  return (
    <div>
      {message.message.length > 0 &&
        <Alert variant='filled' severity={message.severity} sx={{ mb: 6 }}>
          {message.message}
        </Alert>
      }

      <Dialog onClose={closeHelpWindow} open={openHelp}>
        <Container sx={{ my: 4, width: 'auto' }}>
          <Typography align='left' variant='h4' sx={{ mb: 4 }}>Signature de la demande de remboursement</Typography>
          <Typography align='left' sx={{ mb: 2, color: 'text.primary' }}>Il vous est possible de signer votre demande de remboursement en ligne. Pour cela, vous devez enregistrer votre signature depuis la page &apos;Profil&apos;. Votre signature apparaîtra alors dans le pdf exporté.</Typography>
          <Typography align='left' sx={{ mb: 2, color: 'text.primary' }}>Si vous avez précédemment signé votre demande de remboursement, mais que vous l&apos;avez à postériori modifiée, vous devez de nouveau la signer.</Typography>
        </Container>
      </Dialog>

      {
        expensesRequested.length == 0 ?
          <Typography align='left' variant='h5' sx={{ color: 'text.secondary', my: 4 }}>Vous n&apos;avez aucune demande de remboursement en cours pour le moment.</Typography>
          :
          <div>
            <Typography align='left' variant='h5' sx={{ mb: 4 }}>Demande de remboursement en cours</Typography>

            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
              <ListItem disablePadding >
                <ListItemText primary='Demande' sx={{ width: '14%' }} />
                <ListItemText primary='Description' sx={{ width: '32%' }} />
                <ListItemText primary='Paiement' sx={{ width: '14%' }} />
                <ListItemText primary='Détails' sx={{ width: '10%', textAlign: 'center' }} />
                <ListItemText primary='Modifier' sx={{ width: '10%', textAlign: 'center' }} />
                <ListItemText
                  primary={
                      <Box
                        display='flex'
                        justifyContent='center'
                        alignItems='center'
                      >
                        <Typography>Signer</Typography>
                        <Fab size='small' color='default' aria-label='help' style={{ transform: 'scale(0.6)' }} onClick={() => openHelpWindow()}>
                          <HelpOutlineIcon />
                        </Fab>
                      </Box>
                  }
                  sx={{ width: '10%', textAlign: 'center' }} />
                <ListItemText primary='Exporter' sx={{ width: '10%', textAlign: 'center' }} />
              </ListItem>

              <Divider sx={{ my: 2 }} />

              {expensesRequested.map((request: Expense, index: number) => (
                <div key={index}>
                  <Dialog onClose={() => { closeEditorWindow() }} open={index === openEditor}>
                    <ExpenseFormular expense={request} setNotification={setMessage} />
                  </Dialog>

                  <Dialog onClose={() => { closeDetailWindow() }} open={openDetail === index}>
                    <Container sx={{ my: 4, width: 'auto' }}>
                      <Typography align='left' variant='h4' sx={{ mb: 4 }}>{request.description}</Typography>

                      <Typography align='left' variant='h5' sx={{ mb: 4 }}>Dépenses</Typography>
                      {
                        request.fees.map((fee: Fee, index: number) => (
                          <Typography align='left' sx={{ my: 1 }} key={index}>
                            {`Demande de reboursement d'une valeur TTC de ${fee.amount}€ (dont ${fee.tva}€ de TVA) pour le motif suivant: ${fee.description} (${dayjs(fee.expense_date).format('DD/MM/YYYY')}).`}
                          </Typography>
                        ))}

                      <Typography align='left' variant='h5' sx={{ my: 4 }}>Justificatifs</Typography>
                      {
                        request.bills.length > 0 ?
                          <div>
                            <Typography align='left' sx={{ color: 'text.secondary' }} key={index}>
                              {request.bills.map(bill => bill.title).join(', ')}
                            </Typography>
                            <Fab size='small' color='primary' sx={{ mt: 2 }} onClick={() => downloadDocuments(request.id)}>
                              <DownloadIcon />
                            </Fab>
                          </div>
                          :
                          <Typography align='left' sx={{ my: 1, color: 'text.secondary' }} key={index}>
                            Aucun justificatif transmis
                          </Typography>
                      }
                    </Container>
                  </Dialog>

                  <ListItem key={index} disablePadding >
                    <ListItemText primary={dayjs(request.asking_date).format('DD/MM/YYYY')} sx={{ width: '14%' }} />
                    <ListItemText primary={request.description} sx={{ width: '32%' }} />
                    <ListItemText primary={request.paiement_date ? dayjs(request.paiement_date).format('DD/MM/YYYY') : <CloseIcon sx={{ color: 'red' }} />} sx={{ width: '14%' }} />

                    <ListItemText
                      sx={{ width: '10%', textAlign: 'center' }}
                      primary={
                          <Fab size='small' color='primary' style={{ transform: 'scale(0.9)' }} onClick={() => { openDetailWindow(index) }}>
                            <InfoIcon />
                          </Fab>
                      } />

                    <ListItemText
                      sx={{ width: '10%', textAlign: 'center' }}
                      primary={
                          <Fab size='small' color='primary' style={{ transform: 'scale(0.9)' }} onClick={() => { openEditorWindow(index) }}>
                            <EditIcon />
                          </Fab>
                      } />

                    <ListItemText
                      sx={{ width: '10%', textAlign: 'center' }}
                      primary={
                          <Fab disabled={account.signature === null} size='small' color={request.is_signed ? 'primary' : 'default'} style={{ transform: 'scale(0.9)' }} onClick={() => signRequest(request)}>
                            <BorderColorIcon />
                          </Fab>
                      } />

                    <ListItemText
                      sx={{ width: '10%', textAlign: 'center' }}
                      primary={
                          <Fab size='small' color='primary' style={{ transform: 'scale(0.9)' }} onClick={() => downloadPDF(request.id, request.asking_date)}>
                            <DownloadIcon />
                          </Fab>
                      } />
                  </ListItem>
                </div>
              ))}
            </List>
          </div>
      }

      {
        expensesValidate.length == 0 ?
          <Typography align='left' variant='h5' sx={{ color: 'text.secondary', my: 4 }}>Vous n&apos;avez aucune demande de remboursement validée pour le moment.</Typography>
          :
          <div>
            <Typography align='left' variant='h5' sx={{ my: 4 }}>Mes précédentes demandes</Typography>

            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
              <ListItem disablePadding >
                <ListItemText primary='Demande' sx={{ width: '14%' }} />
                <ListItemText primary='Description' sx={{ width: '40%' }} />
                <ListItemText primary='Paiement' sx={{ width: '14%' }} />
                <ListItemText primary='Validation' sx={{ width: '12%', textAlign: 'center' }} />
                <ListItemText primary='Détails' sx={{ width: '10%', textAlign: 'center' }} />
                <ListItemText primary='Exporter' sx={{ width: '10%', textAlign: 'center' }} />
              </ListItem>

              <Divider sx={{ my: 2 }} />

              {expensesValidate.slice((page - 1) * itemsPerPage, page * itemsPerPage).map((expense: Expense, index: number) => (
                <div key={index + expensesRequested.length}>
                  <Dialog onClose={() => { closeDetailWindow() }} open={openDetail === index + expensesRequested.length}>
                    <Container sx={{ my: 4, width: 'auto' }}>
                      <Typography align='left' variant='h4' sx={{ mb: 4 }}>{expense.description}</Typography>

                      <Typography align='left' variant='h5' sx={{ mb: 4 }}>Dépenses</Typography>
                      {
                        expense.fees.map((fee: Fee, index: number) => (
                          <Typography align='left' sx={{ my: 1 }} key={index}>
                            {`Demande de reboursement d'une valeur TTC de ${fee.amount}€ (dont ${fee.tva}€ de TVA) pour le motif suivant: ${fee.description} (${dayjs(fee.expense_date).format('DD/MM/YYYY')}).`}
                          </Typography>
                        ))}

                      <Typography align='left' variant='h5' sx={{ my: 4 }}>Validation</Typography>
                      {
                        expense.is_accepted ?
                          <Typography align='left' sx={{ my: 1 }}>{`Demande accéptée le ${dayjs(expense.validation_date).format('DD/MM/YYYY')}`}</Typography>
                          :
                          <Typography align='left' sx={{ my: 1 }}>{`Demande refusée le ${dayjs(expense.validation_date).format('DD/MM/YYYY')}`}</Typography>
                      }
                      {
                        !expense.is_accepted && expense.reason != '' &&
                        <Typography align='left' sx={{ my: 1 }}>
                          {`Raison du refus: ${expense.reason}`}
                        </Typography>
                      }

                      <Typography align='left' variant='h5' sx={{ my: 4 }}>Justificatifs</Typography>
                      {
                        expense.bills.length > 0 ?
                          <div>
                            <Typography align='left' sx={{ color: 'text.secondary' }} key={index}>
                              {expense.bills.map(bill => bill.title).join(', ')}
                            </Typography>
                            <Fab size='small' color='primary' sx={{ mt: 2 }} onClick={() => downloadDocuments(expense.id)}>
                              <DownloadIcon />
                            </Fab>
                          </div>
                          :
                          <Typography align='left' sx={{ my: 1, color: 'text.secondary' }} key={index}>
                            Aucun justificatif transmis
                          </Typography>
                      }
                    </Container>
                  </Dialog>

                  <ListItem key={index + expensesRequested.length} disablePadding >
                    <ListItemText primary={dayjs(expense.asking_date).format('DD/MM/YYYY')} sx={{ width: '14%' }} />
                    <ListItemText primary={expense.description} sx={{ width: '40%' }} />
                    <ListItemText primary={expense.paiement_date ? dayjs(expense.paiement_date).format('DD/MM/YYYY') : <CloseIcon sx={{ color: 'red' }} />} sx={{ width: '14%' }} />

                    <ListItemText
                      sx={{ width: '12%', textAlign: 'center' }}
                      primary={
                        <Fragment >
                          {
                            expense.is_accepted ?
                              <CheckIcon sx={{ color: 'green' }} />
                              :
                              <CloseIcon sx={{ color: 'red' }} />
                          }
                        </Fragment>
                      } />

                    <ListItemText
                      sx={{ width: '10%', textAlign: 'center' }}
                      primary={
                          <Fab size='small' color='primary' style={{ transform: 'scale(0.9)' }} onClick={() => { openDetailWindow(index + expensesRequested.length) }}>
                            <InfoIcon />
                          </Fab>
                      } />

                    <ListItemText
                      sx={{ width: '10%', textAlign: 'center' }}
                      primary={
                          <Fab size='small' color='primary' style={{ transform: 'scale(0.9)' }} onClick={() => downloadPDF(expense.id, expense.asking_date)}>
                            <DownloadIcon />
                          </Fab>
                      } />
                  </ListItem>
                </div>
              ))}
            </List>
            <Box
              display='flex'
              justifyContent='center'
              alignItems='center'
              sx={{ mt: 2 }}
            >
              <Pagination
                count={noOfPages}
                page={page}
                defaultPage={1}
                onChange={handleChange}
                size='large'
                showFirstButton
                showLastButton
                color='primary'
              />
            </Box>
          </div>
      }
    </div>
  );
}