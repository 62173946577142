import React, { useState } from 'react'
import { Event } from '../../services/EventsService';
import { eventsService } from '../../services/Service';
import { Holiday, FrenchHoliday } from '../../services/HolidaysService';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import CalendarToolbar from '../../common/CalendarToolbar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import dayjs from 'dayjs';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import { css } from '@emotion/css';
import { grey } from '@mui/material/colors';


moment.updateLocale('en', {
	week: {
		dow: 1,
	},
  months: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Decembre'],
  weekdays: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
});

interface CalendarEvent {
  id: number;
  title: string;
  allDay: boolean;
  start: Date;
  end: Date;
  desc: string;
  schedule: string;
  eventColor: string;
}

const CustomEvent = (event: any) => {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <Dialog onClose={() => { setOpen(false) }} open={open}>
        <Container sx={{ my: 4, width: 'auto' }}>
          <Typography align='left' variant='h4' sx={{ mb: 4 }}>{event.title}</Typography>
          <Typography align='left' sx={{ mb: 2 }}>{event.event.desc}</Typography>
          <Typography align='left' sx={{ mb: 2 }}>{event.event.schedule}</Typography>
        </Container>
      </Dialog>

      <div title={event.title} onClick={() => { setOpen(true) }}>
        {event.title}
      </div>
    </div>
  )
}

interface Props {
  events: Event[];
  holidays: Holiday[];
  frenchHolidays: FrenchHoliday[];
}

export default function DesktopCalendar(props: Props) {

  const localizer = momentLocalizer(moment)
  const formats = {
    weekdayFormat: (date: any, culture: any, localizer: any) => localizer.format(date, 'dddd', culture),
  }
  const calendarEvents = Array<CalendarEvent>();

  props.events.forEach((event: Event, index: number) => calendarEvents.push(
    {
      id: index,
      title: event.name,
      allDay: false,
      start: new Date(event.start_date),
      end: new Date(event.end_date),
      desc: eventsService.isParticipated(event) ? event.description + ' (participant)' : event.description + ' (non participant)',
      schedule: `Du ${dayjs(event.start_date).format('DD/MM/YYYY')} à ${dayjs(event.start_date).format('HH:mm')} au ${dayjs(event.end_date).format('DD/MM/YYYY')} à ${dayjs(event.end_date).format('HH:mm')}, ${event.address}.`,
      eventColor: eventsService.isParticipated(event) ? '#ef2322' : '#7f7F7f',
    }
  ));

  props.holidays.forEach((holiday: Holiday, index: number) => calendarEvents.push(
    {
      id: index + props.events.length,
      title: holiday.holiday_type,
      allDay: false,
      start: new Date(holiday.start_date),
      end: new Date(holiday.end_date),
      desc: holiday.holiday_type,
      schedule: holiday.holiday_type === 'RTT' ? `Absence le ${dayjs(holiday.start_date).format('DD/MM/YYYY')}` : `Absence du ${dayjs(holiday.start_date).format('DD/MM/YYYY')} au ${dayjs(holiday.end_date).format('DD/MM/YYYY')}`,
      eventColor: '#1276fa',
    }
  ));

  props.frenchHolidays.forEach((frenchHoliday: FrenchHoliday, index: number) => calendarEvents.push(
    {
      id: index + props.events.length + props.frenchHolidays.length,
      title: frenchHoliday.name,
      allDay: false,
      start: new Date(frenchHoliday.day),
      end: new Date(frenchHoliday.day),
      desc: frenchHoliday.name,
      schedule: `Le ${dayjs(frenchHoliday.day).format('DD/MM/YYYY')}.`,
      eventColor: '#ef2322',
    }
  ));

  const eventStyleGetter = (event: CalendarEvent) => {
    const backgroundColor = event.eventColor;
    const style = {
        backgroundColor: backgroundColor,
        color: 'white',
    };
    return {
        style: style
    };
  }

  return (
    <Calendar
      events={calendarEvents}
      showAllEvents={true}
      startAccessor='start'
      endAccessor='end'
      defaultDate={moment().toDate()}
      defaultView='month'
      localizer={localizer}
      formats={formats}
      style={{ height: '60vh' }}
      components={{
        toolbar: CalendarToolbar,
        event: CustomEvent
      }}
      views={['month']}
      eventPropGetter={(eventStyleGetter)}
      dayPropGetter={date => {
        const dayOfWeek = date.getDay();
        if (dayOfWeek === 0 || dayOfWeek === 6) {
          return {
            style: {
              backgroundColor: grey[500],
            },
          };
        } else {
          return {};
        }
      }}
      className={css`
      font-family: 'Roboto','Helvetica','Arial',sans-serif;
      .rbc-today {
        background-color: #e6baa9;
      }
      .rbc-show-more {
        background-color: rgba(255, 255, 255, 0);
        color: #ef2322;
      }
      .rbc-row-content-scroll-container {
        height: auto;
        overflow-y: visible;
      }
      .rbc-month-row {
        height: auto;
        overflow: visible;
      }
      .rbc-row-content-scrollable {
        height: auto;
        overflow-y: visible;
      }
      .rbc-month-view {
        height: auto;
        overflow-y: scroll;
      }
      ` }
    />
  )
}