import { observable } from 'micro-observables';
import { AccountService } from './AccountService';
import dayjs, { Dayjs } from 'dayjs';
import axios from 'axios';


export type EnventStatus = 'En cours' | 'Passé' | 'À venir';

export interface Event {
  id: number;
  name: string;
  creator?: number;
  start_date: string;
  end_date: string;
  place: number;
  description: string;
  image: string;
  address: string;
  participants: number[];
  participant_fullnames: string[];
}

interface Data {
  id: number;
  name: string;
  description: string;
  start_date: string;
  end_date: string;
  status: EnventStatus;
  place: number;
  participants: string;
  address: string;
}

export class EventsService {
  private _events = observable<Event[]>([]);
  readonly events = this._events.readOnly();

  constructor(private accountService: AccountService) { }

  async fetchEvents() {
    const token = this.accountService.token.get();
    const config = {
      'headers': {
        'Authorization': `Bearer ${token}`
      }
    }
    const res = await axios.get('/api/events/', config);

    const events = res.data;
    events.sort((a: Event, b: Event) => a.start_date < b.start_date ? 1 : -1);
    this._events.set(events);
  }

  async addEvent(event: Event) {
    const account = this.accountService.account.get();

    if (account) {
      const token = this.accountService.token.get();
      const config = {
        'headers': {
          'Authorization': `Bearer ${token}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      };

      const data = {
        name: event.name,
        creator: account.id,
        start_date: event.start_date,
        end_date: event.end_date,
        description: event.description,
        image: event.image,
        place: event.place,
        address: event.address,
        participants: [],
      };

      await axios.post('/api/events/', JSON.stringify(data), config);
    }
  }

  async updateEvent(event: Event) {
    const token = this.accountService.token.get();
    const config = {
      'headers': {
        'Authorization': `Bearer ${token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    };
    const data = {
      name: event.name,
      creator: event.creator,
      start_date: event.start_date,
      end_date: event.end_date,
      description: event.description,
      image: event.image,
      place: event.place,
      address: event.address,
      participants: event.participants,
    };
    const res = await axios.put(`/api/events/${event.id}/`, JSON.stringify(data), config);

    const events = this.events.get().filter(event => event.id !== res.data.id);
    events.push(res.data);
    events.sort((a: Event, b: Event) => a.start_date < b.start_date ? 1 : -1);
    this._events.set(events);
  }

  async removeEvent(event: Event) {
    const token = this.accountService.token.get();
    const config = {
      'headers': {
        'Authorization': `Bearer ${token}`
      }
    }
    await axios.delete(`/api/events/${event.id}/`, config);
    this._events.update(events => events.filter((e) => e.id !== event.id));
  }

  async subscribeToEvent(event_id: number) {
    //Subscribe or unsubscribe the user depending of if he is already in the participant list or not
    const account = this.accountService.account.get();

    if (account) {
      const token = this.accountService.token.get();
      const config = {
        'headers': {
          'Authorization': `Bearer ${token}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      };

      const res = await axios.put(`/api/event_subscription/${account.id}/${event_id}/`, '', config);

      const events = this.events.get().filter(event => event.id !== res.data.id);
      events.push(res.data);
      events.sort((a: Event, b: Event) => a.start_date < b.start_date ? 1 : -1);
      this._events.set(events);
    }
  }

  isEventPast(end_date: Dayjs) {
    const today = dayjs();
    return today.diff(end_date, 'minute') > 0;
  }

  isEventComming(start_date: Dayjs) {
    const today = dayjs();
    return start_date.diff(today, 'minute') > 0;
  }

  isEventNow(start_date: Dayjs, end_date: Dayjs) {
    const today = dayjs();
    return today.diff(start_date, 'minute') >= 0 && today.diff(end_date, 'minute') <= 0;
  }

  getPastEvents() {
    return this.events.select(arr => arr.filter(event => this.isEventPast(dayjs(event.end_date, 'YYYY-MM-DD HH:mm'))));
  }

  getFutureEvents() {
    return this.events.select(arr => arr.filter(event => this.isEventComming(dayjs(event.start_date, 'YYYY-MM-DD HH:mm'))));
  }

  getCurrentEvents() {
    return this.events.select(arr => arr.filter(event => this.isEventNow(dayjs(event.start_date, 'YYYY-MM-DD HH:mm'), dayjs(event.end_date, 'YYYY-MM-DD HH:mm'))));
  }

  isParticipated(event: Event) {
    const account = this.accountService.account.get();

    if (account) {
      return event.participants.includes(account.id);
    }

    return false;
  }

  getTableRows() {
    return this.events.select(arr => this.constructTableRows(arr));
  }

  constructTableRows(events: Event[]) {
    const rows: Data[] = [];
    let status: EnventStatus;

    for (let i = 0; i < events.length; i++) {
      if (this.isEventPast(dayjs(events[i].end_date, 'YYYY-MM-DD HH:mm'))) {
        status = 'Passé';
      } else if (this.isEventComming(dayjs(events[i].start_date, 'YYYY-MM-DD HH:mm'))) {
        status = 'À venir';
      } else {
        status = 'En cours';
      }

      rows.push({
        id: i,
        name: events[i].name,
        description: events[i].description,
        start_date: dayjs(events[i].start_date).format('DD/MM/YYYY HH:mm').toString(),
        end_date: dayjs(events[i].end_date).format('DD/MM/YYYY HH:mm').toString(),
        status: status,
        place: events[i].place ? events[i].place : Infinity,
        participants: events[i].participants.length > 0 ? events[i].participant_fullnames.join(', ') : 'Aucun participant',
        address: events[i].address,
      });
    }

    return rows;
  }
}