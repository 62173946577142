import React, { useState, Fragment } from 'react';
import { useMemoizedObservable } from 'micro-observables';
import { holidaysService } from '../../services/Service';
import { Holiday } from '../../services/HolidaysService';
import { AlertMessage } from '../../common/dto';
import HolidaysDemandFormular from './HolidaysDemandFormular';
import dayjs from 'dayjs';
import Alert from '@mui/material/Alert';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import Collapse from '@mui/material/Collapse';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import Divider from '@mui/material/Divider';
import EditIcon from '@mui/icons-material/Edit';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Fab from '@mui/material/Fab';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import RefreshIcon from '@mui/icons-material/Refresh';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';


export default function DemandInProgress() {

  const holidaysRequested = useMemoizedObservable(() => holidaysService.getHolidaysRequested(), []);

  const [open, setOpen] = useState<number[]>([]);
  const [openEditor, setOpenEditor] = useState<number[]>([]);
  const [message, setMessage] = useState<AlertMessage>({ message: '', severity: undefined });

  const handleClick = (index: number) => {
    setOpen(prevState => prevState.includes(index) ? prevState.filter(val => val !== index) : [...prevState, index]);
  };

  const openEditorWindow = (index: number) => {
    setOpenEditor(prevState => prevState.includes(index) ? prevState.filter(val => val !== index) : [...prevState, index]);
  };

  const closeEditorWindow = (index: number) => {
    setOpenEditor(prevState => prevState.includes(index) ? prevState.filter(val => val !== index) : [...prevState, index]);
  };

  return (
    <Container>
      {message.message.length > 0 &&
        <Alert variant='filled' severity={message.severity} sx={{ mb: 6 }}>
          {message.message}
        </Alert>
      }

      <Typography align='left' variant='h4' sx={{ mt: 4, mb: 6 }}>Demandes d&apos;absence en cours</Typography>

      {
        holidaysRequested.length == 0 ?
          <Typography align='left' variant='h5' sx={{ color: 'text.secondary', my: 4 }}>Vous n&apos;avez aucune demande d&apos;absence en cours pour le moment.</Typography>
          :
          <List sx={{ width: '100%', mb: 4 }} component='nav' >
            {holidaysRequested.map((request: Holiday, index: number) => (
              <div key={index}>
                <Dialog onClose={() => { closeEditorWindow(index) }} open={openEditor.includes(index) || false}>
                  <HolidaysDemandFormular holiday={request} setNotification={setMessage} close={() => closeEditorWindow(index)} />
                </Dialog>

                <ListItemButton onClick={() => { handleClick(index) }}>
                  <ListItemIcon>
                    <RefreshIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={request.creator_fullname + ' - ' + request.holiday_type}
                    secondary={
                      <Fragment >
                        {
                          request.holiday_type === 'RTT' ?
                            <Typography component='span' variant='body2' >
                              {dayjs(request.start_date).format('DD/MM/YYYY')}
                            </Typography>
                            :
                            <Typography component='span' variant='body2' >
                              {dayjs(request.start_date).format('DD/MM/YYYY')}
                              <ArrowRightAltIcon style={{ verticalAlign: 'middle' }} sx={{ mx: 1 }} />
                              {dayjs(request.end_date).format('DD/MM/YYYY')}
                            </Typography>
                        }
                      </Fragment>
                    } />
                  {open.includes(index) ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={open.includes(index)} timeout='auto' unmountOnExit>
                  <Stack
                    direction='column'
                    justifyContent='flex-start'
                    alignItems='flex-start'
                    sx={{ pl: 9 }}
                  >
                    {
                      request.holiday_type === 'RTT' ?
                        <Typography align='left' sx={{ my: 1, color: 'text.secondary' }}>Absence le {dayjs(request.start_date).format('DD/MM/YYYY')} (1j).</Typography>
                        :
                        <Typography align='left' sx={{ my: 1, color: 'text.secondary' }}>Absence débutant le {dayjs(request.start_date).format('DD/MM/YYYY')} {request.begin_at_midday ? "en début d'après-midi" : 'matin'} et terminant le {dayjs(request.end_date).format('DD/MM/YYYY')} {request.end_at_midday ? 'en fin de matinée' : 'soir'} ({parseFloat(request.duration.toString())}j).</Typography>
                    }

                    <Typography align='left' sx={{ my: 1, color: 'text.secondary' }}>
                      Demande réalisée le {dayjs(request.asking_date).format('DD/MM/YYYY').toString()}.
                    </Typography>

                    <Fab size='small' color='primary' sx={{ my: 2 }} aria-label='change image' onClick={() => { openEditorWindow(index) }}>
                      <EditIcon />
                    </Fab>
                  </Stack>
                </Collapse>
                <Divider sx={{ my: 0 }} />
              </div>
            ))}
          </List>
      }
    </Container>
  );
}