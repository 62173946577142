import React, { useState } from 'react';
import { usersService } from '../../services/Service';
import { User } from '../../services/UsersService';
import { AlertMessage } from '../../common/dto';
import { formatError } from '../../common/formatError';
import { useFormik } from 'formik';
import * as yup from 'yup'
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import EditIcon from '@mui/icons-material/Edit';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';


interface Props {
  user: User;
}

export default function PasswordEditor(props: Readonly<Props>) {

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<AlertMessage>({ message: '', severity: undefined });

  const formik = useFormik({
    initialValues: {
      new_password: '',
      confirm_new_password: '',
    },
    validationSchema: yup.object({
      new_password: yup
        .string()
        .trim()
        .required('Password requis')
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-+={}[\]:;?/<>,.]).{8,}$/,
          'Mot de passe avec au moins 8 caractères, une majuscule, une minuscule, un chiffre et un caractère spécial.'
        ),
      confirm_new_password: yup
        .string()
        .trim()
        .required('Password requis')
        .test(
          'Password non valide',
          'Les password ne correspondent pas',
          function (value) {
            return value === this.parent.new_password
          }
        ),
    }),
    onSubmit: (values) => {
      setLoading(true);
      handlePasswordUpdate(values.new_password);
    },
  });

  const handlePasswordUpdate = async (new_password: string) => {
    try {
      await usersService.updatePassword(props.user.id, new_password);
      setMessage({ message: 'Password modifié.', severity: 'success' });
    } catch (err: any) {
      setMessage({ message: formatError(err), severity: 'error' });
    } finally {
      setLoading(false);
    }
  }

  return (
    <Container sx={{ my: 4, width: 'auto' }} >

      {message.message.length > 0 &&
        <Alert variant='filled' severity={message.severity} sx={{ mb: 6 }}>
          {message.message}
        </Alert>
      }

      <form onSubmit={formik.handleSubmit}>
        <Typography align='left' variant='h4' sx={{ mb: 6 }}>Changer le password</Typography>

        <Typography align='left' sx={{ mt: 4, mb: 2 }}>Nouveau password *</Typography>
        <TextField
          type='password'
          name='new_password'
          label='nouveau password'
          size='small'
          value={formik.values.new_password}
          onChange={formik.handleChange}
          error={formik.touched.new_password && Boolean(formik.errors.new_password)}
          helperText={formik.touched.new_password && formik.errors.new_password}
        />

        <Typography align='left' sx={{ mt: 4, mb: 2 }}>Confirmer password *</Typography>
        <TextField
          type='password'
          name='confirm_new_password'
          label='confirmer password'
          size='small'
          value={formik.values.confirm_new_password}
          onChange={formik.handleChange}
          error={formik.touched.confirm_new_password && Boolean(formik.errors.confirm_new_password)}
          helperText={formik.touched.confirm_new_password && formik.errors.confirm_new_password}
        />

        <Box sx={{ mt: 4 }}>
          {
            loading ?
              <CircularProgress />
              :
              <Button variant='contained' type='submit' endIcon={<EditIcon />} disabled={loading}>
                Edit
              </Button>
          }
        </Box>
      </form>
    </Container>
  );
}