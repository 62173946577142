import React, { useState } from 'react'
import { useSwipeable } from 'react-swipeable';
import { Photo } from '../../services/GalleryService';
import Grid from '@mui/material/Grid';
import IconButton  from '@mui/material/IconButton';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import MobileStepper from '@mui/material/MobileStepper';
import { css } from '@emotion/css';


interface Props {
  photos: Photo[];
  imageIndex: number;
}

export default function SwipeableGallery(props: Props) {

  const [activeStep, setActiveStep] = useState<number>(props.imageIndex);

  const handleBack = () => {
    setActiveStep((prevActiveStep: number) => (prevActiveStep > 0 ? prevActiveStep - 1 : 0));
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep: number) => (prevActiveStep < props.photos.length - 1 ? prevActiveStep + 1 : props.photos.length - 1));
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => handleNext(),
    onSwipedRight: () => handleBack(),
    swipeDuration: 250,
  });

  return (
    <div>
      <Grid container justifyContent='center' alignItems='center' className={css`height: 80vh`}>
        <img src={props.photos[activeStep].image} className={css`width: 100%; max-height: 80vh; object-fit: contain;`} {...handlers} />
      </Grid>

      <Grid container justifyContent='center' sx={{ mb: 2 }}>
        <MobileStepper
          variant='progress'
          steps={props.photos.length}
          position='static'
          activeStep={activeStep}
          sx={{ maxwidth: 'auto', flexGrow: 1, bgcolor: 'transparent' }}
          nextButton={
            <IconButton size='small' color='primary' onClick={handleNext} disabled={activeStep === props.photos.length - 1}>
              <KeyboardArrowRight />
            </IconButton>
          }
          backButton={
            <IconButton size='small' color='primary' onClick={handleBack} disabled={activeStep === 0}>
              <KeyboardArrowLeft />
            </IconButton>
          }
        />
      </Grid>
    </div>
  );
}