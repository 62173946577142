import Switch from '@mui/material/Switch';
import { styled, alpha } from '@mui/material/styles';
import { red } from '@mui/material/colors';


interface SwitchProps {
  switchcolor: string;
}

export const CustomSwitch = styled(Switch)<SwitchProps>(({ switchcolor, theme }) => ({
  '& .MuiSwitch-switchBase': {
    color: red[600],
    '&:hover': {
      backgroundColor: alpha(red[600], theme.palette.action.hoverOpacity),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: switchcolor,
    '&:hover': {
      backgroundColor: alpha(switchcolor, theme.palette.action.hoverOpacity),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: switchcolor,
  },
  '& .MuiSwitch-switchBase + .MuiSwitch-track': {
    backgroundColor: red[600],
  },
}));