import React, { useState } from 'react';
import { expensesService } from '../../services/Service';
import { Expense } from '../../services/ExpensesService';
import { AlertMessage } from '../../common/dto';
import { formatError } from '../../common/formatError';
import { useFormik } from 'formik';
import * as yup from 'yup'
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CheckIcon from '@mui/icons-material/Check';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import Collapse from '@mui/material/Collapse';
import Container from '@mui/material/Container';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import EditIcon from '@mui/icons-material/Edit';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';


interface Props {
  expense: Expense;
  setNotification: (msg: AlertMessage) => void;
}

export default function ExpenseDemandFormular(props: Props) {

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<AlertMessage>({ message: '', severity: undefined });
  const [firstclick, setFirstClick] = useState(false);

  const formik = useFormik({
    initialValues: {
      reason: '',
      paiement_date: dayjs().format('DD/MM/YYYY').toString(),
      isValidated: true,
    },
    validationSchema: yup.object({
      reason: yup
        .string()
        .trim(),
      isValidated: yup
        .boolean(),
      paiement_date: yup
        .string()
        .trim()
        .test(
          'Date valide',
          'Date non valide',
          function (value) {
            return dayjs(value, 'DD/MM/YYYY').isValid() || !this.parent.isValidated
          }
        )
    }),
    onSubmit: (values) => {
      setLoading(true);

      const expense: Expense = {
        id: props.expense.id,
        creator: props.expense.creator,
        creator_fullname: props.expense.creator_fullname,
        description: props.expense.description,
        asking_date: props.expense.asking_date,
        paiement_date: values.isValidated ? dayjs(values.paiement_date, 'DD/MM/YYYY').format('YYYY-MM-DD').toString() : props.expense.paiement_date,
        validation_date: dayjs().format('YYYY-MM-DD').toString(),
        reason: values.isValidated ? '' : values.reason,
        is_accepted: values.isValidated,
        fees: [],
        bills: [],
        is_signed: props.expense.is_signed,
      }

      handleUpdate(expense);
    },
  });

  const handleUpdate = async (expense: Expense) => {
    try {
      await expensesService.updateExpense(expense, []);
      props.setNotification({ message: 'La demande de remboursement a bien été modifiée', severity: 'success' });
    } catch (err: any) {
      setMessage({ message: formatError(err), severity: 'error' });
    } finally {
      setLoading(false);
    }
  }

  return (
    <Container sx={{ my: 4, width: 'auto' }}>
      {message.message.length > 0 &&
        <Alert variant='filled' severity={message.severity} sx={{ mb: 6 }}>
          {message.message}
        </Alert>
      }

      <form onSubmit={formik.handleSubmit}>
        <Typography align='left' variant='h4' sx={{ mb: 6 }}>Validation de la demande de remoursement</Typography>

        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          sx={{ my: 6 }}
        >
          <Button
            variant='contained'
            endIcon={<CheckIcon />}
            style={{ backgroundColor: firstclick ? formik.values.isValidated ? 'green' : 'grey' : 'grey'}}
            onClick={() => { formik.setFieldValue(`isValidated`, true); setFirstClick(true); }}
          >
            Accepter
          </Button>
          <Button
            variant='contained'
            endIcon={<CloseIcon />}
            style={{ backgroundColor: firstclick ? formik.values.isValidated ? 'grey' : 'red' : 'grey'}}
            onClick={() => { formik.setFieldValue(`isValidated`, false); setFirstClick(true); }}
          >
            Refuser
          </Button>
        </Stack>

        <Collapse in={formik.values.isValidated} timeout='auto' unmountOnExit>
          <Typography align='left' >Date du paiement</Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              label='Jour'
              inputFormat='DD/MM/YYYY'
              value={dayjs(formik.values.paiement_date, 'DD/MM/YYYY')}
              onChange={(value) => {
                const newDate = dayjs(value).format('DD/MM/YYYY');
                formik.setFieldValue('paiement_date', newDate, true);
              }}
              renderInput={(params) =>
                <TextField
                  name='date'
                  label='date'
                  size='small'
                  error={formik.touched.paiement_date && Boolean(formik.errors.paiement_date)}
                  helperText={formik.errors.paiement_date?.toString()}
                  {...params} />}
            />
          </LocalizationProvider>
        </Collapse>

        <Collapse in={!formik.values.isValidated} timeout='auto' unmountOnExit>
          <Typography align='left' >Raison</Typography>
          <TextField
            fullWidth
            label='reason'
            name='reason'
            size='small'
            multiline
            minRows={2}
            maxRows={10}
            value={formik.values.reason}
            onChange={formik.handleChange}
            error={formik.touched.reason && Boolean(formik.errors.reason)}
            helperText={formik.touched.reason && formik.errors.reason}
          />
        </Collapse>

        <Box sx={{ mt: 6 }}>
          {
            loading ?
              <CircularProgress />
              :
              <Button variant='contained' type='submit' endIcon={<EditIcon />} disabled={loading || !firstclick}>
                Valider
              </Button>
          }
        </Box>
      </form>
    </Container >
  );
}