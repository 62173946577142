import React, { useState } from 'react';
import { eventsService } from '../../services/Service';
import { useMemoizedObservable } from 'micro-observables';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef, GridToolbar, gridClasses } from '@mui/x-data-grid';
import { css } from '@emotion/css';


const columns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Évènement',
    minWidth: 150,
    flex: 0.5,
    editable: false,
    align: 'left',
  },
  {
    field: 'description',
    headerName: 'Description',
    minWidth: 200,
    flex: 1,
    editable: false,
    align: 'left',
  },
  {
    field: 'address',
    headerName: 'Adresse',
    minWidth: 200,
    flex: 1,
    editable: false,
    align: 'left',
  },
  {
    field: 'start_date',
    headerName: 'Début',
    width: 140,
    editable: false,
    align: 'left',
  },
  {
    field: 'end_date',
    headerName: 'Fin',
    width: 140,
    editable: false,
    align: 'left',
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 100,
    editable: false,
    align: 'left',
  },
  {
    field: 'place',
    headerName: 'Places',
    type: 'number',
    width: 100,
    editable: false,
    align: 'left',
  },
  {
    field: 'participants',
    headerName: 'Participants',
    minWidth: 200,
    flex: 1,
    editable: false,
    align: 'left',
  },
];

export default function TableView() {

  const rows = useMemoizedObservable(() => eventsService.getTableRows(), []);
  const [pageSize, setPageSize] = useState<number>(25);

  return (
    <Box sx={{ height: 200 + 52 * Math.min(rows.length, pageSize), width: '100%' }} className={css`min-width: 60vw; position: relative; left: calc(-30vw + 50%);`}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[25, 50, 100]}
        pagination
        getRowHeight={() => 'auto'}
        sx={{
          [`& .${gridClasses.cell}`]: {
            py: 2,
          },
        }}
        disableSelectionOnClick
        disableDensitySelector
        components={{
          Toolbar: GridToolbar,
        }}
      />
    </Box>
  );
}