import React, { useState } from 'react';
import { galleryService } from '../../services/Service';
import { Album, Photo } from '../../services/GalleryService';
import { AlertMessage } from '../../common/dto';
import { formatError } from '../../common/formatError';
import DeleteConfirm from '../../common/DeleteConfirm';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CheckIcon from '@mui/icons-material/Check';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import EditIcon from '@mui/icons-material/Edit';
import Fab from '@mui/material/Fab';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { css } from '@emotion/css';


interface Props {
  album: Album;
  setNotification: (msg: AlertMessage) => void;
}

export default function AlbumFormular(props: Props) {

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<AlertMessage>({ message: '', severity: undefined });

  const [open, setOpen] = useState(false);
  const [img, setImg] = useState('');

  const handleClickOpen = (photo: string) => {
    setImg(photo);
    setOpen(true);
  };

  const closePhoto = () => {
    setOpen(false);
  };

  const formik = useFormik({
    initialValues: {
      deletedContent: Array<number>(),
      newContent: [],
      deleted: false,
    },
    validationSchema: yup.object({
      newContent: yup
        .array(),
      deletedContent: yup
        .array()
        .default([])
        .test(
          'Album possédant au moins une image',
          'Au moins 1 photo est requise',
          (value, context) => props.album.photos.length + context.parent.newContent.length - value.length > 0
        ),
      deleted: yup
        .boolean()
    }),
    onSubmit: (values, { resetForm }) => {
      setLoading(true);

      if (values.deleted) {
        handleDelete();
      } else {
        const photoToDelete: number[] = [];
        values.deletedContent.forEach((val: number) => {
          photoToDelete.push(props.album.photos[val].id);
        });

        const album: Album = {
          id: props.album.id,
          name: props.album.name,
          creator: props.album.creator,
          creation_date: props.album.creation_date,
          photos: Array<Photo>(),
        }

        handleUpdate(album, values.newContent, photoToDelete, resetForm);
      }
    },
  });

  const handleDelete = async () => {
    try {
      await galleryService.removeAlbum(props.album);
      props.setNotification({ message: "L'album a bien été supprimé.", severity: 'success' });
    } catch (err: any) {
      setMessage({ message: formatError(err), severity: 'error' });
    } finally {
      setLoading(false);
    }
  }

  const handleUpdate = async (album: Album, photosToAdd: File[], photoToDelete: number[], resetForm: () => void) => {
    try {
      await galleryService.updateAlbum(album, photosToAdd, photoToDelete);
      props.setNotification({ message: "L'album a bien été modifiée.", severity: 'success' });
      resetForm();
    } catch (err: any) {
      setMessage({ message: formatError(err), severity: 'error' });
    } finally {
      setLoading(false);
    }
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      {message.message.length > 0 &&
        <Alert variant='filled' severity={message.severity} sx={{ mb: 6 }}>
          {message.message}
        </Alert>
      }

      <Dialog onClose={closePhoto} open={open}>
        <img src={img} className={css`width: 100%; max-height: 80vh; object-fit: contain;`} />
      </Dialog>

      {props.album.photos.map((photo: Photo, index: number) => (
        <Stack
          direction='row'
          justifyContent='flex-start'
          alignItems='center'
          spacing={2}
          sx={{ mb: 0.5, px: 0.5, overflowWrap: 'anywhere' }}
          key={index}
        >
          {
            !formik.values.deletedContent.includes(index) ?
              <Typography align='center'>{photo.title}</Typography>
              :
              <Typography align='center' className={css`text-decoration:line-through;`}>{photo.title}</Typography>
          }

          <Box sx={{ flexGrow: 1 }} />

          <Fab size='small' color='primary' style={{ transform: 'scale(0.9)' }} onClick={() => { handleClickOpen(photo.image) }}>
            <VisibilityIcon />
          </Fab>

          <Fab size='small' color='primary' style={{ transform: 'scale(0.9)' }} >
            {
              formik.values.deletedContent.includes(index) ?
                <CloseIcon onClick={() => {
                  formik.setFieldValue('deletedContent', formik.values.deletedContent.filter(val => val !== index))
                }} />
                :
                <CheckIcon onClick={() => {
                  formik.setFieldValue('deletedContent', [...formik.values.deletedContent, index])
                }} />
            }
          </Fab>
        </Stack>
      ))}

      <Typography align='left' color='#d32f2f' sx={{ pl: 3, fontSize: '0.75rem' }}>{formik.touched.deletedContent && formik.errors.deletedContent}</Typography>

      <Box
        display='flex'
        justifyContent='flex-start'
        alignItems='flex-start'
        sx={{ my: 4 }}
      >
        <Button
          variant='contained'
          component='label'
          endIcon={<UploadFileIcon />}>
          Ajouter des photos
          <input
            name='newContent'
            type='file'
            hidden
            onChange={(event) => {
              const files = event.target.files;
              if (!files) {
                formik.setFieldValue('newContent', []);
              } else {
                formik.setFieldValue('newContent', Array.from(files));
              }
            }}
            multiple
          />
        </Button>
      </Box>

      <Typography align='left' color='#d32f2f' sx={{ pl: 5, fontSize: '0.75rem' }}>{formik.errors.newContent?.toString()}</Typography>

      <Stack
        direction='column'
        justifyContent='flex-start'
        alignItems='flex-start'
        spacing={1}
        sx={{ px: 3 }}>
        {formik.values.newContent.map((value: File, index) => (
          <Typography key={index} variant='body2' align='left' sx={{ color: 'text.secondary' }} >{value.name}</Typography>
        ))}
      </Stack>

      {
        loading ?
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            sx={{ mt: 4, mb: 1 }}
          >
            <CircularProgress />
          </Box>
          :
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            sx={{ mt: 4, mb: 1 }}
          >
            <Button variant='contained' type='submit' endIcon={<EditIcon />} disabled={loading}>
              Modifier
            </Button>
            <DeleteConfirm message={"Êtes vous sur de vouloir supprimer l'album'?"} disabled={loading} action={() => { formik.setFieldValue(`deleted`, true); formik.submitForm() }} />
          </Stack>
      }
    </form >
  );
}