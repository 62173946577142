import React from 'react';
import { accountService } from '../../services/Service';
import { useObservable } from 'micro-observables';
import AvatarInfo from './AvatarInfo'
import MissionInfo from './MissionInfo'
import PasswordInfo from './PasswordInfo'
import ProfileInfo from './ProfileInfo'
import SignatureInfo from './SignatureInfo'
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';


export default function Profile() {

  const account = useObservable(accountService.account);

  if (!account) {
    return <div />
  }

  return (
    <div>
      <Paper elevation={3} sx={{ my: 3, py: 3 }}>
        <Container>
          <Stack
            direction='row'
            justifyContent='flex-start'
            alignItems='center'
            spacing={3}
          >
            <AvatarInfo />
            <Typography variant='h4' gutterBottom>{accountService.getFullName()}</Typography>
          </Stack>
        </Container>
      </Paper>

      <ProfileInfo />
      <MissionInfo />
      <SignatureInfo />
      <PasswordInfo />
    </div>
  );
}