import React, { useState, useEffect } from 'react';
import { useObservable } from 'micro-observables';
import { usersService } from '../../services/Service';
import { User } from '../../services/UsersService';
import { AlertMessage } from '../../common/dto';
import AuthorizationsEditor from './AuthorizationsEditor';
import AuthorizationsInfo from './AuthorizationsInfo';
import MissionEditor from './MissionEditor';
import MissionInfo from './MissionInfo';
import PasswordEditor from './PasswordEditor';
import PasswordInfo from './PasswordInfo';
import UserEditor from './UserEditor';
import UserInfo from './UserInfo';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import { AccordionSummary, Button } from '@mui/material';
import Alert from '@mui/material/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import Dialog from '@mui/material/Dialog';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';


export default function ModifyUser() {

  const users = useObservable(usersService.users);

  const userList = useObservable(usersService.getUsernameList());
  const [selectedUser, setSelectedUser] = useState<null | string>(null);

  const [openUserEditor, setUserOpenEditor] = useState(false);
  const [openMissionEditor, setMissionOpenEditor] = useState(false);
  const [openPasswordEditor, setPasswordOpenEditor] = useState(false);
  const [openAuthorizationEditor, setAuthorizationOpenEditor] = useState(false);
  const [userId, setUserId] = useState<number>(-1);
  const [message, setMessage] = useState<AlertMessage>({ message: '', severity: undefined });

  useEffect(() => {
    usersService.fetchUsers();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [message])

  const handlePannelChange =
    (index: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setUserId(isExpanded ? index : -1);
    };

  const openUserEditorWindow = () => {
    setUserOpenEditor(true);
  };

  const closeUserEditorWindow = () => {
    setUserOpenEditor(false);
  };

  const openMissionEditorWindow = () => {
    setMissionOpenEditor(true);
  };

  const closeMissionEditorWindow = () => {
    setMissionOpenEditor(false);
  };

  const openPasswordEditorWindow = () => {
    setPasswordOpenEditor(true);
  };

  const closePasswordEditorWindow = () => {
    setPasswordOpenEditor(false);
  };

  const openAuthorizationEditorWindow = () => {
    setAuthorizationOpenEditor(true);
  };

  const closeAuthorizationEditorWindow = () => {
    setAuthorizationOpenEditor(false);
  };

  return (
    <div>
      {message.message.length > 0 &&
        <Alert variant='filled' severity={message.severity} sx={{ my: 4 }}>
          {message.message}
        </Alert>
      }

      {
        users.length > 0 &&
        <div>
          <Dialog onClose={() => { closeUserEditorWindow() }} open={openUserEditor}>
            <UserEditor user={users[userId]} setNotification={setMessage} close={closeUserEditorWindow} />
          </Dialog>

          <Dialog onClose={() => { closeMissionEditorWindow() }} open={openMissionEditor}>
            <MissionEditor user={users[userId]} />
          </Dialog>

          <Dialog onClose={() => { closePasswordEditorWindow() }} open={openPasswordEditor}>
            <PasswordEditor user={users[userId]} />
          </Dialog>

          <Dialog onClose={() => { closeAuthorizationEditorWindow() }} open={openAuthorizationEditor}>
            <AuthorizationsEditor user={users[userId]} />
          </Dialog>
        </div>
      }

      <Autocomplete
        disablePortal
        options={userList}
        sx={{ width: 300, mb: 4 }}
        renderInput={(params) => <TextField {...params} label='Utilisateur' />}
        onChange={(event, value) => setSelectedUser(value)}
      />

      {users.map((user: User, index: number) => {

        return (
          <div key={index}>
            <Accordion TransitionProps={{ unmountOnExit: true }} expanded={userId === index} onChange={handlePannelChange(index)} key={index} sx={{ my: 1, display: !selectedUser || user.first_name + ' ' + user.last_name === selectedUser ? 'block' : 'none' }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel1bh-content'
              >
                <Button
                  variant='contained'
                  type='submit'
                  style={{ backgroundColor: user.is_active ? 'green' : 'red' }}
                  onClick={async () => {
                    try {
                      await usersService.updateStatus(user.id, !user.is_active);
                      setMessage({ message: 'Status de l utilisateur modifié', severity: 'success' });
                    } catch (err: any) {
                      setMessage({ message: err, severity: 'error' });
                    }
                    user.is_active = !user.is_active;
                  }}
                >
                  {user.is_active ? 'Actif' : 'Inactif'}
                </Button>
                <Typography align='left' variant={userId === index ? 'h5' : 'inherit'} sx={{ my: 1, mx: 2 }}>{user.first_name + ' ' + user.last_name}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <UserInfo user={user} openUserEditorWindow={() => openUserEditorWindow()} />
                <MissionInfo user={user} openMissionEditorWindow={() => openMissionEditorWindow()} />
                <PasswordInfo user={user} openPasswordEditorWindow={() => openPasswordEditorWindow()} />
                <AuthorizationsInfo user={user} openAuthorizationEditorWindow={() => openAuthorizationEditorWindow()} />
              </AccordionDetails>
            </Accordion >
          </div>
        )
      })
      }
    </div>
  );
}