import React, { useState, useEffect } from 'react';
import { accountService } from '../../services/Service';
import { AlertMessage } from '../../common/dto';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import EditIcon from '@mui/icons-material/Edit';
import Typography from '@mui/material/Typography';


export default function SignatureUpload() {

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<AlertMessage>({ message: '', severity: undefined });

  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [imageUrl, setImageUrl] = useState<string | null>(null);

  useEffect(() => {
    if (selectedImage) {
      setImageUrl(URL.createObjectURL(selectedImage));
    }
  }, [selectedImage]);

  const saveSign = async () => {
    setLoading(true);

    if (!selectedImage) {
      setMessage({ message: "Aucune image n'est uploadée pour le moment", severity: 'error' });
    } else {
      try {
        if (selectedImage.size > 10e5) {
          setMessage({ message: 'Votre image ne doit pas dépasser 1Mo', severity: 'error' });
        } else {
          await accountService.updateSignImage(selectedImage);
          setMessage({ message: 'Signature enregistrée', severity: 'success' });
        }
      } catch (err: any) {
        setMessage(err.response.data.detail.toString());
      }
    }

    setLoading(false);
  }

  return (
    <Container sx={{ my: 4, width: 'auto' }} >

      {message.message.length > 0 &&
        <Alert variant='filled' severity={message.severity} sx={{ mb: 6 }}>
          {message.message}
        </Alert>
      }

      <Typography align='left' variant='h4' sx={{ mb: 6 }}>Signature</Typography>

      <input
        type='file'
        accept='image/png, image/jpeg, image/jpg'
        multiple={false}
        id='select-image'
        style={{ display: 'none' }}
        onChange={(e) => { if (e.target.files) setSelectedImage(e.target.files[0]) }}
      />
      <label htmlFor='select-image'>
        <Button variant='contained' component='span'>
          Upload Image
        </Button>
      </label>
      {imageUrl && selectedImage && (
        <Box sx={{ mt: 4 }} textAlign='center'>
          <img src={imageUrl} alt={selectedImage.name} width='400px' height='150px' />
        </Box>
      )}

      <Box sx={{ mt: 4 }}>
        {
          loading ?
            <CircularProgress />
            :
            <Button variant='contained' type='submit' onClick={saveSign} endIcon={<EditIcon />} disabled={loading}>
              Modifier
            </Button>
        }
      </Box>
    </Container>
  );
}