import React, { useState } from 'react';
import { useObservable } from 'micro-observables';
import { accountService } from '../../services/Service';
import ProfileEditor from './ProfileEditor'
import dayjs from 'dayjs';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import EditIcon from '@mui/icons-material/Edit';
import Fab from '@mui/material/Fab';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';


export default function ProfileInfo() {

  const account = useObservable(accountService.account);
  const [open, setOpen] = useState(false);

  const openEditor = () => {
    setOpen(true);
  };

  const closeEditor = () => {
    setOpen(false);
  };

  if (!account) {
    return <div />
  }

  return (
    <Paper elevation={3} sx={{ my: 3, py: 2 }}>

      <Dialog onClose={closeEditor} open={open}>
        <ProfileEditor account={account} />
      </Dialog>

      <Container>
        <Grid
          container
          direction='row'
          justifyContent='flex-start'
          alignItems='center'
          spacing={2}
        >

          <Grid item>
            <Typography variant='h5'>Information du profil</Typography>
          </Grid>

          <Grid item sx={{ flexGrow: 1 }} />

          <Grid item>
            <Fab size='small' color='primary' aria-label='change image' onClick={openEditor}>
              <EditIcon />
            </Fab>
          </Grid>

          <Box sx={{ width: '100%' }} />

          <Grid item xs={5} sm={4} md={3}>
            <Typography align='left' sx={{ color: 'text.secondary' }}>Nom complet</Typography>
          </Grid>

          <Grid item xs={7} sm={8} md={9}>
            <Typography align='left'>{accountService.getFullName()}</Typography>
          </Grid>

          <Box sx={{ width: '100%' }} />

          <Grid item xs={5} sm={4} md={3}>
            <Typography align='left' sx={{ color: 'text.secondary' }}>Date de naissance</Typography>
          </Grid>

          <Grid item xs={7} sm={8} md={9}>
            <Typography align='left'>{dayjs(account.date_of_birth, 'YYYY-MM-DD').format('DD/MM/YYYY')}</Typography>
          </Grid>

          <Box sx={{ width: '100%' }} />

          <Grid item xs={5} sm={4} md={3}>
            <Typography align='left' sx={{ color: 'text.secondary' }}>Adresse e-mail</Typography>
          </Grid>

          <Grid item xs={7} sm={8} md={9}>
            <Typography align='left'>{account.email}</Typography>
          </Grid>

          <Box sx={{ width: '100%' }} />

          <Grid item xs={5} sm={4} md={3}>
            <Typography align='left' sx={{ color: 'text.secondary' }}>Numéro de téléphone</Typography>
          </Grid>

          <Grid item xs={7} sm={8} md={9}>
            <Typography align='left'>{account.phone}</Typography>
          </Grid>

          <Box sx={{ width: '100%' }} />

          <Grid item xs={5} sm={4} md={3}>
            <Typography align='left' sx={{ color: 'text.secondary' }}>Date d&apos;arrivée</Typography>
          </Grid>

          <Grid item xs={7} sm={8} md={9}>
            <Typography align='left'>{dayjs(account.arrival, 'YYYY-MM-DD').format('DD/MM/YYYY')}</Typography>
          </Grid>
        </Grid>
      </Container>
    </Paper>
  );
}