import React, { useState } from 'react';
import { Photo } from '../../services/GalleryService';
import SwipeableGallery from './SwipeableGallery';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';


interface Props {
  photos: Photo[];
}

export default function MasonryGallery(props: Props) {

  const [open, setOpen] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = (imageIndex: number) => {
    setOpen(true);
    setImageIndex(imageIndex);
  };

  return (
    <Container>
      <Box>
        <Dialog onClose={handleClose} open={open}>
          <SwipeableGallery photos={props.photos} imageIndex={imageIndex}/>
        </Dialog>
      </Box>

      <Box sx={{ height: 1000, overflowY: 'scroll', mx: 0 }}>
        <ImageList variant='masonry' cols={3} gap={8} >
          {props.photos.map((photo: Photo, index: number) => (
            <ImageListItem key={photo.title}>
              <img
                src={photo.image}
                srcSet={photo.image}
                alt={photo.title}
                loading='lazy'
                onClick={() => handleOpen(index)}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
    </Container>
  );
}