import { observable } from 'micro-observables';
import { AccountService } from './AccountService';
import dayjs, { Dayjs } from 'dayjs';
import axios from 'axios';
import download from 'downloadjs';


export const midDayStatus = ['Jour travaillé', 'Télétravail', 'Weekend', 'Jour travaillé non facturé', 'Jour férié', 'Congé payé', 'RTT', 'Congé maladie', 'Congé maternité', 'Congé paternité', 'Évènement familial', 'Autre'];
type MidDayStatus = typeof midDayStatus[number];

export interface RMA {
  id: number | null;
  creator: number;
  date: string;
  creation_date: string;
  modification_date: string;
  content: string;
  is_signed: boolean;
  sign_date?: string;
}

export interface Day {
  number: number;
  am: boolean;
  pm: boolean;
  am_status: MidDayStatus;
  pm_status: MidDayStatus;
  night_first_part: boolean;
  night_second_part: boolean;
  call_on_day: boolean;
}

export class RMAService {
  private _rmas = observable<RMA[]>([]);
  readonly rmas = this._rmas.readOnly();

  constructor(private accountService: AccountService) { }

  async fetchUserRMAs() {
    const account = this.accountService.account.get();

    if (account) {
      const token = this.accountService.token.get();
      const config = {
        'headers': {
          'Authorization': `Bearer ${token}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      };
      const res = await axios.get(`/api/user_rma/${account.id}/`, config);

      const rmas = res.data;
      rmas.sort((a: RMA, b: RMA) => a.date < b.date ? 1 : -1);
      this._rmas.set(rmas);
    }
  }

  async fetchMonthRMAs(year: number, month: number) {
    const token = this.accountService.token.get();
    const config = {
      'headers': {
        'Authorization': `Bearer ${token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    };
    const res = await axios.get(`/api/month_rma/${year}/${month}/`, config);
    this._rmas.set(res.data);
  }

  async addRMA(rma: RMA) {
    const account = this.accountService.account.get();

    if (account) {
      const token = this.accountService.token.get();
      const config = {
        'headers': {
          'Authorization': `Bearer ${token}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      };
      const data = {
        creator: account.id,
        date: rma.date,
        creation_date: rma.creation_date,
        modification_date: rma.modification_date,
        content: rma.content,
        is_signed: false,
      };

      await axios.post('/api/rma/', JSON.stringify(data), config);
    }
  }

  async updateRMA(rma: RMA) {
    const token = this.accountService.token.get();
    const config = {
      'headers': {
        'Authorization': `Bearer ${token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    };
    const data = {
      creator: rma.creator,
      date: rma.date,
      creation_date: rma.creation_date,
      modification_date: rma.modification_date,
      content: rma.content,
      is_signed: false,
    };

    const res = await axios.put(`/api/rma/${rma.id}/`, JSON.stringify(data), config);
    const rmas = this.rmas.get().filter(rma => rma.id !== res.data.id);
    rmas.push(res.data);
    rmas.sort((a: RMA, b: RMA) => a.date < b.date ? 1 : -1);
    this._rmas.set(rmas);
  }

  async signRMA(rma: RMA) {
    const token = this.accountService.token.get();
    const config = {
      'headers': {
        'Authorization': `Bearer ${token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    };
    const data = {
      creator: rma.creator,
      date: rma.date,
      creation_date: rma.creation_date,
      modification_date: rma.modification_date,
      content: rma.content,
      is_signed: !rma.is_signed,
      sign_date: dayjs().format('YYYY-MM-DD').toString(),
    };

    const res = await axios.put(`/api/rma/${rma.id}/`, JSON.stringify(data), config);
    const rmas = this.rmas.get().filter(rma => rma.id !== res.data.id);
    rmas.push(res.data);
    rmas.sort((a: RMA, b: RMA) => a.date < b.date ? 1 : -1);
    this._rmas.set(rmas);
  }

  getMonthRMA() {
    return this.rmas.select(arr => arr.filter(rma => rma.date == dayjs().format('YYYY-MM-01'))[0]);
  }

  getUserRMA(id: number) {
    const rmas = this.rmas.get();
    return rmas.filter(rma => rma.creator === id)[0];
  }

  getMonthCalendar(rma: RMA) {
    dayjs.Ls.en.weekStart = 1;
    const startOfWeek = dayjs(rma.date, 'YYYY-MM-DD').startOf('week');
    const number_day = dayjs(rma.date, 'YYYY-MM-DD').daysInMonth();
    const endOfWeek = dayjs(rma.date, 'YYYY-MM-DD').set('date', number_day).endOf('week');
    const number_day_calendar = endOfWeek.diff(startOfWeek, 'day') + 1;
    const calendar = new Array<Dayjs>();

    for (let i = 0; i < number_day_calendar; i++) {
      const date = startOfWeek.add(i, 'day');
      calendar.push(date);
    }

    return calendar;
  }

  getFormularInitialValues(rma: RMA) {
    const content = JSON.parse(rma.content);
    const initialValues: Day[] = [];

    for (const key in content) {
      const value = content[key];
      const day: Day = {
        number: value.number,
        am: value.am === 'True',
        pm: value.pm === 'True',
        am_status: value.am_status,
        pm_status: value.pm_status,
        call_on_day: value.call_on_day === 'True',
        night_first_part: value.night_first_part === 'True',
        night_second_part: value.night_second_part === 'True',
      }
      initialValues.push(day);
    }

    return initialValues;
  }

  async downloadPDF(id: number, date: string) {
    const account = this.accountService.account.get();

    if (account) {
      const token = this.accountService.token.get();

      const res = await axios.get(
        `/api/rma_pdf/${id}/`,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          responseType: 'blob'
        }
      );

      const filetype = res.headers['content-type'];
      const month = dayjs(date).format('MM_YYYY').toString();
      const filename = `${account.first_name}_${account.last_name}_${month}`;
      download(res.data, filename, filetype);
    }
  }

  async downloadReport(year: number, month: number) {
    const token = this.accountService.token.get();
    const res = await axios.get(
      `/api/salary_report/${year}/${month}/`,
      {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        responseType: 'blob'
      }
    );

    const filetype = res.headers['content-type'];
    const filename = `${dayjs().format('YYYY_MM_DD-HH_mm')}-Tabeau_des_salaires`;
    download(res.data, filename, filetype);
  }
}