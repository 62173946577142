import React, { useState, useEffect, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { useObservable } from 'micro-observables';
import { rmaService, organizationalService } from '../../services/Service';
import { Profil } from '../../services/OrganizationalService';
import { AlertMessage } from '../../common/dto';
import { formatError } from '../../common/formatError';
import dayjs from 'dayjs';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import CheckIcon from '@mui/icons-material/Check';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import DownloadIcon from '@mui/icons-material/Download';
import EditIcon from '@mui/icons-material/Edit';
import Fab from '@mui/material/Fab';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Typography from '@mui/material/Typography';


dayjs.Ls.en.months = [
  'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet',
  'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'
]

// FIXME: When modified RMA for a user, who has not created his RMA yet, the RMA is set to admin's RMA

export default function RMAManagement() {

  const users = useObservable(organizationalService.profils);
  const [month, setMonth] = useState(dayjs().format('YYYY/MM'));
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<AlertMessage>({ message: '', severity: undefined });

  const navigate = useNavigate();

  useEffect(() => {
    const today = dayjs();
    const year = parseInt(today.format('YYYY'));
    const month = parseInt(today.format('MM'));
  
    organizationalService.fetchProfils();
    loadRMAs(year, month);
  }, []);
  
  const loadRMAs = async (year: number, month: number) => {
    setLoading(true);
    try {
      await rmaService.fetchMonthRMAs(year, month);
    } catch (err: any) {
      setMessage({ message: formatError(err), severity: 'error' });
    } finally {
      setLoading(false);
    }
  };
  
  const handleChange = async (event: SelectChangeEvent) => {
    const newMonth = event.target.value;
    setMonth(newMonth);
  
    const year = parseInt(newMonth.split('/')[0]);
    const month = parseInt(newMonth.split('/')[1]);
  
    loadRMAs(year, month);
  };

  const downloadPDF = async (id: number | null, date: string) => {
    if (id) {
      try {
        await rmaService.downloadPDF(id, date);
      } catch (err: any) {
        setMessage({ message: formatError(err), severity: 'error' });
      }
    }
  }

  const downloadReport = async (year: number, month: number) => {
    try {
      await rmaService.downloadReport(year, month);
    } catch (err: any) {
      setMessage({ message: formatError(err), severity: 'error' });
    }
  }

  return (
    <Container>
      {message.message.length > 0 &&
        <Alert variant='filled' severity={message.severity} sx={{ mb: 6 }}>
          {message.message}
        </Alert>
      }

      <Typography align='left' variant='h4' sx={{ mt: 6, mb: 4 }}>RMA</Typography>

      {
        loading ?
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            sx={{ mt: 4 }}
          >
            <CircularProgress />
          </Box>
          :
          <div>
            <Box display='flex' justifyContent='center' alignItems='center' sx={{ my: 4 }}>
              <FormControl sx={{ width: 200 }}>
                <InputLabel>Mois</InputLabel>
                <Select
                  value={month}
                  label='Mois'
                  onChange={handleChange}
                >
                  {
                    Array.from({ length: 12 }).map((item: any, index: number) =>
                      <MenuItem value={`${dayjs().subtract(index, 'month').year()}/${dayjs().subtract(index, 'month').format('MM')}`} key={index}>{`${dayjs().subtract(index, 'month').year()} - ${dayjs().subtract(index, 'month').format('MMMM')}`}</MenuItem>
                    )
                  }
                </Select>
              </FormControl>
            </Box>

            <List sx={{ width: '100%', bgcolor: 'background.paper', overflowWrap: 'anywhere' }}>
              <ListItem disablePadding >
                <ListItemText primary='Salarié' sx={{ width: '16.5%' }} />
                <ListItemText primary='Création' sx={{ width: '16.5%' }} />
                <ListItemText primary='Modification' sx={{ width: '16.5%' }} />
                <ListItemText primary='Signature' sx={{ width: '16.5%' }} />
                <ListItemText primary='Modifier' sx={{ width: '16.5%', textAlign: 'center' }} />
                <ListItemText primary='Exporter' sx={{ width: '16.5%', textAlign: 'center' }} />
              </ListItem>

              <Divider sx={{ my: 2 }} />

              {
                users.filter((user: Profil) => user.active).map((user: Profil, index: number) => (

                  <ListItem key={index} disablePadding >
                    <ListItemText primary={user.first_name + ' ' + user.last_name} sx={{ width: '16.5%' }} />

                    <ListItemText
                      sx={{ width: '16.5%' }}
                      primary={
                        rmaService.getUserRMA(user.id) && rmaService.getUserRMA(user.id).id ?
                          dayjs(rmaService.getUserRMA(user.id).creation_date).format('DD/MM/YYYY')
                          :
                          <CloseIcon sx={{ color: 'red' }} />
                      } />

                    <ListItemText
                      sx={{ width: '16.5%' }}
                      primary={
                        rmaService.getUserRMA(user.id) && rmaService.getUserRMA(user.id).id ?
                          dayjs(rmaService.getUserRMA(user.id).modification_date).format('DD/MM/YYYY')
                          :
                          <CloseIcon sx={{ color: 'red' }} />
                      } />

                    <ListItemText
                      sx={{ width: '16.5%' }}
                      primary={
                        rmaService.getUserRMA(user.id) && rmaService.getUserRMA(user.id).id && rmaService.getUserRMA(user.id).is_signed ?
                          <CheckIcon sx={{ color: 'green' }} />
                          :
                          <CloseIcon sx={{ color: 'red' }} />
                      } />

                    <ListItemText
                      sx={{ width: '16.5%', textAlign: 'center' }}
                      primary={
                          <Fab size='small' color='primary' style={{ transform: 'scale(0.9)' }} onClick={() => { navigate('/rma_editor', { state: { rma: rmaService.getUserRMA(user.id), back: '/rma_management' } }); }} aria-label="Edit RMA">
                            <EditIcon />
                          </Fab>
                      } />

                    <ListItemText
                      sx={{ width: '16.5%', textAlign: 'center' }}
                      primary={
                        <Fragment >
                          {
                            rmaService.getUserRMA(user.id) && rmaService.getUserRMA(user.id).id ?
                              <Fab size='small' color='primary' style={{ transform: 'scale(0.9)' }} onClick={() => downloadPDF(rmaService.getUserRMA(user.id).id, rmaService.getUserRMA(user.id).date)} aria-label="Download PDF">
                                <DownloadIcon />
                              </Fab>
                              :
                              <Fab disabled={true} size='small' color='primary' style={{ transform: 'scale(0.9)' }} aria-label="Download Disabled">
                                <DownloadIcon />
                              </Fab>
                          }
                        </Fragment>
                      } />
                  </ListItem>
                ))}
            </List>

            {
              users.filter(user => !(rmaService.getUserRMA(user.id) && rmaService.getUserRMA(user.id).id)).length > 0 ?
                <Alert severity='error' sx={{ my: 4 }}>
                  <Typography align='left'>{"Les salariés suivants n'ont pas encore rempli leur RMA ce mois ci : " + users.filter(user => !(rmaService.getUserRMA(user.id) && rmaService.getUserRMA(user.id).id)).map(user => user.first_name + ' ' + user.last_name).join(', ')}</Typography>
                </Alert>
                :
                <div />
            }

            {
              users.filter(user => (rmaService.getUserRMA(user.id) && rmaService.getUserRMA(user.id).id && dayjs(rmaService.getUserRMA(user.id).modification_date) > dayjs(month + '/26'))).length > 0 ?
                <Alert severity='warning' sx={{ my: 4 }}>
                  <Typography align='left'>{"Les salariés suivants ont modifié leur RMA après le 26 du mois : " + users.filter(user => (rmaService.getUserRMA(user.id) && rmaService.getUserRMA(user.id).id && dayjs(rmaService.getUserRMA(user.id).modification_date) > dayjs(month + '/26') )).map(user => user.first_name + ' ' + user.last_name).join(', ')}</Typography>
                </Alert>
                :
                <div />
            }

            <Typography align='left' variant='h4' sx={{ mt: 8, mb: 4 }}>Télécharger le tableau des salaires</Typography>
            <Box
              display='flex'
              justifyContent='flex-start'
              alignItems='flex-start'
            >
              <Fab size='small' color='primary' style={{ transform: 'scale(1.2)' }} onClick={() => downloadReport(parseInt(month.split('/')[0]), parseInt(month.split('/')[1]))} aria-label="Download Report">
                <DownloadIcon />
              </Fab>
            </Box>
          </div>
      }
    </Container >
  );
}