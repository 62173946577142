import React from 'react';
import { MyTab } from '../../common/dto';
import TabSelector from '../../common/TabSelector';
import AddUser from './AddUser';
import ModifyUser from './ModifyUser';
import UserHistory from './UserHistory';


export default function UserManagement() {

  const tabs: MyTab[] = [
    {label: 'Ajouter un utilisateur', content: <AddUser />},
    {label: 'Gestion des utilisateurs', content: <ModifyUser />},
    {label: 'Historique des utilisateurs', content: <UserHistory />},
  ]

  return (
    <TabSelector tabs={tabs} />
  );
}