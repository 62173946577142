import React, { useState } from 'react';
import { accountService } from '../../services/Service';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Footer from '../../common/Footer'
import logo from '../../assets/xrays_logo_white.svg';
import Alert from '@mui/material/Alert';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import LoginIcon from '@mui/icons-material/Login';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { css } from '@emotion/css';
import { AlertMessage } from '../../common/dto';

export default function ForgotPassword() {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<AlertMessage>({ message: '', severity: undefined });
  const navigate = useNavigate();

  const handleReset = async (email: string) => {
    try {
      await accountService.forgotPassword(email);
    } catch (err: any) {
      // Ne rien faire en cas d'erreur
    } finally {
      setMessage({ message: 'Si un compte existe à cette adresse email, un mail de réinitialisation a été envoyé.', severity: 'success' });
      setLoading(false);
    }
  }


  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .trim()
        .email('Email invalide')
        .required('Email requis')
    }),
    onSubmit: (values) => {
      setLoading(true);
      handleReset(values.email);
    },
  });

  return (
    <div>
      <div className={css`position: relative; min-height: 86vh;`}>
        <Box sx={{ flexGrow: 1 }}>
          <AppBar position='fixed'>
            <Toolbar>
              <Box component='img' alt='XRAYS TRADING logo' src={logo} onClick={() => {navigate('/login')}} sx={{ height: '40px' }} />
            </Toolbar>
          </AppBar>
        </Box>

        <div className={css`padding-bottom: 200px;`}>
          <form onSubmit={formik.handleSubmit}>

            <Stack
              direction='column'
              justifyContent='left'
              alignItems='center'
              sx={{ mt: 10 }}
            >
              <Typography align='left' variant='h4' sx={{ my: 3 }}>Reset Password</Typography>

              <Box sx={{ my: 2 }}>
                {message?.message && (
                  <Alert variant='filled' severity={message.severity}>
                    {message.message}
                  </Alert>
                )}
              </Box>

              <TextField
                name='email'
                label='email'
                size='small'
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                sx={{ my: 2 }}
              />

            </Stack>

            <Box sx={{ my: 2 }}>
              {
                loading ?
                  <CircularProgress />
                  :
                  <Button variant='contained' type='submit' endIcon={<LoginIcon />} disabled={loading}>
                    Reset Password
                  </Button>
              }
            </Box>
          </form>
        </div>
        <div className={css`position: absolute; bottom: 0; width: 100%; height: 200px;`}>
          <Footer />
        </div>
      </div>
    </div>
  );
}