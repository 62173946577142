import React, { useState, Fragment } from 'react';
import { useObservable, useMemoizedObservable } from 'micro-observables';
import { holidaysService } from '../../services/Service';
import { Holiday } from '../../services/HolidaysService';
import { AlertMessage } from '../../common/dto';
import { formatError } from '../../common/formatError';
import HolidaysFormular from './HolidaysFormular';
import RTTFormular from './RTTFormular';
import DeleteConfirm from '../../common/DeleteConfirm';
import dayjs from 'dayjs';
import Alert from '@mui/material/Alert';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import Box from '@mui/material/Box';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Collapse from '@mui/material/Collapse';
import Dialog from '@mui/material/Dialog';
import Divider from '@mui/material/Divider';
import EditIcon from '@mui/icons-material/Edit';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Fab from '@mui/material/Fab';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Pagination from '@mui/material/Pagination';
import RefreshIcon from '@mui/icons-material/Refresh';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { css } from '@emotion/css';
import * as yup from 'yup'


export default function ListView() {

  const holidaysRequested = useMemoizedObservable(() => holidaysService.getHolidaysRequested(), []);
  const holidaysValidate = useObservable(holidaysService.getHoldaysValidated());

  const [open, setOpen] = useState<number[]>([]);
  const [openEditor, setOpenEditor] = useState<number[]>([]);
  const itemsPerPage = 25;
  const [page, setPage] = useState(1);
  const [noOfPages] = useState(Math.ceil(holidaysValidate.length / itemsPerPage));
  const [message, setMessage] = useState<AlertMessage>({ message: '', severity: undefined });
  const [loading, setLoading] = useState(false);

  const handleChange = (event: React.ChangeEvent<any>, value: number) => {
    setPage(value);
  };

  const handleClick = (index: number) => {
    setOpen(prevState => prevState.includes(index) ? prevState.filter(val => val !== index) : [...prevState, index]);
  };

  const openEditorWindow = (index: number) => {
    setOpenEditor(prevState => prevState.includes(index) ? prevState.filter(val => val !== index) : [...prevState, index]);
  };

  const closeEditorWindow = (index: number) => {
    setOpenEditor(prevState => prevState.includes(index) ? prevState.filter(val => val !== index) : [...prevState, index]);
  };

  const handleDelete = async (holiday: Holiday) => {
    setLoading(true);

    try {
      await holidaysService.removeHoliday(holiday);
      setMessage({ message: "La demande d'absence a bien été annulée.", severity: 'success' });
    } catch (err: any) {
      if (err.message === "You cannot delete a validated holiday") {
        window.location.reload();
      } else {
        setMessage({ message: formatError(err), severity: 'error' });
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <div>
      {message.message.length > 0 &&
        <Alert variant='filled' severity={message.severity} sx={{ mb: 6 }}>
          {message.message}
        </Alert>
      }

      {
        holidaysRequested.length == 0 ?
          <Typography align='left' variant='h5' sx={{ color: 'text.secondary', my: 4 }}>Vous n&apos;avez aucune demande d&apos;absence en cours pour le moment.</Typography>
          :
          <List
            sx={{ width: '100%', mb: 4 }}
            component='nav'
            subheader={
              <ListSubheader component='div' className={css`text-align: start;`}>
                Mes demandes en cours
              </ListSubheader>
            }
          >
            {holidaysRequested.map((request: Holiday, index: number) => (
              <div key={index}>
                {
                  request.holiday_type === 'RTT' ?
                    <Dialog onClose={() => { closeEditorWindow(index) }} open={openEditor.includes(index) || false}>
                      <RTTFormular holiday={request} setNotification={setMessage} close={() => closeEditorWindow(index)} />
                    </Dialog>
                    :
                    <Dialog onClose={() => { closeEditorWindow(index) }} open={openEditor.includes(index) || false}>
                      <HolidaysFormular holiday={request} setNotification={setMessage} close={() => closeEditorWindow(index)} />
                    </Dialog>
                }

                <ListItemButton onClick={() => handleClick(index)}>
                  <ListItemIcon>
                    <RefreshIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={request.holiday_type}
                    secondary={
                      <Fragment >
                        {
                          request.holiday_type === 'RTT' ?
                            <Typography component='span' variant='body2' >
                              {dayjs(request.start_date).format('DD/MM/YYYY')}
                            </Typography>
                            :
                            <Typography component='span' variant='body2' >
                              {dayjs(request.start_date).format('DD/MM/YYYY')}
                              <ArrowRightAltIcon style={{ verticalAlign: 'middle' }} sx={{ mx: 1 }} />
                              {dayjs(request.end_date).format('DD/MM/YYYY')}
                            </Typography>
                        }
                      </Fragment>
                    } />
                  {open.includes(index) ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={open.includes(index)} timeout='auto' unmountOnExit>
                  <Stack
                    direction='column'
                    justifyContent='flex-start'
                    alignItems='flex-start'
                    sx={{ pl: 9 }}
                  >
                    {
                      request.holiday_type === 'RTT' ?
                        <Typography align='left' sx={{ my: 1, color: 'text.secondary' }}>Absence le {dayjs(request.start_date).format('DD/MM/YYYY')} (1j).</Typography>
                        :
                        <Typography align='left' sx={{ my: 1, color: 'text.secondary' }}>Absence débutant le {dayjs(request.start_date).format('DD/MM/YYYY')} {request.begin_at_midday ? "en début d'après-midi" : 'matin'} et terminant le {dayjs(request.end_date).format('DD/MM/YYYY')} {request.end_at_midday ? 'en fin de matinée' : 'soir'} ({parseFloat(request.duration.toString())}j).</Typography>
                    }

                    <Typography align='left' sx={{ mb: 1, color: 'text.secondary' }}>
                      Demande réalisée le {dayjs(request.asking_date).format('DD/MM/YYYY')}.
                    </Typography>

                    <Tooltip title="Éditer ma demande d'absence">
                      <Fab size='small' color='primary' sx={{ my: 2 }} onClick={() => { openEditorWindow(index) }}>
                        <EditIcon />
                      </Fab>
                    </Tooltip>
                  </Stack>
                </Collapse>
                <Divider sx={{ my: 0 }} />
              </div>
            ))}
          </List>
      }

      {
        holidaysValidate.length == 0 ?
          <Typography align='left' variant='h5' sx={{ color: 'text.secondary', my: 4 }}>Vous n&apos;avez aucune absence déclaré et validée pour le moment.</Typography>
          :
          <div>
            <List
              sx={{ width: '100%', mb: 4 }}
              component='nav'
              subheader={
                <ListSubheader component='div' className={css`text-align: start;`}>
                  Mes précédentes demandes
                </ListSubheader>
              }
            >
              {holidaysValidate.slice((page - 1) * itemsPerPage, page * itemsPerPage).map((holiday: Holiday, index: number) => (
                <div key={index}>
                  <ListItemButton onClick={() => handleClick(index + holidaysRequested.length)}>
                    <ListItemIcon>
                      {
                        holiday.is_accepted ?
                          <CheckIcon sx={{ color: 'green' }} />
                          :
                          <CloseIcon sx={{ color: 'red' }} />
                      }
                    </ListItemIcon>
                    <ListItemText
                      primary={holiday.holiday_type}
                      secondary={
                        <Fragment >
                          {
                            holiday.holiday_type === 'RTT' ?
                              <Typography component='span' variant='body2' sx={{ color: dayjs(holiday.start_date) > dayjs() && holiday.is_accepted ? 'green' : 'text.secondary' }}>
                                {dayjs(holiday.start_date).format('DD/MM/YYYY')}
                              </Typography>
                              :
                              <Typography component='span' variant='body2' sx={{ color: dayjs(holiday.start_date) > dayjs() && holiday.is_accepted ? 'green' : 'text.secondary' }}>
                                {dayjs(holiday.start_date).format('DD/MM/YYYY')}
                                <ArrowRightAltIcon style={{ verticalAlign: 'middle' }} sx={{ mx: 1 }} />
                                {dayjs(holiday.end_date).format('DD/MM/YYYY')}
                              </Typography>
                          }
                        </Fragment>
                      } />
                    {open.includes(index + holidaysRequested.length) ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse in={open.includes(index + holidaysRequested.length)} timeout='auto' unmountOnExit>
                    <Stack
                      direction='column'
                      justifyContent='flex-start'
                      alignItems='flex-start'
                      sx={{ pl: 9 }}
                    >

                      {
                        holiday.holiday_type === 'RTT' ?
                          <Typography align='left' sx={{ my: 1, color: 'text.secondary' }}>Absence le {dayjs(holiday.start_date).format('DD/MM/YYYY')} (1j).</Typography>
                          :
                          <Typography align='left' sx={{ my: 1, color: 'text.secondary' }}>Absence débutant le {dayjs(holiday.start_date).format('DD/MM/YYYY')} {holiday.begin_at_midday ? "en début d'après-midi" : 'matin'} et terminant le {dayjs(holiday.end_date).format('DD/MM/YYYY')} {holiday.end_at_midday ? 'en fin de matinée' : 'soir'} ({parseFloat(holiday.duration.toString())}j).</Typography>
                      }

                      {
                        holiday.is_accepted ?
                          <div>
                            <Typography align='left' sx={{ mb: 1, color: 'text.secondary' }}>
                              Demande réalisée le {dayjs(holiday.asking_date).format('DD/MM/YYYY')} et accéptée le {dayjs(holiday.validation_date).format('DD/MM/YYYY')}.
                            </Typography>
                            {
                              dayjs(holiday.start_date) > dayjs() ?
                                <Tooltip title="Vous pouvez annuler votre demande d'absence car elle n'a pas encore débutée.">
                                  <Box display='flex' sx={{ my: 2 }}>
                                    <DeleteConfirm message={"Êtes vous sur de vouloir supprimer votre demande d'absence?"} disabled={loading} action={() => { handleDelete(holiday) }} />
                                  </Box>
                                </Tooltip>
                                :
                                <div />
                            }
                          </div>
                          :
                          <div>
                            <Typography align='left' sx={{ color: 'text.secondary' }}>
                              Demande réalisée le {dayjs(holiday.asking_date).format('DD/MM/YYYY')} et refusée le {dayjs(holiday.validation_date).format('DD/MM/YYYY')}.
                            </Typography>
                            <Typography align='left' sx={{ mb: 1, color: 'text.secondary' }}>
                              Raison du refus: {holiday.reason != '' ? holiday.reason : 'Pas de raison spécfiée'}.
                            </Typography>
                          </div>
                      }
                    </Stack>
                  </Collapse>
                  <Divider sx={{ my: 0 }} />
                </div>
              ))}
            </List>
            <Box
              display='flex'
              justifyContent='center'
              alignItems='center'
              sx={{ mt: 2 }}
            >
              <Pagination
                count={noOfPages}
                page={page}
                defaultPage={1}
                onChange={handleChange}
                size='large'
                showFirstButton
                showLastButton
                color='primary'
              />
            </Box>
          </div>
      }
    </div>
  );
}