import React, { useState, useEffect } from 'react';
import { eventsService } from '../../services/Service';
import { Event } from '../../services/EventsService';
import { AlertMessage } from '../../common/dto';
import { formatError } from '../../common/formatError';
import { useFormik } from 'formik';
import * as yup from 'yup'
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Alert from '@mui/material/Alert';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import Fab from '@mui/material/Fab';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import SendIcon from '@mui/icons-material/Send';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';


export default function CreateEvent() {

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<AlertMessage>({ message: '', severity: undefined });

  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [imageUrl, setImageUrl] = useState<string | null>(null);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [message])

  useEffect(() => {
    if (selectedImage) {
      setImageUrl(URL.createObjectURL(selectedImage));
    }
  }, [selectedImage]);

  const transformImageToString = (image: File) => {
    const reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onload = function () {
      formik.setFieldValue('image', reader.result);
    };
  }

  const formik = useFormik({
    initialValues: {
      name: '',
      description: '',
      image: '',
      address: '',
      start_date: dayjs().add(1, 'day').format('DD/MM/YYYY HH:mm'),
      end_date: dayjs().add(2, 'day').format('DD/MM/YYYY HH:mm'),
      place: 10,
    },
    validationSchema: yup.object({
      name: yup
        .string()
        .trim()
        .required("Nom de l'évènement requis"),
      description: yup
        .string()
        .trim()
        .required('Description requise'),
      address: yup
        .string()
        .trim()
        .required('Adresse requise'),
      start_date: yup
        .string()
        .trim()
        .test(
          'Date valide',
          'Date non valide',
          (value) => dayjs(value, 'DD/MM/YYYY HH:mm').isValid()
        )
        .test(
          'Date valide',
          "Dates de début et de fin d'évènement non valides",
          (value, context) => dayjs(context.parent.end_date, 'DD/MM/YYYY HH:mm').diff(dayjs(value, 'DD/MM/YYYY HH:mm'), 'minutes') > 0
        )
        .required('Date de début requise'),
      end_date: yup
        .string()
        .trim()
        .test(
          'Date valide',
          'Date non valide',
          (value) => dayjs(value, 'DD/MM/YYYY HH:mm').isValid()
        )
        .test(
          'Date future',
          'Date déjà passée',
          (value) => dayjs(value, 'DD/MM/YYYY HH:mm').diff(dayjs(), 'minutes') > 0
        )
        .required('Date de fin requise'),
      place: yup
        .number()
        .required()
        .default(-1),
    }),
    onSubmit: (values, { resetForm }) => {
      setLoading(true);

      const event: Event = {
        id: 1,
        name: values.name,
        start_date: dayjs(values.start_date, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm').toString(),
        end_date: dayjs(values.end_date, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm').toString(),
        place: values.place,
        description: values.description,
        image: values.image,
        address: values.address,
        participants: [],
        participant_fullnames: [],
      }

      handleDemand(event, resetForm);
    },
  });

  const handleDemand = async (event: Event, resetForm: () => void) => {
    try {
      await eventsService.addEvent(event);
      setMessage({ message: 'Évènements créé.', severity: 'success' });
      resetForm();
      setSelectedImage(null);
    } catch (err: any) {
      setMessage({ message: formatError(err), severity: 'error' });
    } finally {
      setLoading(false);
    }
  }

  return (
    <Container>
      {message.message.length > 0 &&
        <Alert variant='filled' severity={message.severity} sx={{ mb: 6 }}>
          {message.message}
        </Alert>
      }

      <form onSubmit={formik.handleSubmit}>
        <Typography align='left' variant='h4' sx={{ mt: 4, mb: 6 }}>Créer un évènement</Typography>

        <Stack
          direction='column'
          justifyContent='flex-start'
          alignItems='flex-start'
          spacing={3}>
          <Typography align='left'>Nom de l&apos;évènement</Typography>

          <TextField
            label="nom"
            name='name'
            size='small'
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />

          <Typography align='left'>Description</Typography>

          <TextField
            fullWidth
            label='description'
            name='description'
            multiline
            minRows={3}
            maxRows={10}
            value={formik.values.description}
            onChange={formik.handleChange}
            error={formik.touched.description && Boolean(formik.errors.description)}
            helperText={formik.touched.description && formik.errors.description}
          />

          <Typography align='left'>Banière d&apos;évènement</Typography>

          <input
            type='file'
            accept='image/png, image/jpeg, image/jpg'
            multiple={false}
            id='select-image'
            style={{ display: 'none' }}
            onChange={(event) => {
              const files = event.target.files;
              if (files && files.length > 0) {
                transformImageToString(files[0]);
                setSelectedImage(files[0]);
              } else {
                formik.setFieldValue('image', '');
                setSelectedImage(null);
              }
            }}
          />
          <label htmlFor='select-image'>
            <Tooltip title="Upload une image de banière pour l'évènement, visible dans sa description">
              <Button variant='contained' component='span'>
                Upload Image
              </Button>
            </Tooltip>
          </label>
          {imageUrl && selectedImage && (
            <Box sx={{ mt: 4 }} textAlign='center'>
              <img src={imageUrl} alt={selectedImage.name} style={{ maxWidth: '100%' }} />
            </Box>
          )}

          <Typography align='left'>Adresse</Typography>

          <TextField
            fullWidth
            label='adresse'
            name='address'
            multiline
            minRows={1}
            maxRows={5}
            value={formik.values.address}
            onChange={formik.handleChange}
            error={formik.touched.address && Boolean(formik.errors.address)}
            helperText={formik.touched.address && formik.errors.address}
          />

          <Typography align='left'>Début de l&apos;évènement</Typography>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              inputFormat='DD/MM/YYYY HH:mm'
              value={dayjs(formik.values.start_date, 'DD/MM/YYYY HH:mm')}
              onChange={(value) => { formik.setFieldValue('start_date', dayjs(value).format('DD/MM/YYYY HH:mm'), true) }}
              renderInput={(params) =>
                <TextField
                  required
                  name='start_date'
                  label='début'
                  size='small'
                  error={formik.touched.start_date && Boolean(formik.errors.start_date)}
                  helperText={formik.errors.start_date?.toString()}
                  {...params} />}
            />
          </LocalizationProvider>

          <Typography align='left'>Fin de l&apos;évènement</Typography>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              inputFormat='DD/MM/YYYY HH:mm'
              value={dayjs(formik.values.end_date, 'DD/MM/YYYY HH:mm')}
              onChange={(value) => { formik.setFieldValue('end_date', dayjs(value).format('DD/MM/YYYY HH:mm'), true) }}
              renderInput={(params) =>
                <TextField
                  required
                  name='end_date'
                  label='fin'
                  size='small'
                  error={formik.touched.end_date && Boolean(formik.errors.end_date)}
                  helperText={formik.errors.end_date?.toString()}
                  {...params} />}
            />
          </LocalizationProvider>

          <Typography align='left'>Nombre de places</Typography>
        </Stack>

        <Stack
          direction='row'
          justifyContent='flex-start'
          alignItems='center'
          spacing={3}
          sx={{ mt: 1 }}
        >
          <TextField
            label='place'
            name='place'
            size='small'
            type='number'
            value={formik.values.place !== -1 ? formik.values.place : ''}
            onChange={(value) => formik.setFieldValue('place', value.target.value.replace(/\D/g, ''), true)}
            error={formik.touched.place && Boolean(formik.errors.place)}
            helperText={formik.touched.place && formik.errors.place}
          />

          <Box sx={{ flexGrow: 0.2 }} />

          <Fab size='small' color={formik.values.place !== -1 ? 'default' : 'primary'} aria-label='change image' onClick={() => { formik.setFieldValue('place', -1, true) }}>
            <AllInclusiveIcon />
          </Fab>
        </Stack>

        <Box
          display='flex'
          justifyContent='flex-start'
          alignItems='center'
          sx={{ mt: 6 }}>
          {
            loading ?
              <CircularProgress />
              :
              <Button variant='contained' type='submit' endIcon={<SendIcon />} disabled={loading}>
                Créer l&apos;évènement
              </Button>
          }
        </Box>
      </form >
    </Container >
  );
}