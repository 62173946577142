import React from 'react';
import { User } from '../../services/UsersService';
import dayjs from 'dayjs';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import EditIcon from '@mui/icons-material/Edit';
import Fab from '@mui/material/Fab';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';


interface Props {
  user: User;
  openUserEditorWindow: () => void;
}

export default function UserInfo(props: Props) {

  return (
    <Paper elevation={3} sx={{ my: 3, py: 2 }}>
      <Container>
        <Grid
          container
          direction='row'
          justifyContent='flex-start'
          alignItems='center'
          spacing={2}
        >
          <Grid item>
            <Typography variant='h6'>Information du profil</Typography>
          </Grid>

          <Grid item sx={{ flexGrow: 1 }} />

          <Grid item>
            <Fab size='small' color='primary' aria-label='change image' onClick={() => { props.openUserEditorWindow() }}>
              <EditIcon />
            </Fab>
          </Grid>

          <Box sx={{ width: '100%' }} />

          <Grid item xs={5} sm={4} md={3}>
            <Typography align='left' sx={{ color: 'text.secondary' }}>Nom complet</Typography>
          </Grid>

          <Grid item xs={7} sm={8} md={9}>
            <Typography align='left'>{props.user.first_name + ' ' + props.user.last_name}</Typography>
          </Grid>

          <Box sx={{ width: '100%' }} />

          <Grid item xs={5} sm={4} md={3}>
            <Typography align='left' sx={{ color: 'text.secondary' }}>Username</Typography>
          </Grid>

          <Grid item xs={7} sm={8} md={9}>
            <Typography align='left'>{props.user.username}</Typography>
          </Grid>

          <Box sx={{ width: '100%' }} />

          <Grid item xs={5} sm={4} md={3}>
            <Typography align='left' sx={{ color: 'text.secondary' }}>Date de naissance</Typography>
          </Grid>

          <Grid item xs={7} sm={8} md={9}>
            <Typography align='left'>{dayjs(props.user.date_of_birth).format('YYYY/MM/DD')}</Typography>
          </Grid>

          <Box sx={{ width: '100%' }} />

          <Grid item xs={5} sm={4} md={3}>
            <Typography align='left' sx={{ color: 'text.secondary' }}>Adresse e-mail</Typography>
          </Grid>

          <Grid item xs={7} sm={8} md={9}>
            <Typography align='left'>{props.user.email}</Typography>
          </Grid>

          <Box sx={{ width: '100%' }} />

          <Grid item xs={5} sm={4} md={3}>
            <Typography align='left' sx={{ color: 'text.secondary' }}>Numéro de téléphone</Typography>
          </Grid>

          <Grid item xs={7} sm={8} md={9}>
            <Typography align='left'>{props.user.phone}</Typography>
          </Grid>
        </Grid>
      </Container>
    </Paper>
  );
}