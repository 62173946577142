import React, { useState } from 'react';
import { expensesService, accountService } from '../../services/Service';
import { Expense, expenseType } from '../../services/ExpensesService';
import { AlertMessage } from '../../common/dto';
import { formatError } from '../../common/formatError';
import { FieldArray, Form, Formik, getIn } from 'formik';
import * as yup from 'yup';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import AddIcon from '@mui/icons-material/Add';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import Container from '@mui/material/Container';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Dialog from '@mui/material/Dialog';
import Divider from '@mui/material/Divider';
import Fab from '@mui/material/Fab';
import FormControl from '@mui/material/FormControl';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import InputLabel from '@mui/material/InputLabel';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import SendIcon from '@mui/icons-material/Send';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import UploadFileIcon from '@mui/icons-material/UploadFile';


export default function DeclareExpense() {

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<AlertMessage>({ message: '', severity: undefined });
  const [openFirst, setOpenFirst] = useState(false);
  const [openSecond, setOpenSecond] = useState(false);

  const openFirstHelp = () => {
    setOpenFirst(true);
  };

  const closeFirstHelp = () => {
    setOpenFirst(false);
  };

  const openSecondHelp = () => {
    setOpenSecond(true);
  };

  const closeSecondHelp = () => {
    setOpenSecond(false);
  };

  const validationSchema: any = yup.object().shape({
    description: yup
      .string()
      .trim()
      .required('Description requise'),
    bills: yup
      .array(),
    fees: yup.array().of(
      yup.object().shape({
        reason: yup
          .string()
          .trim()
          .required('Motif requis'),
        expense_date: yup
          .string()
          .trim()
          .test(
            'Date non valide',
            'Date non valide',
            (value) => dayjs(value, 'DD/MM/YYYY').isValid()
          )
          .required('Date requise'),
        expense_type: yup
          .string()
          .trim()
          .required('Type de dépense requis'),
        amount: yup
          .number()
          .required()
          .default(0)
          .test(
            'Montant positif',
            'Le montant doit être positif',
            (value) => value > 0
          )
          .test(
            'TTC above TVA',
            'Le montant TTC doit être supérieur au montant de la TVA',
            (value, context) => value > context.parent.tva
          ),
        tva: yup
          .number()
          .default(0)
          .test(
            'Montant positif',
            'Le montant doit être positif',
            (value) => value >= 0
          ),
      })
    )
  });

  const handleDemand = async (expense: Expense, bills: File[], resetForm: () => void) => {
    try {
      await expensesService.addExpense(expense, bills);
      setMessage({ message: 'Demande de remboursement envoyée.', severity: 'success' });
      resetForm();
    } catch (err: any) {
      setMessage({ message: formatError(err), severity: 'error' });
    } finally {
      setLoading(false);
    }
  }

  return (
    <div >
      {message.message.length > 0 &&
        <Alert variant='filled' severity={message.severity} sx={{ mb: 6 }}>
          {message.message}
        </Alert>
      }

      <Dialog onClose={closeFirstHelp} open={openFirst}>
        <Container sx={{ my: 4, width: { sm: 500, xs: '100%' } }}>
          <Typography align='left' variant='h4' sx={{ mb: 4, wordWrap: 'break-word' }}>Évènement</Typography>
          <Typography align='left' sx={{ mb: 2, wordWrap: 'break-word' }}>Décrivez dans quel contexte a eu lieu la dépense.</Typography>
          <Typography align='left' sx={{ mb: 1, color: 'text.secondary', wordWrap: 'break-word' }}>Exemples: </Typography>
          <Typography align='left' sx={{ pl: 2, color: 'text.secondary', wordWrap: 'break-word' }}>Forum d&apos;école, business trip</Typography>
        </Container>
      </Dialog>


      <Dialog onClose={closeSecondHelp} open={openSecond}>
        <Container sx={{ my: 4, width: { sm: 500, xs: '100%' } }}>
          <Typography align='left' variant='h4' sx={{ mb: 4, wordWrap: 'break-word' }}>Description de la dépense</Typography>
          <Typography align='left' sx={{ mb: 2, wordWrap: 'break-word' }}>Décrivez le détail de la dépense.</Typography>
          <Typography align='left' sx={{ mb: 1, color: 'text.secondary', wordWrap: 'break-word' }}>Exemples: </Typography>
          <Typography align='left' sx={{ pl: 2, color: 'text.secondary', wordWrap: 'break-word' }}>Billet de train aller</Typography>
          <Typography align='left' sx={{ pl: 2, color: 'text.secondary', wordWrap: 'break-word' }}>Billet de train retours</Typography>
          <Typography align='left' sx={{ pl: 2, color: 'text.secondary', wordWrap: 'break-word' }}>Nuit à l&apos;hôtel</Typography>
        </Container>
      </Dialog>

      <Formik
        initialValues={{
          description: '',
          bills: [],
          fees: [
            {
              id: Math.random(),
              reason: '',
              expense_type: expenseType[0],
              expense_date: dayjs().format('DD/MM/YYYY'),
              amount: 0,
              tva: 0,
            }
          ]
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => {
          setLoading(true);

          const expense: Expense = {
            id: 1,
            creator: 1,
            creator_fullname: accountService.getFullName(),
            description: values.description,
            asking_date: dayjs().format('YYYY-MM-DD').toString(),
            paiement_date: '',
            validation_date: '',
            reason: '',
            is_accepted: false,
            fees: [],
            bills: [],
            is_signed: false,
          }

          values.fees.forEach((fee) => expense.fees.push({
            id: 1,
            expense_date: dayjs(fee.expense_date, 'DD/MM/YYYY').format('YYYY-MM-DD').toString(),
            expense_type: fee.expense_type,
            description: fee.reason,
            amount: fee.amount,
            tva: fee.tva,
          }));

          handleDemand(expense, values.bills, resetForm);
        }}
      >
        {({ values, touched, errors, handleChange, handleBlur, setFieldValue }) => (

          <Form noValidate autoComplete='off'>
            <Box
              display='flex'
              justifyContent='flex-start'
              alignItems='center'
              sx={{ mt: 4 }}
            >
              <Typography align='left' variant='h5' sx={{ my: 2 }}>Évènement</Typography>
              <Fab size='small' color='default' aria-label='help' style={{ transform: 'scale(0.6)' }} onClick={() => openFirstHelp()} sx={{ ml: 2 }}>
                <HelpOutlineIcon />
              </Fab>
            </Box>

            <Box
              display='flex'
              justifyContent='flex-start'
              alignItems='center'
              sx={{ mt: 2 }}
            >
              <TextField
                fullWidth
                name='description'
                label="nom de l'évènement"
                size='small'
                multiline
                minRows={2}
                maxRows={10}
                value={values.description}
                onChange={handleChange}
                error={touched.description && Boolean(errors.description)}
                helperText={touched.description && errors.description}
              />
            </Box>

            <Divider sx={{ my: 4 }} />

            <Typography align='left' variant='h5'>Frais</Typography>

            <FieldArray name='fees'>
              {({ push, remove }) => (
                <div>
                  {values.fees.map((fee, index) => {
                    const reason = `fees[${index}].reason`;
                    const touchedReason = getIn(touched, reason);
                    const errorReason = getIn(errors, reason);

                    const expense_date = `fees[${index}].expense_date`;
                    const touchedDateExpense = getIn(touched, expense_date);
                    const errorDateExpense = getIn(errors, expense_date);

                    const expense_type = `fees[${index}].expense_type`;

                    const amount = `fees[${index}].amount`;
                    const touchedAmount = getIn(touched, amount);
                    const errorAmount = getIn(errors, amount);

                    const tva = `fees[${index}].tva`;
                    const touchedTva = getIn(touched, tva);
                    const errorTva = getIn(errors, tva);

                    return (
                      <div key={fee.id}>
                        {index > 0 &&
                          <Divider sx={{ mt: 6 }} />
                        }

                        {index > 0 &&
                          <Button variant='contained' type='button' onClick={() => remove(index)} endIcon={<CloseIcon />} disabled={loading} sx={{ my: 2 }}>
                            Retirer la dépense
                          </Button>
                        }

                        <Stack
                          direction='row'
                          justifyContent='flex-start'
                          alignItems='flex-start'
                          alignContent='flex-start'
                          spacing={{ xs: 0, sm: 10 }}
                          sx={{
                            flexWrap: 'wrap'
                          }}
                        >
                          <Box>
                            <Typography align='left' sx={{ my: 2 }}>Date</Typography>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DesktopDatePicker
                                inputFormat='DD/MM/YYYY'
                                value={dayjs(fee.expense_date, 'DD/MM/YYYY')}
                                onChange={(value) => {
                                  const newDate = dayjs(value).format('DD/MM/YYYY');
                                  setFieldValue(expense_date, newDate, true)
                                }}

                                renderInput={(params) =>
                                  <TextField
                                    required
                                    name={expense_date}
                                    label='expense_date'
                                    size='small'
                                    error={Boolean(touchedDateExpense && errorDateExpense)}
                                    helperText={errorDateExpense?.toString()}
                                    {...params} />}
                              />
                            </LocalizationProvider>
                          </Box>

                          <Box>
                            <Typography align='left' sx={{ my: 2 }}>Catégorie</Typography>
                            <FormControl>
                              <InputLabel>Type de dépense</InputLabel>
                              <Select
                                name={expense_type}
                                value={fee.expense_type || expenseType[0]}
                                label='Type de dépense'
                                size='small'
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                              >
                                {expenseType.map((item, index) => (
                                  <MenuItem value={item} key={index}>{item}</MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Box>
                        </Stack>

                        <Box
                          display='flex'
                          justifyContent='flex-start'
                          alignItems='center'
                          sx={{ mt: 2 }}
                        >
                          <Typography align='left' sx={{ my: 2 }}>Description de la dépense</Typography>
                          <Fab size='small' color='default' aria-label='help' style={{ transform: 'scale(0.6)' }} onClick={() => openSecondHelp()} sx={{ ml: 2 }}>
                            <HelpOutlineIcon />
                          </Fab>
                        </Box>
                        <Box
                          display='flex'
                          justifyContent='flex-start'
                          alignItems='center'
                          sx={{ mb: 2 }}
                        >
                          <TextField
                            fullWidth
                            label='description'
                            name={reason}
                            size='small'
                            multiline
                            minRows={2}
                            maxRows={10}
                            value={fee.reason}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={Boolean(touchedReason && errorReason)}
                            helperText={touchedReason && errorReason}
                          />
                        </Box>

                        <Stack
                          direction='row'
                          justifyContent='flex-start'
                          alignItems='flex-start'
                          spacing={10}
                        >

                          <Box>
                            <Typography align='left' sx={{ my: 2 }}>Montant TTC</Typography>
                            <TextField
                              label='montant'
                              name={amount}
                              size='small'
                              type='number'
                              value={fee.amount}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={Boolean(touchedAmount && errorAmount)}
                              helperText={touchedAmount && errorAmount}
                            />
                          </Box>

                          <Box>
                            <Typography align='left' sx={{ my: 2 }}>Montant TVA</Typography>
                            <TextField
                              label='tva'
                              name={tva}
                              size='small'
                              type='number'
                              value={fee.tva}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={Boolean(touchedTva && errorTva)}
                              helperText={touchedTva && errorTva}
                            />
                          </Box>
                        </Stack>
                      </div>
                    );
                  })}

                  <Button
                    type='button'
                    variant='contained'
                    onClick={() =>
                      push({ id: Math.random(), reason: '', expense_type: expenseType[0], expense_date: dayjs().format('DD/MM/YYYY'), amount: 0, tva: 0, bills: [], })
                    }
                    endIcon={<AddIcon />}
                    sx={{ my: 6 }}
                  >
                    Ajouter une dépense
                  </Button>
                </div>
              )}
            </FieldArray>

            <Divider sx={{ my: 2 }} />

            <Typography align='left' variant='h5' sx={{ my: 2 }}>Justificatif</Typography>

            <Box
              display='flex'
              justifyContent='flex-start'
              alignItems='flex-start'
            >
              <Button
                sx={{ my: 2 }}
                variant='contained'
                component='label'
                endIcon={<UploadFileIcon />}>
                Sélectionnez vos justificatifs
                <input
                  name='bills'
                  type='file'
                  hidden
                  onChange={(event) => {
                    const files = event.target.files;
                    if (!files) {
                      setFieldValue('bills', []);
                    } else {
                      setFieldValue('bills', Array.from(files));
                    }
                  }}
                  multiple
                />
              </Button>
            </Box>

            <Stack
              direction='column'
              justifyContent='flex-start'
              alignItems='flex-start'
              spacing={3}
              sx={{ mt: 2 }}
            >
              {values.bills.map((value: File, index) => (
                <Typography key={index} variant='body2' align='left' sx={{ color: 'text.secondary' }} >{value.name}</Typography>
              ))}
            </Stack>

            <Box sx={{ mt: 6 }}>
              {
                loading ?
                  <CircularProgress />
                  :
                  <Button variant='contained' type='submit' endIcon={<SendIcon />} disabled={loading}>
                    Envoyer la fiche de frais
                  </Button>
              }
            </Box>
          </Form>
        )}
      </Formik>
    </div >
  );
}