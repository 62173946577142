import React, { useState } from 'react';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

interface Props {
  message: string;
  disabled: boolean;
  action: () => void;
}

export default function DeleteConfirm(props: Props) {

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleConfirm = () => {
    setOpen(false);
    props.action();
  };

  return (
    <div>
      <Button variant='contained' endIcon={<DeleteIcon />} disabled={props.disabled} onClick={handleClickOpen}>
        Supprimer
      </Button>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {props.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Annuler</Button>
          <Button type='submit' color='primary' variant='outlined' endIcon={<DeleteIcon />} onClick={handleConfirm}>Confirmer</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}