import React, { useEffect } from 'react';
import { holidaysService } from '../../services/Service';
import ViewSelector from '../../common/ViewSelector';
import { Views } from '../../common/dto';
import ListView from './ListView';
import TableView from './TableView';


export default function ViewHolidays() {

  useEffect(() => {
    holidaysService.fetchUserHolidays();
    holidaysService.fetchFrenchHolidays();
  }, []);

  const views: Views = {
    listView: <ListView />,
    tableView: <TableView />,
  }

  return (
    <ViewSelector title='Mes absences' views={views} />
  );
}