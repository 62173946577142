import { observable } from 'micro-observables';
import { AccountService } from './AccountService';
import axios from 'axios';


export interface Profil {
  id: number;
  first_name: string;
  last_name: string;
  photo: string;
  email: string;
  phone: string;
  arrival: string;
  statut: 'available' | 'vacation' | 'birthday';
  active: boolean;
}

export class OrganizationalService {
  private _profils = observable<Profil[]>([]);
  readonly profils = this._profils.readOnly();

  constructor(private accountService: AccountService) { }

  async fetchProfils() {
    const token = this.accountService.token.get();
    const config = {
      'headers': {
        'Authorization': `Bearer ${token}`
      }
    }
    const res = await axios.get('/api/profils/', config);

    const profils = res.data;
    profils.sort((a: Profil, b: Profil) => a.first_name.toLowerCase() + ' ' + a.last_name.toLowerCase() > b.first_name.toLowerCase() + ' ' + b.last_name.toLowerCase() ? 1 : -1);
    this._profils.set(profils);
  }

  async setProfil(profil: Profil[]) {
    this._profils.set(profil)
  }

  getUsernameList() {
    return this.profils.select(arr => arr.map((profil: Profil) => profil.first_name + ' ' + profil.last_name));
  }
}