import React, { useState } from 'react';
import { holidaysService } from '../../services/Service';
import { Holiday } from '../../services/HolidaysService';
import { AlertMessage } from '../../common/dto';
import { formatError } from '../../common/formatError';
import DeleteConfirm from '../../common/DeleteConfirm';
import { useFormik } from 'formik';
import * as yup from 'yup'
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import EditIcon from '@mui/icons-material/Edit';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';


interface Props {
  holiday: Holiday;
  setNotification: (msg: AlertMessage) => void;
  close: () => void;
}

export default function HolidaysFormular(props: Props) {

  const [holidayCache] = useState<Holiday>(props.holiday);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<AlertMessage>({ message: '', severity: undefined });

  const formik = useFormik({
    initialValues: {
      start_date: dayjs(holidayCache.start_date, 'YYYY-MM-DD').format('DD/MM/YYYY').toString(),
      end_date: dayjs(holidayCache.end_date, 'YYYY-MM-DD').format('DD/MM/YYYY').toString(),
      begin_at_midday: holidayCache.begin_at_midday,
      end_at_midday: holidayCache.end_at_midday,
      deleted: false,
    },
    validationSchema: yup.object({
      start_date: yup
        .string()
        .trim()
        .test(
          'Date valide',
          'Date non valide',
          (value) => dayjs(value, 'DD/MM/YYYY').isValid()
        )
        .test(
          'RTT valide',
          'Vous ne pouvez pas poser 2 RTT consécutifs',
          (value, context) => context.parent.holidaysType !== 'RTT' || (context.parent.holidaysType === 'RTT' && holidaysService.isRTTValid(value))
        )
        .test(
          'Date valide',
          "Date de début ne peut pas être antérieure au 2 années précédentes",
          (value) => dayjs(value, 'DD/MM/YYYY').isAfter(dayjs().subtract(2, 'year'))
        )
        .test(
          'Date valide',
          "Date de début ne peut pas être postérieure à 2 années futures",
          (value) => dayjs(value, 'DD/MM/YYYY').isBefore(dayjs().add(2, 'year'))
        )
        .required("Date de début d'absence' requise"),
      end_date: yup
        .string()
        .trim()
        .test(
          'Date valide',
          'Date non valide',
          (value) => dayjs(value, 'DD/MM/YYYY').isValid()
        )
        .test(
          'RTT valide',
          'Vous ne pouvez pas poser 2 RTT consécutifs',
          (value, context) => context.parent.holidaysType !== 'RTT' || (context.parent.holidaysType === 'RTT' && holidaysService.isRTTValid(value))
        )
        .test(
          'Date valide',
          "Date de début ne peut pas être antérieure au 2 années précédentes",
          (value) => dayjs(value, 'DD/MM/YYYY').isAfter(dayjs().subtract(2, 'year'))
        )
        .test(
          'Date valide',
          "Date de début ne peut pas être postérieure à 2 années futures",
          (value) => dayjs(value, 'DD/MM/YYYY').isBefore(dayjs().add(2, 'year'))
        )
        .required("Date de fin d'absence requise"),
      begin_at_midday: yup
        .boolean()
        .test(
          'Date valide',
          'Date de début et de fin ne peuvent pas être le même jour quand vous commencez l apres-midi et terminez le matin',
          (value, context) => (value !== true || context.parent.end_at_midday !== true || context.parent.start_date !== context.parent.end_date)
        ),
      end_at_midday: yup
        .boolean(),
      deleted: yup
        .boolean()
    }),
    onSubmit: (values) => {
      setLoading(true);

      if (values.deleted) {
        handleDelete();
      } else {
        const holiday: Holiday = {
          id: props.holiday.id,
          holiday_type: props.holiday.holiday_type,
          creator: props.holiday.creator,
          creator_fullname: props.holiday.creator_fullname,
          start_date: dayjs(values.start_date, 'DD/MM/YYYY').format('YYYY-MM-DD').toString(),
          end_date: dayjs(values.end_date, 'DD/MM/YYYY').format('YYYY-MM-DD').toString(),
          begin_at_midday: values.begin_at_midday,
          end_at_midday: values.end_at_midday,
          duration: holidaysService.getHolidayDuration(values.start_date, values.end_date, values.begin_at_midday, values.end_at_midday),
          asking_date: dayjs().format('YYYY-MM-DD').toString(),
          validation_date: props.holiday.validation_date,
          reason: props.holiday.reason,
          is_accepted: props.holiday.is_accepted,
        }
        handleUpdate(holiday);
      }
    },
  });

  const hasErrors = Object.keys(formik.errors).length > 0;

  const handleDelete = async () => {
    try {
      await holidaysService.removeHoliday(props.holiday);
      props.setNotification({ message: "La demande d'absence a bien été annulée.", severity: 'success' });
      props.close();
    } catch (err: any) {
      setMessage({ message: formatError(err), severity: 'error' });
    } finally {
      setLoading(false);
    }
  }

  const handleUpdate = async (holiday: Holiday) => {
    try {
      await holidaysService.updateHoliday(holiday);
      setMessage({ message: "La demande d'absence a bien été modifiée.", severity: 'success' });
    } catch (err: any) {
      setMessage({ message: formatError(err), severity: 'error' });
    } finally {
      setLoading(false);
    }
  }

  return (
    <Container sx={{ my: 4, width: 'auto' }}>
      {message.message.length > 0 &&
        <Alert variant='filled' severity={message.severity} sx={{ mb: 6 }}>
          {message.message}
        </Alert>
      }

      <form onSubmit={formik.handleSubmit}>
        <Typography align='left' variant='h4' sx={{ mb: 4, wordBreak: 'break-word' }}>Modifier une demande d&apos;absence</Typography>

        <Typography align='left' variant='h5' sx={{ mt: 2, mb: 2, wordBreak: 'break-word' }}>{props.holiday.holiday_type}</Typography>

        <Typography align='left' sx={{ mt: 4, mb: 2, wordBreak: 'break-word' }}>Début d&apos;absence</Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            label='Premier jour'
            inputFormat='DD/MM/YYYY'
            value={dayjs(formik.values.start_date, 'DD/MM/YYYY')}
            onChange={(value) => {
              const startDate = dayjs(value, 'DD/MM/YYYY').startOf('day');
              const endDate = dayjs(formik.values.end_date, 'DD/MM/YYYY').startOf('day');
              if (endDate.isBefore(startDate))
                formik.setFieldValue('end_date', dayjs(value, 'DD/MM/YYYY').format('DD/MM/YYYY'), true);
              formik.setFieldValue('start_date', dayjs(value, 'DD/MM/YYYY').format('DD/MM/YYYY'), true);
            }}
            renderInput={(params) =>
              <TextField
                name='start_date'
                label='start_date'
                size='small'
                error={formik.touched.start_date && Boolean(formik.errors.start_date)}
                helperText={formik.errors.start_date?.toString()}
                {...params} />}
          />
        </LocalizationProvider>

        <Typography align='left' sx={{ mt: 4, mb: 2, wordBreak: 'break-word' }}>Fin d&apos;absence</Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            label='Dernier jour'
            inputFormat='DD/MM/YYYY'
            value={dayjs(formik.values.end_date, 'DD/MM/YYYY')}
            onChange={(value) => {
              const endDate = dayjs(value, 'DD/MM/YYYY').startOf('day');
              const startDate = dayjs(formik.values.start_date, 'DD/MM/YYYY').startOf('day');
              if (startDate.isAfter(endDate))
                formik.setFieldValue('end_date', dayjs(formik.values.start_date, 'DD/MM/YYYY').format('DD/MM/YYYY'), true);
              else
                formik.setFieldValue('end_date', dayjs(value, 'DD/MM/YYYY').format('DD/MM/YYYY'), true);
            }}
            renderInput={(params) =>
              <TextField
                name='end_date'
                label='end_date'
                size='small'
                error={formik.touched.end_date && Boolean(formik.errors.end_date)}
                helperText={formik.errors.end_date?.toString()}
                {...params} />}
          />
        </LocalizationProvider>

        <Typography align='left' sx={{ mt: 4, mb: 2, wordBreak: 'break-word' }}>Votre absence démarre l&apos;après-midi de votre premier jour ?</Typography>
        <Switch
          name='begin_at_midday'
          checked={formik.values.begin_at_midday}
          onChange={formik.handleChange}
        />

        <Typography align='left' sx={{ mt: 4, mb: 2, wordBreak: 'break-word' }}>Votre absence termine en fin de matinée de votre dernier jour ?</Typography>
        <Switch
          name='end_at_midday'
          checked={formik.values.end_at_midday}
          onChange={formik.handleChange}
        />

        {
          loading ?
            <Box
              display='flex'
              justifyContent='center'
              alignItems='center'
              sx={{ mt: 4 }}
            >
              <CircularProgress />
            </Box>
            :
            <Stack
              direction='row'
              justifyContent='space-between'
              alignItems='center'
              sx={{ mt: 4, flexWrap: 'wrap' }}
            >
              <Button variant='contained' type='submit' endIcon={<EditIcon />} disabled={loading || hasErrors}>
                Modifier
              </Button>
              <DeleteConfirm message={"Êtes vous sur de vouloir supprimer la demande d'absence?"} disabled={loading} action={() => { formik.setFieldValue(`deleted`, true); formik.submitForm() }} />
            </Stack>
        }
      </form>
    </Container >
  );
}