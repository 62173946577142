import React, { useState } from 'react';
import PasswordEditor from './PasswordEditor'
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import EditIcon from '@mui/icons-material/Edit';
import Fab from '@mui/material/Fab';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';


export default function PasswordInfo() {

  const [open, setOpen] = useState(false);

  const openEditor = () => {
    setOpen(true);
  };

  const closeEditor = () => {
    setOpen(false);
  };

  return (
    <Paper elevation={3} sx={{ my: 3, py: 2 }}>

      <Dialog onClose={closeEditor} open={open}>
        <PasswordEditor />
      </Dialog>

      <Container>
        <Grid
          container
          direction='row'
          justifyContent='flex-start'
          alignItems='center'
          spacing={2}
        >
          <Grid item>
            <Typography variant='h5'>Changer mon password</Typography>
          </Grid>

          <Grid item sx={{ flexGrow: 1 }} />

          <Grid item>
            <Fab size='small' color='primary' aria-label='change image' onClick={openEditor}>
              <EditIcon />
            </Fab>
          </Grid>
        </Grid>
      </Container>
    </Paper>
  );
}