/**
 * Formats an error object into a human-readable string.
 * @param err - The error object to format.
 * @returns A formatted error message.
 */
export const formatError = (err: any) => {
  const errors: string[] = [];
  errors.push(err.message + '.');

  if (err.response?.data) {
    for (const key in err.response.data) {
      const value: string = err.response.data[key];
      errors.push(value);
    }
  }

  return 'Something went wrong: \n' + errors.join('\n');
}