import React, { useState } from 'react';
import { accountService } from '../../services/Service';
import { Account } from '../../services/AccountService';
import { AlertMessage } from '../../common/dto';
import { formatError } from '../../common/formatError';
import { useFormik } from 'formik';
import * as yup from 'yup'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import EditIcon from '@mui/icons-material/Edit';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';


interface Props {
  account: Account;
}

export default function ProfileEditor(props: Props) {

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<AlertMessage>({ message: '', severity: undefined });

  const formik = useFormik({
    initialValues: {
      phone: props.account.phone
    },
    validationSchema: yup.object({
      phone: yup
        .string()
        .trim()
        .matches(/^\+?1?\d{9,15}$/, "Phone number must be entered in the format: '+999999999'. Up to 15 digits allowed."),
    }),
    onSubmit: (values) => {
      setLoading(true);
      handleProfileUpdate(values.phone);
    },
  });

  const handleProfileUpdate = async (new_phone: string) => {
    try {
      await accountService.updatePhone(new_phone);
      setMessage({ message: 'Informations personnelles modifiées.', severity: 'success' });
    } catch (err: any) {
      setMessage({ message: formatError(err), severity: 'error' });
    } finally {
      setLoading(false);
    }
  }

  return (
    <Container sx={{ my: 4, width: 'auto' }}>

      {message.message.length > 0 &&
        <Alert variant='filled' severity={message.severity} sx={{ mb: 6 }}>
          {message.message}
        </Alert>
      }

      <form onSubmit={formik.handleSubmit}>
        <Typography align='left' variant='h4' sx={{ mb: 6 }}>Information du profil</Typography>

        <Typography align='left' sx={{ mt: 4, mb: 2 }}>Nom</Typography>
        <TextField
          disabled
          name='last_name'
          label='nom'
          size='small'
          value={props.account.last_name}
        />

        <Typography align='left' sx={{ mt: 4, mb: 2 }}>Prénom</Typography>
        <TextField
          disabled
          name='first_name'
          label='prénom'
          size='small'
          value={props.account.first_name}
        />

        <Typography align='left' sx={{ mt: 4, mb: 2 }}>Date de naissance</Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            disabled
            onChange={() => {}}
            inputFormat='DD/MM/YYYY'
            value={props.account.date_of_birth}
            renderInput={(params) =>
              <TextField
                name='date_of_birth'
                label='date de naissance'
                size='small'
                {...params} />}
          />
        </LocalizationProvider>

        <Typography align='left' sx={{ mt: 4, mb: 2 }}>Adresse e-mail</Typography>
        <TextField
          disabled
          name='email'
          label='email'
          size='small'
          value={props.account.email}
        />

        <Typography align='left' sx={{ mt: 4, mb: 2 }}>Numéro de téléphone</Typography>
        <TextField
          name='phone'
          label='numéro'
          size='small'
          value={formik.values.phone}
          onChange={formik.handleChange}
          error={formik.touched.phone && Boolean(formik.errors.phone)}
          helperText={formik.touched.phone && formik.errors.phone}
        />

        <Box sx={{ mt: 4 }}>
          {
            loading ?
              <CircularProgress />
              :
              <Button variant='contained' type='submit' endIcon={<EditIcon />} disabled={loading}>
                Edit
              </Button>
          }
        </Box>
      </form>
    </Container>
  );
}