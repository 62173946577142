import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Views } from './dto';
import Container from '@mui/material/Container';
import ListIcon from '@mui/icons-material/List';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import Stack from '@mui/material/Stack';
import TableViewIcon from '@mui/icons-material/TableView';
import Typography from '@mui/material/Typography';


interface Props {
  title: string;
  views: Views;
}

export default function ViewSelector(props: Props) {

  const [view, setView] = useState(0);

  return (
    <Container>
      {
        !isMobile ?
            <Stack
              direction='row'
              justifyContent='space-between'
              alignItems='center'
              sx={{ mt: 2, mb: 4 }}
            >
              <Typography align='left' variant='h4' sx={{ my: 2 }}>{props.title}</Typography>
              <SpeedDial
                ariaLabel='View selection'
                icon={<SpeedDialIcon />}
                direction='right'
                sx={{ my: 2 }}
              >
                <SpeedDialAction
                  key='List view'
                  icon={<ListIcon />}
                  tooltipTitle='List view'
                  onClick={() => { setView(0) }}
                />

                <SpeedDialAction
                  key='Table view'
                  icon={<TableViewIcon />}
                  tooltipTitle='Table view'
                  onClick={() => { setView(1) }}
                />
              </SpeedDial>
            </Stack>
          :
          <Typography align='left' variant='h4' sx={{ my: 2 }}>{props.title}</Typography>
      }

      {
        {
          0:
            props.views.listView,
          1:
            props.views.tableView
        }[view]
      }

    </Container>
  );
}