import React, { useState } from 'react';
import { useObservable } from 'micro-observables';
import { accountService } from '../../services/Service';
import SignatureEditor from './SignatureEditor'
import SignatureUpload from './SignatureUpload';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import EditIcon from '@mui/icons-material/Edit';
import Fab from '@mui/material/Fab';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import UploadIcon from '@mui/icons-material/Upload';


export default function SignatureInfo() {

  const account = useObservable(accountService.account);
  const [openUpload, setOpenUpload] = useState(false);
  const [openEditor, setOpenEditor] = useState(false);

  const openUploadWindow = () => {
    setOpenUpload(true);
  };

  const closeUploadWindow = () => {
    setOpenUpload(false);
  };

  const openEditorWindow = () => {
    setOpenEditor(true);
  };

  const closeEditorWindow = () => {
    setOpenEditor(false);
  };

  if (!account) {
    return <div />
  }

  return (
    <Paper elevation={3} sx={{ my: 3, py: 2 }}>

      <Dialog onClose={closeUploadWindow} open={openUpload}>
        <SignatureUpload />
      </Dialog>

      <Dialog onClose={closeEditorWindow} open={openEditor}>
        <SignatureEditor />
      </Dialog>

      <Container>
        <Grid
          container
          direction='row'
          justifyContent='flex-start'
          alignItems='center'
          spacing={2}
        >
          <Grid item>
            <Typography variant='h5'>Signature</Typography>
          </Grid>

          <Grid item sx={{ flexGrow: 1 }} />

          <Grid item>
            <Tooltip title='Upload une image de ma signature'>
              <Fab size='small' color='primary' onClick={openUploadWindow}>
                <UploadIcon />
              </Fab>
            </Tooltip>
          </Grid>

          <Grid item>
            <Tooltip title='Dessiner ma signature'>
              <Fab size='small' color='primary' onClick={openEditorWindow}>
                <EditIcon />
              </Fab>
            </Tooltip>
          </Grid>

          <Box sx={{ width: '100%' }} />

          <Grid item>
            {account.signature ?
              <Box
                component='img'
                sx={{ width: 200, height: 75 }}
                alt='signature'
                src={account.signature}
              />
              :
              <Typography align='left' sx={{ color: 'text.secondary' }}>Aucune signature enregistrée</Typography>
            }
          </Grid>
        </Grid>
      </Container>
    </Paper>
  );
}