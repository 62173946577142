import React, { useState } from 'react';
import { accountService } from '../../services/Service';
import { Mission } from '../../services/AccountService';
import { AlertMessage } from '../../common/dto';
import { formatError } from '../../common/formatError';
import { useFormik } from 'formik';
import * as yup from 'yup'
import dayjs from 'dayjs';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import EditIcon from '@mui/icons-material/Edit';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';


interface Props {
  mission: Mission;
}

export default function MissionEditor(props: Props) {

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<AlertMessage>({ message: '', severity: undefined });

  const formik = useFormik({
    initialValues: {
      client: props.mission.client,
      service: props.mission.service,
      leader_name: props.mission.leader_name,
      leader_email: props.mission.leader_email,
      description: props.mission.description,
    },
    validationSchema: yup.object({
      client: yup
        .string()
        .trim(),
      service: yup
        .string()
        .trim(),
      leader_name: yup
        .string()
        .trim(),
      leader_email: yup
        .string()
        .trim(),
      description: yup
        .string()
        .trim(),
    }),
    onSubmit: (values) => {
      setLoading(true);

      const mission: Mission = {
        id: 1,
        client: values.client,
        service: values.service,
        leader_name: values.leader_name,
        leader_email: values.leader_email,
        description: values.description,
        modification_date: dayjs().format('YYYY-MM-DD').toString(),
      };
      handleDemand(mission);
    },
  });

  const handleDemand = async (mission: Mission) => {
    try {
      await accountService.updateMission(mission);
      setMessage({ message: 'Informations sur la mission modifiées', severity: 'success' });
    } catch (err: any) {
      setMessage({ message: formatError(err), severity: 'error' });
    } finally {
      setLoading(false);
    }
  }

  return (
    <Container sx={{ my: 4, width: 'auto' }} >

      {message.message.length > 0 &&
        <Alert variant='filled' severity={message.severity} sx={{ mb: 6 }}>
          {message.message}
        </Alert>
      }

      <form onSubmit={formik.handleSubmit}>
        <Typography align='left' variant='h4' sx={{ mb: 6 }}>Information sur la mission</Typography>

        <Typography align='left' sx={{ mt: 4, mb: 2 }}>Client</Typography>
        <TextField
          label='client'
          name='client'
          size='small'
          value={formik.values.client}
          onChange={formik.handleChange}
          error={formik.touched.client && Boolean(formik.errors.client)}
          helperText={formik.touched.client && formik.errors.client}
        />

        <Typography align='left' sx={{ mt: 4, mb: 2 }}>Service</Typography>
        <TextField
          label='service'
          name='service'
          size='small'
          value={formik.values.service}
          onChange={formik.handleChange}
          error={formik.touched.service && Boolean(formik.errors.service)}
          helperText={formik.touched.service && formik.errors.service}
        />

        <Typography align='left' sx={{ mt: 4, mb: 2 }}>Responsable</Typography>
        <TextField
          label='responsable'
          name='leader_name'
          size='small'
          value={formik.values.leader_name}
          onChange={formik.handleChange}
          error={formik.touched.leader_name && Boolean(formik.errors.leader_name)}
          helperText={formik.touched.leader_name && formik.errors.leader_name}
        />

        <Typography align='left' sx={{ mt: 4, mb: 2 }}>E-mail du responsable</Typography>
        <TextField
          label='email'
          name='leader_email'
          size='small'
          value={formik.values.leader_email}
          onChange={formik.handleChange}
          error={formik.touched.leader_email && Boolean(formik.errors.leader_email)}
          helperText={formik.touched.leader_email && formik.errors.leader_email}
        />

        <Typography align='left' sx={{ mt: 4, mb: 2 }}>Mission</Typography>
        <TextField
          fullWidth
          label='description'
          name='description'
          size='small'
          multiline
          minRows={2}
          maxRows={10}
          value={formik.values.description}
          onChange={formik.handleChange}
          error={formik.touched.description && Boolean(formik.errors.description)}
          helperText={formik.touched.description && formik.errors.description}
        />

        <Box sx={{ mt: 4 }}>
          {
            loading ?
              <CircularProgress />
              :
              <Button variant='contained' type='submit' endIcon={<EditIcon />} disabled={loading}>
                Edit
              </Button>
          }
        </Box>
      </form>
    </Container>
  );
}