import React, { useEffect } from 'react';
import { expensesService } from '../../services/Service';
import { MyTab } from '../../common/dto';
import TabSelector from '../../common/TabSelector';
import DemandInProgress from './DemandInProgress';
import ExpenseHistory from './ExpenseHistory';


export default function EventManagement() {

  const tabs: MyTab[] = [
    {label: 'Demande de remboursement', content: <DemandInProgress />},
    {label: 'Historique des demandes', content: <ExpenseHistory />},
  ]

  useEffect(() => {
    expensesService.fetchExpenses();
  }, []);

  return (
    <TabSelector tabs={tabs} />
  );
}