import React, { useState } from 'react';
import { usersService } from '../../services/Service';
import { User } from '../../services/UsersService';
import { websiteStructure } from '../../common/WebsiteStructure';
import { Group, Page, AlertMessage } from '../../common/dto';
import { CustomSwitch } from '../../common/CustomSwitch';
import { formatError } from '../../common/formatError';
import { useFormik } from 'formik';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import EditIcon from '@mui/icons-material/Edit';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { green } from '@mui/material/colors';


interface Props {
  user: User;
}

export default function AuthorizationsEditor(props: Props) {

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<AlertMessage>({ message: '', severity: undefined });

  const formik = useFormik({
    initialValues: {
      authorizations: props.user.authorizations,
    },
    onSubmit: (values) => {
      setLoading(true);
      handleAuthorizationUpdate(values.authorizations);
    },
  });

  const handleAuthorizationUpdate = async (new_authorizations: string[]) => {
    try {
      await usersService.updateAuthorization(props.user.id, new_authorizations);
      setMessage({ message: 'Autorisations modifiées.', severity: 'success' });
    } catch (err: any) {
      setMessage({ message: formatError(err), severity: 'error' });
    } finally {
      setLoading(false);
    }
  }

  return (
    <Container sx={{ my: 4, width: 'auto' }} >

      {message.message.length > 0 &&
        <Alert variant='filled' severity={message.severity} sx={{ mb: 6 }}>
          {message.message}
        </Alert>
      }

      <form onSubmit={formik.handleSubmit}>
        <Typography align='left' variant='h4' sx={{ mb: 6 }}>Modifier les droits d&apos;administration</Typography>
        <Stack
          direction='column'
          justifyContent='flex-start'
          alignItems='flex-start'
          spacing={1}
        >

          {websiteStructure.groups.map((group: Group) => (
            group.pages.map((page: Page) => (
              page.requiredAuthorization &&
              <FormGroup key={page.name}>
                <FormControlLabel
                  control={<CustomSwitch switchcolor={green[600]} sx={{ m: 1 }} checked={formik.values.authorizations.includes(page.requiredAuthorization)} onChange={() => {
                    page.requiredAuthorization && formik.values.authorizations.includes(page.requiredAuthorization) ?
                      formik.setFieldValue('authorizations', formik.values.authorizations.filter(val => val !== page.requiredAuthorization))
                      :
                      formik.setFieldValue('authorizations', [...formik.values.authorizations, page.requiredAuthorization])
                  }}
                  />}
                  label={page.name}
                />
              </FormGroup>
            ))
          ))
          }

          {websiteStructure.otherPages.map((page: Page) => (
            page.requiredAuthorization &&
            <FormGroup key={page.name}>
              <FormControlLabel
                control={<CustomSwitch switchcolor={green[600]} sx={{ mx: 1 }} checked={formik.values.authorizations.includes(page.requiredAuthorization)} onChange={() => {
                  page.requiredAuthorization && formik.values.authorizations.includes(page.requiredAuthorization) ?
                    formik.setFieldValue('authorizations', formik.values.authorizations.filter(val => val !== page.requiredAuthorization))
                    :
                    formik.setFieldValue('authorizations', [...formik.values.authorizations, page.requiredAuthorization])
                }}
                />}
                label={page.name}
              />
            </FormGroup>
          ))
          }
        </Stack>

        <Box sx={{ mt: 4 }}>
          {
            loading ?
              <CircularProgress />
              :
              <Button variant='contained' type='submit' endIcon={<EditIcon />} disabled={loading}>
                Edit
              </Button>
          }
        </Box>
      </form>
    </Container >
  );
}