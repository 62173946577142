import React, { useState, useEffect} from 'react';
import { useObservable, useMemoizedObservable } from 'micro-observables';
import { eventsService } from '../../services/Service';
import { Event } from '../../services/EventsService';
import { AlertMessage } from '../../common/dto';
import EventFormular from './EventFormular';
import dayjs from 'dayjs';
import Alert from '@mui/material/Alert';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import Box from '@mui/material/Box';
import CheckIcon from '@mui/icons-material/Check';
import Collapse from '@mui/material/Collapse';
import Dialog from '@mui/material/Dialog';
import Divider from '@mui/material/Divider';
import EditIcon from '@mui/icons-material/Edit';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Fab from '@mui/material/Fab';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Pagination from '@mui/material/Pagination';
import RefreshIcon from '@mui/icons-material/Refresh';
import Typography from '@mui/material/Typography';
import { css } from '@emotion/css';

function EventDetail({ label, value }: { readonly label: string, readonly value: any }) {
  return (
    <Typography align='left' sx={{ ml: 9, my: 1, color: 'text.secondary' }}>
      {label + value}
    </Typography>
  );
}

export default function ListView() {

  const futureEvents = useMemoizedObservable(() => eventsService.getFutureEvents(), []);
  const currentEvents = useObservable(eventsService.getCurrentEvents());
  const pastEvents = useObservable(eventsService.getPastEvents());

  const [open, setOpen] = useState<number[]>([]);
  const itemsPerPage = 50;
  const [page, setPage] = useState(1);
  const [noOfPages] = useState(1 | Math.ceil(pastEvents.length / itemsPerPage));
  const [openEditor, setOpenEditor] = useState<number[]>([]);
  const [message, setMessage] = useState<AlertMessage>({ message: '', severity: undefined });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [message])

  const handleChange = (event: React.ChangeEvent<any>, value: number) => {
    setPage(value);
  };

  const handleClick = (index: number) => {
    setOpen(prevState => prevState.includes(index) ? prevState.filter(val => val !== index) : [...prevState, index]);
  };

  const openEditorWindow = (index: number) => {
    setOpenEditor(prevState => prevState.includes(index) ? prevState.filter(val => val !== index) : [...prevState, index]);
  };

  const closeEditorWindow = (index: number) => {
    setOpenEditor(prevState => prevState.includes(index) ? prevState.filter(val => val !== index) : [...prevState, index]);
  };

  return (
    <div>
      {message.message.length > 0 &&
        <Alert variant='filled' severity={message.severity} sx={{ my: 4 }}>
          {message.message}
        </Alert>
      }

      {
        futureEvents.length == 0 ?
          <Typography align='left' variant='h5' sx={{ color: 'text.secondary', my: 4 }}>Aucun évènement programmé.</Typography>
          :
          <List
            sx={{ width: '100%', mb: 4 }}
            component='nav'
            subheader={
              <ListSubheader component='div' className={css`text-align: start;`}>
                Évènements à venir
              </ListSubheader>
            }
          >
            {futureEvents.map((event: Event, index: number) => (
              <div key={index}>
                <Dialog onClose={() => { closeEditorWindow(index) }} open={openEditor.includes(index) || false}>
                  <EventFormular event={event} setNotification={setMessage} close={() => closeEditorWindow(index)} />
                </Dialog>

                <ListItemButton onClick={() => handleClick(index)}>
                  <ListItemIcon>
                    <RefreshIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={event.name}
                    secondary={
                        <Typography component='span' variant='body2' >
                          {dayjs(event.start_date).format('DD/MM/YYYY HH:mm')}
                          <ArrowRightAltIcon style={{ verticalAlign: 'middle' }} sx={{ mx: 1 }} />
                          {dayjs(event.end_date).format('DD/MM/YYYY HH:mm')}
                        </Typography>
                    } />
                  {open.includes(index) ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={open.includes(index)} timeout='auto' unmountOnExit>
                  <EventDetail label="Description: " value={event.description} />
                  <EventDetail label="Adresse: " value={event.address} />
                  <EventDetail label="Nombre de places: " value={event.place !== -1 ? event.place : 'Pas de limite de place'} />
                  <EventDetail label="Participants: " value={event.participants.length > 0 ? event.participant_fullnames.join(', ') : 'Aucun participant pour le moment'} />

                  <Box
                    display='flex'
                    justifyContent='left'
                    alignItems='center'
                    sx={{ my: 2, pl: 9 }}
                  >
                    <Fab size='small' color='primary' onClick={() => { openEditorWindow(index) }}>
                      <EditIcon />
                    </Fab>
                  </Box>
                </Collapse>
                <Divider sx={{ my: 0 }} />
              </div>
            ))}
          </List>
      }

      {
        currentEvents.length == 0 ?
          <Typography align='left' variant='h5' sx={{ color: 'text.secondary', my: 4 }}>Aucun évènement en cours.</Typography>
          :
          <List
            sx={{ width: '100%', mb: 4 }}
            component='nav'
            subheader={
              <ListSubheader component='div' className={css`text-align: start;`}>
                Évènements en cours
              </ListSubheader>
            }
          >
            {currentEvents.map((event: Event, index: number) => (
              <div key={index}>
                <ListItemButton onClick={() => { handleClick(index + futureEvents.length) }}>
                  <ListItemIcon>
                    <RefreshIcon sx={{ color: 'green' }} />
                  </ListItemIcon>
                  <ListItemText
                    primary={event.name}
                    secondary={
                        <Typography component='span' variant='body2' >
                          {dayjs(event.start_date).format('DD/MM/YYYY HH:mm').toString()}
                          <ArrowRightAltIcon style={{ verticalAlign: 'middle' }} sx={{ mx: 1 }} />
                          {dayjs(event.end_date).format('DD/MM/YYYY HH:mm').toString()}
                        </Typography>
                    } />
                  {open.includes(index + futureEvents.length) ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={open.includes(index + futureEvents.length)} timeout='auto' unmountOnExit>
                  <EventDetail label="Description: " value={event.description} />
                  <EventDetail label="Adresse: " value={event.address} />
                  <EventDetail label="Nombre de places: " value={event.place !== -1 ? event.place : 'Pas de limite de place'} />
                  <EventDetail label="Participants: " value={event.participants.length > 0 ? event.participant_fullnames.join(', ') : 'Aucun participant pour le moment'} />
                </Collapse>
                <Divider sx={{ my: 0 }} />
              </div>
            ))}
          </List>
      }

      {
        pastEvents.length == 0 ?
          <Typography align='left' variant='h5' sx={{ color: 'text.secondary', my: 4 }}>Aucun évènement passé.</Typography>
          :
          <div>
            <List
              sx={{ width: '100%', mb: 4 }}
              component='nav'
              subheader={
                <ListSubheader component='div' className={css`text-align: start;`}>
                  Évènements passés
                </ListSubheader>
              }
            >
              {pastEvents.slice((page - 1) * itemsPerPage, page * itemsPerPage).map((event: Event, index: number) => (
                <div key={index}>
                  <ListItemButton onClick={() => { handleClick(index + futureEvents.length + currentEvents.length) }}>
                    <ListItemIcon>
                      <CheckIcon sx={{ color: 'green' }} />
                    </ListItemIcon>
                    <ListItemText
                      primary={event.name}
                      secondary={
                          <Typography component='span' variant='body2' >
                            {dayjs(event.start_date).format('DD/MM/YYYY HH:mm').toString()}
                            <ArrowRightAltIcon style={{ verticalAlign: 'middle' }} sx={{ mx: 1 }} />
                            {dayjs(event.end_date).format('DD/MM/YYYY HH:mm').toString()}
                          </Typography>
                      } />
                    {open.includes(index + futureEvents.length + currentEvents.length) ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse in={open.includes(index + futureEvents.length + currentEvents.length)} timeout='auto' unmountOnExit>
                    <EventDetail label="Description: " value={event.description} />
                    <EventDetail label="Adresse: " value={event.address} />
                    <EventDetail label="Nombre de places: " value={event.place !== -1 ? event.place : 'Pas de limite de place'} />
                    <EventDetail label="Participants: " value={event.participants.length > 0 ? event.participant_fullnames.join(', ') : 'Aucun participant pour le moment'} />
                  </Collapse>
                  <Divider sx={{ my: 0 }} />
                </div>
              ))}
            </List>
            <Box
              display='flex'
              justifyContent='center'
              alignItems='center'
              sx={{ mt: 2 }}
            >
              <Pagination
                count={noOfPages}
                page={page}
                defaultPage={1}
                onChange={handleChange}
                size='large'
                showFirstButton
                showLastButton
                color='primary'
              />
            </Box>
          </div>
      }
    </div>
  );
}