import React, { useState } from 'react';
import { useObservable } from 'micro-observables';
import { accountService } from '../../services/Service';
import { AlertMessage } from '../../common/dto';
import defaultAvatar from '../../assets/default_avatar.png';
import Alert from '@mui/material/Alert';
import Avatar from 'react-avatar-edit'
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import EditIcon from '@mui/icons-material/Edit';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';


export default function AvatarEditor() {

  const account = useObservable(accountService.account);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<AlertMessage>({ message: '', severity: undefined });
  const [preview, setPreview] = useState('');

  const onClose = () => {
    setPreview('')
  }

  const onCrop = (preview: any) => {
    setPreview(preview);
  }

  const saveImage = async () => {
    setLoading(true);

    try {
      await accountService.updateAvatar(preview);
      setMessage({ message: 'Image de profil modifiée.', severity: 'success' });
    } catch (err: any) {
      setMessage(err.response.data.detail.toString());
    } finally {
      setLoading(false);
    }
  }

  if (!account) {
    return <div />
  }

  return (
    <Container sx={{ m: 3, width: 'auto' }}>

      {message.message.length > 0 &&
        <Alert variant='filled' severity={message.severity} sx={{ mb: 6 }}>
          {message.message}
        </Alert>
      }

      <Grid sx={{ mb: 2 }}
        container
        direction='row'
        justifyContent='center'
        alignItems='center'>

        <Avatar
          imageWidth={320}
          width={320}
          height={320}
          onCrop={onCrop}
          onClose={onClose}
          // src={defaultAvatar}
        />
      </Grid>

      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        sx={{ mt: 6, mb: 2 }}>
        {
          preview ?
            <img src={preview} alt='Preview' width='150px' />
            :
            <img src={defaultAvatar} alt='Preview' width='150px' />
        }

        {
          loading ?
            <CircularProgress />
            :
            <Button variant='contained' type='submit' onClick={saveImage} endIcon={<EditIcon />} disabled={loading}>
              Edit
            </Button>
        }
      </Stack>
    </Container>
  )
}