import React, { useEffect } from 'react';
import { galleryService } from '../../services/Service';
import { MyTab } from '../../common/dto';
import TabSelector from '../../common/TabSelector';
import CreateAlbum from './CreateAlbum';
import ViewAlbums from './ViewAlbums';


export default function EventManagement() {
  
  const tabs: MyTab[] = [
    {label: 'Créer un album', content: <CreateAlbum />},
    {label: 'Gestion des albums', content: <ViewAlbums />},
  ]

  useEffect(() => {
    galleryService.fetchAlbums();
  }, []);

  return (
    <TabSelector tabs={tabs} />
  );
}