import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useObservable, useMemoizedObservable } from 'micro-observables';
import { accountService, rmaService } from '../../services/Service';
import { RMA } from '../../services/RMAService';
import { AlertMessage } from '../../common/dto';
import { formatError } from '../../common/formatError';
import dayjs from 'dayjs';
import Alert from '@mui/material/Alert';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import Divider from '@mui/material/Divider';
import DownloadIcon from '@mui/icons-material/Download';
import EditIcon from '@mui/icons-material/Edit';
import Fab from '@mui/material/Fab';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';


dayjs.Ls.en.months = [
  'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet',
  'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'
]

export default function RMAList() {

  const account = useObservable(accountService.account);
  const rmas = useObservable(rmaService.rmas);
  const month_rma = useMemoizedObservable(() => rmaService.getMonthRMA(), []);
  const [message, setMessage] = useState<AlertMessage>({ message: '', severity: undefined });
  const [open, setOpen] = useState(false);
  const itemsPerPage = 25;
  const [page, setPage] = useState(1);
  const [noOfPages] = useState(Math.ceil(rmas.length / itemsPerPage));
  const navigate = useNavigate();

  const handleChange = (event: React.ChangeEvent<any>, value: number) => {
    setPage(value);
  };

  const openHelp = () => {
    setOpen(true);
  };

  const closeHelp = () => {
    setOpen(false);
  };

  useEffect(() => {
    rmaService.fetchUserRMAs();
  }, []);

  const downloadPDF = async (id: number | null, date: string) => {
    if (id) {
      try {
        await rmaService.downloadPDF(id, date);
      } catch (err: any) {
        setMessage({ message: formatError(err), severity: 'error' });
      }
    }
  }

  const signRMA = async (rma: RMA) => {
    if (rma.id) {
      try {
        await rmaService.signRMA(rma);
      } catch (err: any) {
        setMessage({ message: formatError(err), severity: 'error' });
      }
    }
  }

  if (!account) {
    return <div />
  }

  return (
    <Container>
      {message.message.length > 0 &&
        <Alert variant='filled' severity={message.severity} sx={{ mb: 6 }}>
          {message.message}
        </Alert>
      }

      <Dialog onClose={closeHelp} open={open}>
        <Container sx={{ my: 4, width: 'auto' }}>
          <Typography align='left' variant='h4' sx={{ mb: 4 }}>Signature du RMA</Typography>
          <Typography align='left' sx={{ mb: 2, color: 'text.primary' }}>Il vous est possible de signer votre RMA en ligne. Pour cela, vous devez enregistrer votre signature depuis la page &apos;Profil&apos;. Votre signature apparaîtra alors dans le pdf exporté.</Typography>
          <Typography align='left' sx={{ mb: 2, color: 'text.primary' }}>Si vous avez précédemment signé votre RMA, mais que vous l&apos;avez à postériori modifié, vous devez de nouveau signer votre nouveau RMA.</Typography>
        </Container>
      </Dialog>

      <Typography align='left' variant='h4' sx={{ my: 4 }}>Mon RMA ce mois-ci</Typography>
      <Stack
        direction='row'
        justifyContent='flex-start'
        alignItems='center'
        spacing={4}
      >
        {
          !month_rma || !month_rma.id ?
            <Typography align='left' variant='h5' sx={{ color: 'text.secondary' }}>Vous n&apos;avez pas encore créé votre RMA ce mois-ci.</Typography>
            :
            <Typography align='left' variant='h4' sx={{ color: 'text.secondary' }}>
              {dayjs(month_rma.date).format('MMMM YYYY')}
            </Typography>
        }
        <Tooltip title='Éditer mon RMA'>
          <Fab size='small' color='primary' style={{ transform: 'scale(1.2)' }} onClick={() => { navigate('/rma_editor', { state: { rma: month_rma, back: '/rma' } }); }} sx={{ mx: 4 }}>
            <EditIcon />
          </Fab>
        </Tooltip>
      </Stack>

      <Typography align='left' variant='h4' sx={{ mt: 6, mb: 4 }}>Mes RMAs</Typography>
      {
        rmas.length == 0 ?
          <Typography align='left' variant='h6' sx={{ color: 'text.secondary', my: 4 }}>Vous n&apos;avez pas encore créé de RMA.</Typography>
          :
          <div>
            <List sx={{ width: '100%', bgcolor: 'background.paper', overflowWrap: 'anywhere' }}>
              <ListItem disablePadding >
                <ListItemText primary='Mois' sx={{ width: '16.5%', fontSize: { xs: '10px', sm: '12px', md: '14px', lg: '16px', xl: '18px'}}} />
                <ListItemText primary='Création' sx={{ width: '16.5%', fontSize: { xs: '10px', sm: '12px', md: '14px', lg: '16px', xl: '18px'}}} />
                <ListItemText primary='Modification' sx={{ width: '16.5%', fontSize: { xs: '10px', sm: '12px', md: '14px', lg: '16px', xl: '18px'}}} />
                <ListItemText primary='Modifier' sx={{ width: '16.5%', textAlign: 'center', fontSize: { xs: '10px', sm: '12px', md: '14px', lg: '16px', xl: '18px'}}} />
                <ListItemText
                  primary={
                      <Box
                        display='flex'
                        justifyContent='center'
                        alignItems='center'
                      >
                        <Typography>Signer</Typography>
                        <Fab size='small' color='default' aria-label='help' style={{ transform: 'scale(0.6)' }} onClick={() => openHelp()}>
                          <HelpOutlineIcon />
                        </Fab>
                      </Box>
                  }
                  sx={{ width: '16.5%', textAlign: 'center', fontSize: { xs: '10px', sm: '12px', md: '14px', lg: '16px', xl: '18px'}}} />
                <ListItemText primary='Exporter' sx={{ width: '16.5%', textAlign: 'center', fontSize: { xs: '10px', sm: '12px', md: '14px', lg: '16px', xl: '18px'} }} />
              </ListItem>

              <Divider sx={{ my: 2 }} />

              {
                rmas.slice((page - 1) * itemsPerPage, page * itemsPerPage).filter(rma => rma.id).map((rma: RMA, index: number) => (

                  <ListItem key={index} disablePadding >
                    <ListItemText primary={dayjs(rma.date).format('YYYY/MM')} sx={{ width: '16.5%' , fontSize: { xs: '10px', sm: '12px', md: '14px', lg: '16px', xl: '18px'}}} />
                    <ListItemText primary={dayjs(rma.creation_date).format('DD/MM/YYYY')} sx={{ width: '16.5%', fontSize: { xs: '10px', sm: '12px', md: '14px', lg: '16px', xl: '18px'} }} />
                    <ListItemText primary={dayjs(rma.modification_date).format('DD/MM/YYYY')} sx={{ width: '16.5%', fontSize: { xs: '10px', sm: '12px', md: '14px', lg: '16px', xl: '18px'} }} />

                    <ListItemText
                      sx={{ width: '16.5%', textAlign: 'center', fontSize: { xs: '10px', sm: '12px', md: '14px', lg: '16px', xl: '18px'} }}
                      primary={
                          <Fab size='small' color='primary' style={{ transform: 'scale(0.9)' }} onClick={() => { navigate('/rma_editor', { state: { rma: rma, back: '/rma' } }); }}>
                            <EditIcon />
                          </Fab>
                      } />

                    <ListItemText
                      sx={{ width: '16.5%', textAlign: 'center', fontSize: { xs: '10px', sm: '12px', md: '14px', lg: '16px', xl: '18px'} }}
                      primary={
                          <Fab disabled={account.signature === null} size='small' color={rma.is_signed ? 'primary' : 'default'} style={{ transform: 'scale(0.9)' }} onClick={() => signRMA(rma)}>
                            <BorderColorIcon />
                          </Fab>
                      } />

                    <ListItemText
                      
                      primary={
                          <Fab size='small' color='primary' style={{ transform: 'scale(0.9)' }} onClick={() => downloadPDF(rma.id, rma.date)}>
                            <DownloadIcon />
                          </Fab>
                      } />
                  </ListItem>
                ))}
            </List>
            <Box
              display='flex'
              justifyContent='center'
              alignItems='center'
              sx={{ mt: 2 }}
            >
              <Pagination
                count={noOfPages}
                page={page}
                defaultPage={1}
                onChange={handleChange}
                size='large'
                showFirstButton
                showLastButton
                color='primary'
              />
            </Box>
          </div>
      }
    </Container >
  );
}