import React, { useState, useRef } from 'react';
import { accountService } from '../../services/Service';
import { AlertMessage } from '../../common/dto';
import SignatureCanvas from 'react-signature-canvas';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import EditIcon from '@mui/icons-material/Edit';
import Grid from '@mui/material/Grid';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';


export default function SignatureEditor() {

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<AlertMessage>({ message: '', severity: undefined });

  const signCanvas = useRef() as React.MutableRefObject<any>;

  const clearCanvas = () => {
    signCanvas.current.clear();
  };

  const saveSign = async () => {
    setLoading(true);

    if (signCanvas.current.isEmpty()) {
      setMessage({ message: "Aucune signature n'est dessinée pour le moment", severity: 'error' });
    } else {
      try {
        await accountService.updateSignDraw(signCanvas.current.getTrimmedCanvas().toDataURL('image/png'));
        clearCanvas();
        setMessage({ message: 'Signature enregistrée', severity: 'success' });
      } catch (err: any) {
        setMessage(err.response.data.detail.toString());
      }
    }

    setLoading(false);
  }

  return (
    <Container sx={{ my: 4, width: 'auto' }} >

      {message.message.length > 0 &&
        <Alert variant='filled' severity={message.severity} sx={{ mb: 6 }}>
          {message.message}
        </Alert>
      }

      <Typography align='left' variant='h4' sx={{ mb: 6 }}>Signature</Typography>

      <Grid
        container
        justifyContent='center'
        alignItems='center'>
        <Grid item>
          <Box sx={{ border: 2, width: 404, height: 154 }}>
            <SignatureCanvas
              penColor='black'
              canvasProps={{ width: 400, height: 150, className: 'sigCanvas' }}
              backgroundColor='rgba(255, 255, 255, 1)'
              ref={signCanvas}
            />
          </Box>
        </Grid>
      </Grid>

      {
        loading ?
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            sx={{ mt: 4 }}
          >
            <CircularProgress />
          </Box>
          :
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            sx={{ mt: 4 }}
          >
            <Button variant='contained' type='submit' onClick={clearCanvas} endIcon={<RestartAltIcon />} disabled={loading}>
              Reset
            </Button>
            <Button variant='contained' type='submit' onClick={saveSign} endIcon={<EditIcon />} disabled={loading}>
              Edit
            </Button>
          </Stack>
      }
    </Container>
  );
}