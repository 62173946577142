import React, { useState } from 'react';
import { useObservable } from 'micro-observables';
import { accountService } from '../../services/Service';
import MissionEditor from './MissionEditor'
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import EditIcon from '@mui/icons-material/Edit';
import Fab from '@mui/material/Fab';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';


export default function MissionInfo() {

  const account = useObservable(accountService.account);
  const [open, setOpen] = useState(false);

  const openEditor = () => {
    setOpen(true);
  };

  const closeEditor = () => {
    setOpen(false);
  };

  if (!account) {
    return <div/>
  }

  return (
    <Paper elevation={3} sx={{ my: 3, py: 2 }}>

      <Dialog onClose={closeEditor} open={open}>
        <MissionEditor mission={account.mission}/>
      </Dialog>

      <Container>
        <Grid
          container
          direction='row'
          justifyContent='flex-start'
          alignItems='center'
          spacing={2}
        >
          <Grid item>
            <Typography variant='h5'>Information sur la mission</Typography>
          </Grid>

          <Grid item sx={{ flexGrow: 1 }} />

          <Grid item>
            <Fab size='small' color='primary' aria-label='change image' onClick={openEditor}>
              <EditIcon />
            </Fab>
          </Grid>

          <Box sx={{ width: '100%' }} />

          <Grid item xs={5} sm={4} md={3}>
            <Typography align='left' sx={{ color: 'text.secondary' }}>Client</Typography>
          </Grid>

          <Grid item xs={7} sm={8} md={9}>
            <Typography align='left'>{account.mission.client}</Typography>
          </Grid>

          <Box sx={{ width: '100%' }} />

          <Grid item xs={5} sm={4} md={3}>
            <Typography align='left' sx={{ color: 'text.secondary' }}>Service</Typography>
          </Grid>

          <Grid item xs={7} sm={8} md={9}>
            <Typography align='left'>{account.mission.service}</Typography>
          </Grid>

          <Box sx={{ width: '100%' }} />

          <Grid item xs={5} sm={4} md={3}>
            <Typography align='left' sx={{ color: 'text.secondary' }}>Responsable</Typography>
          </Grid>

          <Grid item xs={7} sm={8} md={9}>
            <Typography align='left'>{account.mission.leader_name}</Typography>
          </Grid>

          <Box sx={{ width: '100%' }} />

          <Grid item xs={5} sm={4} md={3}>
            <Typography align='left' sx={{ color: 'text.secondary' }}>E-mail du responsable</Typography>
          </Grid>

          <Grid item xs={7} sm={8} md={9}>
            <Typography align='left'>{account.mission.leader_email}
            </Typography>
          </Grid>

          <Box sx={{ width: '100%' }} />

          <Grid item xs={5} sm={4} md={3}>
            <Typography align='left' sx={{ color: 'text.secondary' }}>Mission</Typography>
          </Grid>

          <Grid item xs={7} sm={8} md={9}>
            <Typography align='left'>{account.mission.description}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Paper>
  );
}