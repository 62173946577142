import React, { useState } from 'react';
import { useObservable } from 'micro-observables';
import { accountService } from '../services/Service';
import { useKonami } from 'react-konami-code';
import Dialog from '@mui/material/Dialog';


/**
 * Renders a Troll component.
 * This component displays a dialog with a cat image and a personalized greeting.
 * The dialog can be opened by triggering a Konami code.
 */
export default function Troll() {

  const account = useObservable(accountService.account);
  const [open, setOpen] = useState(false);

  const openEditor = async () => {
    setOpen(true);
  };

  const closeEditor = () => {
    setOpen(false);
  };

  useKonami(openEditor);

  return (
    <Dialog onClose={() => { closeEditor() }} open={open}>
      <img src={'https://cataas.com/cat/says/Hello ' + account?.first_name + '!?' + Date.now()} />
    </Dialog>
  );
}