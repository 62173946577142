import React, { useState } from 'react';
import { expensesService } from '../../services/Service';
import { Expense } from '../../services/ExpensesService';
import { AlertMessage } from '../../common/dto';
import { formatError } from '../../common/formatError';
import DeleteConfirm from '../../common/DeleteConfirm';
import { useFormik } from 'formik';
import * as yup from 'yup'
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import EditIcon from '@mui/icons-material/Edit';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import UploadFileIcon from '@mui/icons-material/UploadFile';


interface Props {
  expense: Expense;
  setNotification: (msg: AlertMessage) => void;
}

export default function ExpenseFormular(props: Props) {

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<AlertMessage>({ message: '', severity: undefined });

  const formik = useFormik({
    initialValues: {
      description: props.expense.description,
      bills: [],
      deleted: false,
    },
    validationSchema: yup.object({
      description: yup
        .string()
        .trim()
        .required('Description requise'),
      bills: yup
        .array(),
      deleted: yup
        .boolean()
    }),
    onSubmit: (values) => {
      setLoading(true);

      if (values.deleted) {
        handleDelete();
      } else {
        const expense: Expense = {
          id: props.expense.id,
          creator: props.expense.creator,
          creator_fullname: props.expense.creator_fullname,
          description: values.description,
          asking_date: props.expense.asking_date,
          paiement_date: props.expense.paiement_date,
          validation_date: props.expense.validation_date,
          reason: props.expense.reason,
          is_accepted: props.expense.is_accepted,
          fees: [],
          bills: [],
          is_signed: false,
        }

        handleUpdate(expense, values.bills);
      }
    },
  });

  const handleDelete = async () => {
    try {
      await expensesService.removeExpense(props.expense);
      props.setNotification({ message: 'La demande de remboursement a bien été suprimée', severity: 'success' });
    } catch (err: any) {
      setMessage({ message: formatError(err), severity: 'error' });
    } finally {
      setLoading(false);
    }
  }

  const handleUpdate = async (expense: Expense, bills: File[]) => {
    try {
      await expensesService.updateExpense(expense, bills);
      props.setNotification({ message: 'La demande de remboursement a bien été modifiée', severity: 'success' });
    } catch (err: any) {
      setMessage({ message: formatError(err), severity: 'error' });
    } finally {
      setLoading(false);
    }
  }

  return (
    <Container sx={{ my: 4, width: 'auto' }}>
      {message.message.length > 0 &&
        <Alert variant='filled' severity={message.severity} sx={{ mb: 6 }}>
          {message.message}
        </Alert>
      }

      <form onSubmit={formik.handleSubmit}>
        <Typography align='left' variant='h4' sx={{ mb: 6 }}>Modifier une fiche de frais</Typography>

        <Typography align='left' sx={{ mt: 4, mb: 2 }}>Évènement</Typography>
        <TextField
          fullWidth
          label="nom de l'évènement"
          name='description'
          size='small'
          multiline
          minRows={2}
          maxRows={10}
          value={formik.values.description}
          onChange={formik.handleChange}
          error={formik.touched.description && Boolean(formik.errors.description)}
          helperText={formik.touched.description && formik.errors.description}
        />

        <Button
          sx={{ my: 4 }}
          variant='contained'
          component='label'
          endIcon={<UploadFileIcon />}>
          Ajouter des justificatifs
          <input
            name='bills'
            type='file'
            hidden
            onChange={(event) => {
              const files = event.target.files;
              if (!files) {
                formik.setFieldValue('bills', []);
              } else {
                formik.setFieldValue('bills', Array.from(files));
              }
            }}
            multiple
          />
        </Button>

        {formik.values.bills.map((value: File, index: number) => (
          <Typography variant='body2' align='left' sx={{ color: 'text.secondary' }} key={index}>{value.name}</Typography>
        ))}

        {
          loading ?
            <Box
              display='flex'
              justifyContent='center'
              alignItems='center'
              sx={{ mt: 4 }}
            >
              <CircularProgress />
            </Box>
            :
            <Stack
              direction='row'
              justifyContent='space-between'
              alignItems='center'
              sx={{ mt: 4 }}
            >
              <Button variant='contained' type='submit' endIcon={<EditIcon />} disabled={loading}>
                Modifier
              </Button>
              <DeleteConfirm message={'Êtes vous sur de vouloir la demande de remboursement'} disabled={loading} action={() => { formik.setFieldValue(`deleted`, true); formik.submitForm() }} />
            </Stack>
        }
      </form>
    </Container >
  );
}