import React, { useState } from 'react';
import { accountService } from '../services/Service';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Footer from '../common/Footer'
import logo from '../assets/xrays_logo_white.svg';
import Alert from '@mui/material/Alert';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import LoginIcon from '@mui/icons-material/Login';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { css } from '@emotion/css';
import { Link } from 'react-router-dom';


export default function Login() {

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (username: string, password: string) => {
    try {
      await accountService.login(username, password);
      setLoading(false);
      navigate('/home');
    } catch (err: any) {
      setMessage(err.response.data.detail.toString());
      setLoading(false);
    }
  }

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: yup.object({
      username: yup
        .string()
        .trim()
        .required('Username requis'),
      password: yup
        .string()
        .trim()
        .required('Password requis'),
    }),
    onSubmit: (values) => {
      setLoading(true);
      handleLogin(values.username, values.password);
    },
  });

  return (
    <div>
      <div className={css`position: relative; min-height: 86vh;`}>
        <Box sx={{ flexGrow: 1 }}>
          <AppBar position='fixed'>
            <Toolbar>
              <Box component='img' alt='XRAYS TRADING logo' src={logo} sx={{ height: '40px' }} />
            </Toolbar>
          </AppBar>
        </Box>

        <div className={css`padding-bottom: 200px;`}>
          <form onSubmit={formik.handleSubmit}>

            <Stack
              direction='column'
              justifyContent='left'
              alignItems='center'
              sx={{ mt: 10 }}
            >
              <Typography align='left' variant='h4' sx={{ my: 3 }}>Connexion</Typography>

              <Box sx={{ my: 2 }}>
                {message.length > 0 &&
                  <Alert variant='filled' severity='error'>
                    {message}
                  </Alert>
                }
              </Box>

              <TextField
                name='username'
                label='username'
                size='small'
                value={formik.values.username}
                onChange={formik.handleChange}
                error={formik.touched.username && Boolean(formik.errors.username)}
                helperText={formik.touched.username && formik.errors.username}
                sx={{ my: 2 }}
              />

              <TextField
                type='password'
                name='password'
                label='password'
                autoComplete='on'
                size='small'
                value={formik.values.password}
                onChange={formik.handleChange}
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
                sx={{ my: 2 }}
              />

            </Stack>

            <Box sx={{ my: 2 }}>
              {
                loading ?
                  <CircularProgress />
                  :
                  <Button variant='contained' type='submit' endIcon={<LoginIcon />} disabled={loading}>
                    Connexion
                  </Button>
              }
            </Box>
          </form>
          <Link to="/forgot-password">Mot de passe oublié ?</Link>
        </div>

        <div className={css`position: absolute; bottom: 0; width: 100%; height: 200px;`}>
          <Footer />
        </div>
      </div>
    </div>
  );
}