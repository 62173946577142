import React, { useState } from 'react';
import { useObservable } from 'micro-observables';
import { accountService } from './../services/Service';
import { websiteStructure } from './WebsiteStructure';
import { Group, Page } from './dto';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import MenuIcon from '@mui/icons-material/Menu';


export default function Navigation() {

  const account = useObservable(accountService.account);

  const [open, setOpen] = useState(false);
  const toggleDrawer = () => { setOpen(!open) };

  const getNoEmptyGroups = () => {
    if (!account) {
      return [];
    }
    return websiteStructure.groups.filter((group: Group) =>
      group.pages.filter((page: Page) =>
        !page.requiredAuthorization || account.authorizations.includes(page.requiredAuthorization) || account.is_superuser
      ).length > 0
    )
  }

  if (!account) {
    return <div />
  }

  return (
    <div>
      <IconButton
        size='large'
        edge='start'
        color='inherit'
        aria-label='open drawer'
        sx={{ mr: 2 }}
        onClick={toggleDrawer}
      >
        <MenuIcon />
      </IconButton>

      <Drawer
        anchor='left'
        open={open}
        onClose={toggleDrawer}
      >
        <Box
          role='presentation'
          onClick={toggleDrawer}
          onKeyDown={toggleDrawer}
          minWidth='300px'
        >
          {getNoEmptyGroups().map((group: Group, index: number) => (
            <div key={index}>
              <List subheader={
                group.name ?
                  <ListSubheader component='div'>
                    {group.name}
                  </ListSubheader>
                  :
                  false
              }>
                {group.pages.filter((page: Page) => !page.requiredAuthorization || account.authorizations.includes(page.requiredAuthorization) || account.is_superuser).map((page: Page) => (
                  <ListItem key={page.name} disablePadding>
                    <ListItemButton component={Link} to={page.path}>
                      <ListItemIcon>
                        {page.icon}
                      </ListItemIcon>
                      <ListItemText primary={page.name} />
                    </ListItemButton>
                  </ListItem>
                ))
                }
              </List>

              {
                index !== getNoEmptyGroups().length - 1 &&
                <Divider />
              }
            </div>
          ))
          }
        </Box>
      </Drawer>
    </div>
  );
}