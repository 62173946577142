import React, { useState } from 'react';
import { useLocation, useNavigate, Navigate } from 'react-router-dom';
import { rmaService } from '../../services/Service';
import { midDayStatus, RMA } from '../../services/RMAService';
import { AlertMessage } from '../../common/dto';
import { formatError } from '../../common/formatError';
import { FieldArray, Form, Formik } from 'formik';
import * as yup from 'yup';
import dayjs, { Dayjs } from 'dayjs';
import Alert from '@mui/material/Alert';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CheckIcon from '@mui/icons-material/Check';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import Divider from '@mui/material/Divider';
import Fab from '@mui/material/Fab';
import FormControl from '@mui/material/FormControl';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Grid from '@mui/material/Grid';
import HomeIcon from '@mui/icons-material/Home';
import MenuItem from '@mui/material/MenuItem';
import ModeNightIcon from '@mui/icons-material/ModeNight';
import NightlightIcon from '@mui/icons-material/Nightlight';
import Paper from '@mui/material/Paper';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import SaveIcon from '@mui/icons-material/Save';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { css } from '@emotion/css';
import { AllInclusive, BeachAccess, Celebration, Chair, ChildFriendly, FamilyRestroom, Medication, Nightlife, WorkOff } from '@mui/icons-material';
import Holidays from 'date-holidays';


dayjs.Ls.en.months = [
  'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet',
  'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'
]

const TableCellBordered = styled(TableCell)`
  &.MuiTableCell-root {
    border: 1px solid rgba(150, 150, 150, 1);
  }
`;

export default function RMAEditor() {

  const { state } = useLocation();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [dayIndex, setDayIndex] = useState(1);
  const [midDay, setmidDay] = useState<'am' | 'pm'>('am');
  const [helpOpen, setHelpOpen] = useState(false);

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<AlertMessage>({ message: '', severity: undefined });

  const hd = new Holidays('FR');

  const handleClickOpen = (index: number, midDay: 'am' | 'pm') => {
    setDayIndex(index);
    setmidDay(midDay);
    setOpen(true);
  };

  const closeEditor = () => {
    setOpen(false);
  };

  const openHelp = () => {
    setHelpOpen(true);
  };

  const closeHelp = () => {
    setHelpOpen(false);
  };

  const validationSchema = yup.object().shape({
    days: yup.array().of(
      yup.object().shape({
        am: yup
          .boolean()
          .required('AM status requis'),
        pm: yup
          .boolean()
          .required('PM status requis'),
        am_status: yup
          .string()
          .trim()
          .required('AM status requis')
          .test(
            // Test if am_status is equal to 'RTT' pm_status must be equal to 'RTT',
            'isRTT',
            'RTT doit être posé sur une journée entière.',
            function (value: string | undefined) { // Update the type of the value parameter
              if (value === 'RTT') {
                if (this.parent.pm_status === 'RTT')
                  return true;
                return false;
              }
              return true;
            }
          ),
        pm_status: yup
          .string()
          .trim()
          .required('PM status requis')
          .test(
            // Test if am_status is equal to 'RTT' pm_status must be equal to 'RTT',
            'isRTT',
            'RTT doit être posé sur une journée entière.',
            function (value: string | undefined) { // Update the type of the value parameter
              if (value === 'RTT') {
                if (this.parent.am_status === 'RTT')
                  return true;
                return false;
              }
              return true;
            }
          ),
        call_on_day: yup
          .boolean()
          .required('Astreinte requise'),
        night_first_part: yup
          .boolean()
          .required('Nuit requise'),
        night_second_part: yup
          .boolean()
          .required('Nuit requise'),
      })
    )
  });

  const handleDemand = async (rma: RMA) => {
    try {
      await rmaService.addRMA(rma);
      setMessage({ message: 'RMA créé.', severity: 'success' });
    } catch (err: any) {
      setMessage({ message: formatError(err), severity: 'error' });
    } finally {
      setLoading(false);
    }
  }

  const handleUpdate = async (rma: RMA) => {
    try {
      await rmaService.updateRMA(rma);
      setMessage({ message: 'RMA modifié.', severity: 'success' });
    } catch (err: any) {
      setMessage({ message: formatError(err), severity: 'error' });
    } finally {
      setLoading(false);
    }
  }

  if (!state) {
    return <Navigate to='/rma' />
  }

  const calendar = rmaService.getMonthCalendar(state.rma);

  return (
    <Container sx={{ mb: 6 }}>
      {message.message.length > 0 &&
        <Alert variant='filled' severity={message.severity} sx={{ mb: 6 }}>
          {message.message}
        </Alert>
      }

      <Formik
        initialValues={{
          days: rmaService.getFormularInitialValues(state.rma),
        }}
        validationSchema={validationSchema}
        onSubmit={values => {
          setLoading(true);

          try {
            validationSchema.validateSync(values);
          } catch (error) {
            console.error(error);
            if (error instanceof yup.ValidationError) {
              setMessage({ message: formatError(error.message), severity: 'error' });
              console.error(error);
            }
          }

          const content: any[] = [];
          values.days.forEach(value => content.push({
            number: value.number,
            am: value.am ? 'True' : 'False',
            pm: value.pm ? 'True' : 'False',
            am_status: value.am_status,
            pm_status: value.pm_status,
            call_on_day: value.call_on_day ? 'True' : 'False',
            night_first_part: value.night_first_part ? 'True' : 'False',
            night_second_part: value.night_second_part ? 'True' : 'False',
          }));

          const rma: RMA = {
            id: state.rma.id,
            creator: state.rma.creator,
            date: state.rma.date,
            creation_date: state.rma.creation_date,
            modification_date: dayjs().format('YYYY-MM-DD').toString(),
            content: JSON.stringify(content),
            is_signed: false,
          }

          if (rma.id) {
            handleUpdate(rma);
          } else {
            handleDemand(rma);
          }
        }}
      >

        {({ values, setFieldValue }) => (
          <Form noValidate autoComplete='off'>

            <Dialog onClose={closeEditor} open={open}>
              <Container sx={{ my: 3, width: 400 }}>
                <Typography variant='h6'>{midDay === 'am' ? dayjs(state.rma.date).set('date', dayIndex + 1).format('DD/MM/YYYY') + ' matin' : dayjs(state.rma.date).set('date', dayIndex + 1).format('DD/MM/YYYY') + ' aprés-midi'}</Typography>
                <FormControl sx={{ minWidth: 200, maxwidth: 400, my: 3 }}>
                  {
                    midDay === 'am' ?
                      <Select
                        name='MidDayType'
                        value={values.days[dayIndex].am_status}
                        onChange={(event: SelectChangeEvent) => {
                          setFieldValue(`days[${dayIndex}].am_status`, event.target.value, true)
                          if (['Jour travaillé', 'Télétravail'].includes(event.target.value)) {
                            setFieldValue(`days[${dayIndex}].am`, true, true)
                          } else {
                            setFieldValue(`days[${dayIndex}].am`, false, true)
                          }
                          closeEditor();
                        }}
                      >
                        {
                          hd.isHoliday(new Date(dayjs(state.rma.date).set('date', dayIndex + 1).format('YYYY-MM-DD'))) ?
                            midDayStatus
                              .filter(item => item === 'Télétravail' || item === 'Jour travaillé' || item === 'Jour férié')
                              .map((item, index) => (
                                <MenuItem value={item} key={index}>{item}</MenuItem>
                              ))
                            :
                            dayjs(state.rma.date).set('date', dayIndex + 1).isSame(dayjs(state.rma.date).set('date', dayIndex + 1).endOf('week'), 'day') ||
                              dayjs(state.rma.date).set('date', dayIndex + 1).isSame(dayjs(state.rma.date).set('date', dayIndex + 1).endOf('week').subtract(1, 'day'), 'day') ?
                              midDayStatus
                                .filter(item => item === 'Télétravail' || item === 'Weekend' || item === 'Jour travaillé')
                                .map((item, index) => (
                                  <MenuItem value={item} key={index}>{item}</MenuItem>
                                ))
                              :
                              midDayStatus
                                .filter(item => item !== 'Jour férié' && item !== 'Weekend')
                                .map((item, index) => (
                                  <MenuItem value={item} key={index}>{item}</MenuItem>
                                ))
                        }

                      </Select>
                      :
                      <Select
                        name='MidDayType'
                        value={values.days[dayIndex].pm_status}
                        onChange={(event: SelectChangeEvent) => {
                          setFieldValue(`days[${dayIndex}].pm_status`, event.target.value, true)
                          if (['Jour travaillé', 'Télétravail'].includes(event.target.value)) {
                            setFieldValue(`days[${dayIndex}].pm`, true, true)
                          } else {
                            setFieldValue(`days[${dayIndex}].pm`, false, true)
                          }
                          closeEditor();
                        }}
                      >
                        {
                          hd.isHoliday(new Date(dayjs(state.rma.date).set('date', dayIndex + 1).format('YYYY-MM-DD'))) ?
                            midDayStatus
                              .filter(item => item === 'Télétravail' || item === 'Jour travaillé' || item === 'Jour férié')
                              .map((item, index) => (
                                <MenuItem value={item} key={index}>{item}</MenuItem>
                              ))
                            :
                            dayjs(state.rma.date).set('date', dayIndex + 1).isSame(dayjs(state.rma.date).set('date', dayIndex + 1).endOf('week'), 'day') ||
                              dayjs(state.rma.date).set('date', dayIndex + 1).isSame(dayjs(state.rma.date).set('date', dayIndex + 1).endOf('week').subtract(1, 'day'), 'day') ?
                              midDayStatus
                                .filter(item => item === 'Télétravail' || item === 'Weekend' || item === 'Jour travaillé')
                                .map((item, index) => (
                                  <MenuItem value={item} key={index}>{item}</MenuItem>
                                ))
                              :
                              midDayStatus
                                .filter(item => item !== 'Jour férié' && item !== 'Weekend')
                                .map((item, index) => (
                                  <MenuItem value={item} key={index}>{item}</MenuItem>
                                ))
                        }
                      </Select>
                  }
                </FormControl>
                <Dialog onClose={closeHelp} open={helpOpen}>
                  <Container sx={{ my: 4, width: 'auto' }}>
                  <Typography align='left' variant='h4' sx={{ mb: 4, wordBreak: 'break-word' }}>Types d&apos;absences</Typography>
                  <Typography align='left' variant='h6' sx={{ mb: 1, wordBreak: 'break-word' }}>Congés payés</Typography>
                  <Typography align='left' sx={{ mb: 2, color: 'text.secondary', wordBreak: 'break-word' }}>Les congés payés sont accumulés sur la période du 1er juin au 31 mai de l&apos;année suivante. le compteur de congés N-1 doit être soldé au 31/05</Typography>
                  <Typography align='left' variant='h6' sx={{ mb: 1, wordBreak: 'break-word' }}>RTT</Typography>
                  <Typography align='left' sx={{ mb: 2, color: 'text.secondary', wordBreak: 'break-word' }}>Un RTT est une journée de récupération du temps de travail. Vous ne pouvez pas poser un demi RTT (vous devez poser une journée entière). Vous ne pouvez pas poser deux RTT consécutifs, Pour un jour d&apos;absence, il faut privilégier 1 RTT s&apos;il est acquis. Vous devez avoir utilisé tous vos RTT de l&apos;année en cours avant le 31 Décembre.</Typography>
                  <Typography align='left' variant='h6' sx={{ mb: 1, wordBreak: 'break-word' }}>Congé maladie</Typography>
                  <Typography align='left' sx={{ mb: 2, color: 'text.secondary', wordBreak: 'break-word' }}>La salariée est tenu d&apos;informer et fournir à son employeur une attestation sous 48h.</Typography>
                  <Typography align='left' variant='h6' sx={{ mb: 1, wordBreak: 'break-word' }}>Congé maternité</Typography>
                  <Typography align='left' sx={{ mb: 2, color: 'text.secondary', wordBreak: 'break-word' }}>Le congé maternité est divisé en deux parties: le congé prénatal d&apos;une durée de 6 semaines (8 à partir du 3ème enfant), et le congé postnatal d&apos;une durée de 10 semaines (18 à partir du 3ème enfant). Ce congé est obligatoire. Le salarié est tenu d&apos;informer son employeur de manière officielle (avec avis de réception).</Typography>
                  <Typography align='left' variant='h6' sx={{ mb: 1, wordBreak: 'break-word' }}>Congé paternité</Typography>
                  <Typography align='left' sx={{ mb: 2, color: 'text.secondary', wordBreak: 'break-word' }}>Le congé paternité comporte deux périodes distinctes. Une première période obligatoire de 4 jours calendaires prise immédiatement après la naissance et consécutivement aux 3 jours de naissance pris au moment de cet évènement et payés par l&apos;employeur. Une seconde période de 21 jours calendaires, pouvant être fractionnée en 2 périodes au plus d&apos;une durée minimale de 5 jours. Cette deuxième période doit être posée dans les 6 mois qui suivent la naissance.</Typography>
                  <Typography align='left' variant='h6' sx={{ mb: 1, wordBreak: 'break-word' }}>Congé pour évènements familiaux</Typography>
                  <Typography align='left' sx={{ mb: 2, color: 'text.secondary', wordBreak: 'break-word' }}>En cas de naissance, mariage, conclusion d&apos;un PACS ou décès d&apos;un proche, le salarié peut s&apos;absenter pendant une durée minimale de 1 à 7 jours, selon les circonstances: 4j pour le mariage ou le PACS du salarié, 1j pour le mariage d&apos;un enfant, 5j pour le décès d&apos;un enfant (7 si l&apos;enfant est agé de moins de 25ans), 3j pour le décès du conjoint, concubin, père, mère, beau-père, belle-mère, frère ou soeur (Toutefois, un accord applicable dans l&apos;entreprise peut prévoir un congé en cas de décès d&apos;autres membres de la famille). La demande d&apos;absence doit être adressée à l&apos;employeur jointe d&apos;un justificatif (certificat de décès...). Ces congés n&apos;entraînent pas de réduction de la rémunération qui tient compte, le cas échéant, des indemnités versées par la Sécurité sociale, et sont assimilés à du temps de travail effectif pour la détermination de la durée du congé payé annuel.</Typography>
                  <Typography align='left' variant='h6' sx={{ mb: 1, wordBreak: 'break-word' }}>Autre</Typography>
                  <Typography align='left' sx={{ mb: 2, color: 'text.secondary', wordBreak: 'break-word' }}>Congé parental d&apos;éducation, congé de proche aidant, congé sans solde, congé sabbatique, congé d&apos;examen...</Typography>
                  </Container>
                </Dialog>
                <Fab size='small' color='default' aria-label='help' style={{ transform: 'scale(0.6)' }} onClick={() => openHelp()} sx={{ ml: 2 }}>
                  <HelpOutlineIcon />
                </Fab>
              </Container>
            </Dialog>

            <Box
              display='flex'
              justifyContent='flex-start'
              alignItems='flex-start'
            >
              <Tooltip title='Mes RMAs'>
                <Fab size='small' color='primary' aria-label='back' style={{ transform: 'scale(1)' }} onClick={() => { navigate(state.back) }}>
                  <ArrowBackIcon fontSize='medium' />
                </Fab>
              </Tooltip>
            </Box>

            <Typography variant='h4' align='center' sx={{ mb: 4 }}>{dayjs(state.rma.date).format('MMMM')}</Typography>

            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label='simple table'>
                <TableHead>
                  <TableRow>
                    <TableCellBordered align='center' width='14%' sx={{ minWidth: 120 }}>Lundi</TableCellBordered>
                    <TableCellBordered align='center' width='14%' sx={{ minWidth: 120 }}>Mardi</TableCellBordered>
                    <TableCellBordered align='center' width='14%' sx={{ minWidth: 120 }}>Mercredi</TableCellBordered>
                    <TableCellBordered align='center' width='14%' sx={{ minWidth: 120 }}>Jeudi</TableCellBordered>
                    <TableCellBordered align='center' width='14%' sx={{ minWidth: 120 }}>Vendredi</TableCellBordered>
                    <TableCellBordered align='center' width='14%' sx={{ minWidth: 120 }}>Samedi</TableCellBordered>
                    <TableCellBordered align='center' width='14%' sx={{ minWidth: 120 }}>Dimanche</TableCellBordered>
                  </TableRow>
                </TableHead>

                <FieldArray name='days'>
                  {() => (
                    <TableBody>

                      {Array.from({ length: calendar.length / 7 }).map((item: any, week_index: number) => (
                        <TableRow
                          key={week_index}
                        >

                          {calendar.slice(week_index * 7, week_index * 7 + 7).map((date: Dayjs, index: number) => {
                            const isWeekend = date.day() === 0 || date.day() === 6; // Check if it's a weekend
                            const isHoliday = hd.isHoliday(new Date(date.format('YYYY-MM-DD'))); // Check if it's a holiday
                            return (
                              !(date.month() === dayjs(state.rma.date).month()) ?
                                <TableCellBordered align='center' key={week_index * 7 + index} >
                                  <Typography align='right' sx={{ color: 'text.secondary', mt: -1 }}>{date.format('DD').toString()}</Typography>
                                </TableCellBordered>
                                :
                                <TableCellBordered align='center' key={week_index * 7 + index} style={{ backgroundColor: isHoliday ? 'orange' : (isWeekend ? 'silver' : '') }}>
                                  <Box
                                    display='flex'
                                    justifyContent='space-between'
                                    alignItems='flex-start'
                                    sx={{ mt: -1 }}
                                  >

                                    <Tooltip title='call_on_day'>
                                      <Fab size='small' color={values.days[date.get('date') - 1].call_on_day ? 'primary' : 'default'} aria-label='add' sx={{ ml: -1 }} style={{ transform: 'scale(0.7)' }} onClick={() => { setFieldValue(`days[${date.get('date') - 1}].call_on_day`, !values.days[date.get('date') - 1].call_on_day, true) }}>
                                        <PhoneInTalkIcon fontSize='small' />
                                      </Fab>
                                    </Tooltip>

                                    <Tooltip title='Nuit 22h-02h'>
                                      <Fab size='small' color={values.days[date.get('date') - 1].night_first_part ? 'primary' : 'default'} aria-label='add' sx={{ ml: -1 }} style={{ transform: 'scale(0.7)' }} onClick={() => { setFieldValue(`days[${date.get('date') - 1}].night_first_part`, !values.days[date.get('date') - 1].night_first_part, true) }}>
                                        <NightlightIcon fontSize='small' />
                                      </Fab>
                                    </Tooltip>

                                    <Tooltip title='Nuit 02h-06h'>
                                      <Fab size='small' color={values.days[date.get('date') - 1].night_second_part ? 'primary' : 'default'} aria-label='add' sx={{ ml: -1 }} style={{ transform: 'scale(0.7)' }} onClick={() => { setFieldValue(`days[${date.get('date') - 1}].night_second_part`, !values.days[date.get('date') - 1].night_second_part, true) }}>
                                        <ModeNightIcon fontSize='small' />
                                      </Fab>
                                    </Tooltip>

                                    <Box sx={{ flexGrow: 1 }} />

                                    <Typography align='right' sx={{ color: 'text.secondary' }}>{date.format('DD').toString()}</Typography>
                                  </Box>

                                  <Box>
                                    <Box
                                      display='flex'
                                      justifyContent='space-between'
                                      alignItems='flex-start'
                                      sx={{ mt: 1 }}
                                    >
                                      <Typography variant='caption' align='left' sx={{ color: 'text.secondary' }}>AM</Typography>
                                      <Box sx={{ flexGrow: 1 }} />
                                      <Tooltip title={values.days[date.get('date') - 1].am_status}>
                                        {(() => {
                                          switch (values.days[date.get('date') - 1].am_status) {
                                            case 'Jour travaillé':
                                              return <CheckIcon sx={{ color: 'green', mx: 1 }} className={css`cursor: pointer`} onClick={() => { handleClickOpen(date.get('date') - 1, 'am') }} />
                                            case 'Télétravail':
                                              return <HomeIcon sx={{ color: 'green', mx: 1 }} className={css`cursor: pointer`} onClick={() => { handleClickOpen(date.get('date') - 1, 'am') }} />
                                            case 'Jour travaillé non facturé':
                                              return <WorkOff sx={{ color: 'blue', mx: 1 }} className={css`cursor: pointer`} onClick={() => { handleClickOpen(date.get('date') - 1, 'am') }} />;
                                            case 'Congé payé':
                                              return <BeachAccess sx={{ color: 'red', mx: 1 }} className={css`cursor: pointer`} onClick={() => { handleClickOpen(date.get('date') - 1, 'am') }} />;
                                            case 'RTT':
                                              return <Nightlife sx={{ color: 'red', mx: 1 }} className={css`cursor: pointer`} onClick={() => { handleClickOpen(date.get('date') - 1, 'am') }} />;
                                            case 'Congé maladie':
                                              return <Medication sx={{ color: 'red', mx: 1 }} className={css`cursor: pointer`} onClick={() => { handleClickOpen(date.get('date') - 1, 'am') }} />;
                                            case 'Congé maternité':
                                              return <ChildFriendly sx={{ color: 'red', mx: 1 }} className={css`cursor: pointer`} onClick={() => { handleClickOpen(date.get('date') - 1, 'am') }} />;
                                            case 'Congé paternité':
                                              return <ChildFriendly sx={{ color: 'red', mx: 1 }} className={css`cursor: pointer`} onClick={() => { handleClickOpen(date.get('date') - 1, 'am') }} />;
                                            case 'Évènement familial':
                                              return <FamilyRestroom sx={{ color: 'red', mx: 1 }} className={css`cursor: pointer`} onClick={() => { handleClickOpen(date.get('date') - 1, 'am') }} />;
                                            case 'Autre':
                                              return <AllInclusive sx={{ color: 'red', mx: 1 }} className={css`cursor: pointer`} onClick={() => { handleClickOpen(date.get('date') - 1, 'am') }} />;
                                            case 'Jour férié':
                                              return <Celebration sx={{ color: 'red', mx: 1 }} className={css`cursor: pointer`} onClick={() => { handleClickOpen(date.get('date') - 1, 'am') }} />;
                                            case 'Weekend':
                                              return <Chair sx={{ color: 'red', mx: 1 }} className={css`cursor: pointer`} onClick={() => { handleClickOpen(date.get('date') - 1, 'am') }} />;
                                            default:
                                              return <CloseIcon sx={{ color: 'red', mx: 1 }} className={css`cursor: pointer`} onClick={() => { handleClickOpen(date.get('date') - 1, 'am') }} />
                                          }
                                        })()}
                                      </Tooltip>
                                    </Box>

                                    <Divider sx={{ my: 1 }} />

                                    <Box
                                      display='flex'
                                      justifyContent='space-between'
                                      alignItems='flex-start'
                                    >
                                      <Typography variant='caption' align='left' sx={{ color: 'text.secondary' }}>PM</Typography>
                                      <Box sx={{ flexGrow: 1 }} />
                                      <Tooltip title={values.days[date.get('date') - 1].pm_status}>
                                        {(() => {
                                          switch (values.days[date.get('date') - 1].pm_status) {
                                            case 'Jour travaillé':
                                              return <CheckIcon sx={{ color: 'green', mx: 1 }} className={css`cursor: pointer`} onClick={() => { handleClickOpen(date.get('date') - 1, 'pm') }} />
                                            case 'Télétravail':
                                              return <HomeIcon sx={{ color: 'green', mx: 1 }} className={css`cursor: pointer`} onClick={() => { handleClickOpen(date.get('date') - 1, 'pm') }} />
                                            case 'Jour travaillé non facturé':
                                              return <WorkOff sx={{ color: 'blue', mx: 1 }} className={css`cursor: pointer`} onClick={() => { handleClickOpen(date.get('date') - 1, 'pm') }} />;
                                            case 'Congé payé':
                                              return <BeachAccess sx={{ color: 'red', mx: 1 }} className={css`cursor: pointer`} onClick={() => { handleClickOpen(date.get('date') - 1, 'pm') }} />;
                                            case 'RTT':
                                              return <Nightlife sx={{ color: 'red', mx: 1 }} className={css`cursor: pointer`} onClick={() => { handleClickOpen(date.get('date') - 1, 'pm') }} />;
                                            case 'Congé maladie':
                                              return <Medication sx={{ color: 'red', mx: 1 }} className={css`cursor: pointer`} onClick={() => { handleClickOpen(date.get('date') - 1, 'pm') }} />;
                                            case 'Congé maternité':
                                              return <ChildFriendly sx={{ color: 'red', mx: 1 }} className={css`cursor: pointer`} onClick={() => { handleClickOpen(date.get('date') - 1, 'pm') }} />;
                                            case 'Congé paternité':
                                              return <ChildFriendly sx={{ color: 'red', mx: 1 }} className={css`cursor: pointer`} onClick={() => { handleClickOpen(date.get('date') - 1, 'pm') }} />;
                                            case 'Évènement familial':
                                              return <FamilyRestroom sx={{ color: 'red', mx: 1 }} className={css`cursor: pointer`} onClick={() => { handleClickOpen(date.get('date') - 1, 'pm') }} />;
                                            case 'Autre':
                                              return <AllInclusive sx={{ color: 'red', mx: 1 }} className={css`cursor: pointer`} onClick={() => { handleClickOpen(date.get('date') - 1, 'pm') }} />;
                                            case 'Jour férié':
                                              return <Celebration sx={{ color: 'red', mx: 1 }} className={css`cursor: pointer`} onClick={() => { handleClickOpen(date.get('date') - 1, 'pm') }} />;
                                            case 'Weekend':
                                              return <Chair sx={{ color: 'red', mx: 1 }} className={css`cursor: pointer`} onClick={() => { handleClickOpen(date.get('date') - 1, 'pm') }} />;
                                            default:
                                              return <CloseIcon sx={{ color: 'red', mx: 1 }} className={css`cursor: pointer`} onClick={() => { handleClickOpen(date.get('date') - 1, 'pm') }} />
                                          }
                                        })()}
                                      </Tooltip>
                                    </Box>
                                  </Box>

                                </TableCellBordered>
                            )
                          })}
                        </TableRow>
                      ))}
                    </TableBody>
                  )}
                </FieldArray>
              </Table>
            </TableContainer>

            <Grid
              container
              direction='row'
              justifyContent='space-between'
              alignItems='center'
              sx={{ mt: 6 }}
            >

              <Grid item>
                {
                  loading ?
                    <CircularProgress />
                    :
                    <Button variant='contained' type='submit' endIcon={<SaveIcon />} disabled={loading}>
                      Sauvegarder mon RMA
                    </Button>
                }
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Container >
  );
}