import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useObservable } from 'micro-observables';
import { accountService, notificationsService } from '../services/Service';
import { ColorModeContext } from '../App';
import Navigation from './Navigation';
import logo from '../assets/xrays_logo_white.svg';
import AppBar from '@mui/material/AppBar';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import IconButton from '@mui/material/IconButton';
import LightModeIcon from '@mui/icons-material/LightMode';
import LogoutIcon from '@mui/icons-material/Logout';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Toolbar from '@mui/material/Toolbar';
import { css } from '@emotion/css';
import axios from 'axios';


export default function Header() {

  const notifications = useObservable(notificationsService.notifications);
  const [notificationNumber, setNotificationNumber] = useState(notifications.length);
  const navigate = useNavigate();

  const handleLogout = () => {
    accountService.logout();
    navigate('/login')
  };

  useEffect(() => {
    if (notifications.length == 0) {
      setNotificationNumber(parseInt(localStorage.getItem('notificationNumber') || '0'));
    } else {
      setNotificationNumber(notifications.length)
    }
    const interceptor = axios.interceptors.response.use(
      response => response,
      error => {
        if (error.response.status === 401) {
          accountService.logout();
          navigate('/login');
        }
        return Promise.reject(error);
      }
    );

    // Supprimer l'intercepteur lors du démontage du composant
    return () => {
      axios.interceptors.response.eject(interceptor);
    };
  }, [notifications, navigate]);

  const { mode, handleSwitchMode } = useContext(ColorModeContext);

  return (
    <Box sx={{ flexGrow: 1, mb: 6 }}>
      <AppBar position='fixed'>
        <Toolbar>
          <Navigation />

          <Box component='img' alt='XRAYS TRADING logo' src={logo} onClick={() => {navigate('/home')}} sx={{ height: '40px', width: '81px' }} className={css`cursor: pointer`} />

          <Box sx={{ flexGrow: 1 }} />

          <IconButton
            size='large'
            aria-label='notifications'
            color='inherit'
            onClick={() => {navigate('/home')}}
          >
            <Badge badgeContent={notificationNumber} color='error'>
              <NotificationsIcon onClick={() => {navigate('/home')}}/>
            </Badge>
          </IconButton>

          <IconButton
            size='large'
            aria-label={mode === 'dark' ? 'light theme' : 'dark theme'}
            color='inherit'
            onClick={handleSwitchMode}
          >
            {mode === 'dark' ? <LightModeIcon /> : <DarkModeIcon />}
          </IconButton>

          <IconButton
            size='large'
            aria-label='logout'
            color='inherit'
          >
            <Badge>
              <LogoutIcon onClick={handleLogout} />
            </Badge>
          </IconButton>
        </Toolbar>
      </AppBar>
    </Box>
  );
}