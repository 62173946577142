import React, { useEffect } from 'react';
import { holidaysService } from '../../services/Service';
import { MyTab } from '../../common/dto';
import TabSelector from '../../common/TabSelector';
import CreateHolidays from './CreateHolidays';
import ViewHolidays from './ViewHolidays';


export default function UserManagement() {

  const tabs: MyTab[] = [
    {label: "Créer une demande d'absence", content: <CreateHolidays />},
    {label: 'Mes absences', content: <ViewHolidays />},
  ]

  useEffect(() => {
    holidaysService.fetchUserHolidays();
    holidaysService.fetchFrenchHolidays();
  }, []);
  
  return (
    <TabSelector tabs={tabs} />
  );
}