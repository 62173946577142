
import React, { useState } from 'react'
import { Holiday } from '../../services/HolidaysService';
import { Calendar, momentLocalizer, SlotInfo } from 'react-big-calendar';
import CalendarToolbar from '../../common/CalendarToolbar';
import dayjs from 'dayjs';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment'
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import { css } from '@emotion/css';
import { grey } from '@mui/material/colors';


moment.updateLocale('en', {
  week: {
    dow: 1,
  },
  months: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Decembre'],
  weekdays: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
});

interface CalendarEvent {
  id: number;
  title: string;
  allDay: boolean;
  start: Date;
  end: Date;
  desc?: string;
}

const CustomEvent = (event: any) => {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <Dialog onClose={() => { setOpen(false) }} open={open}>
        <Container sx={{ my: 4, width: 'auto' }}>
          <Typography align='left' variant='h4' sx={{ mb: 4 }}>{event.title}</Typography>

          <Typography align='left' sx={{ mb: 2 }}>{event.event.desc}</Typography>
        </Container>
      </Dialog>

      <div title={event.title} onClick={() => { setOpen(true) }}>
        {event.title}
      </div>
    </div>
  )
}

interface Props {
  holidays: Holiday[];
}

export default function DesktopCalendar(props: Props) {

  const localizer = momentLocalizer(moment)
  const formats = {
    weekdayFormat: (date: any, culture: any, localizer: any) => localizer.format(date, 'dddd', culture),
  }
  const calendarEvents = Array<CalendarEvent>();

  props.holidays.forEach((holiday: Holiday, index: number) => calendarEvents.push(
    {
      id: index,
      title: holiday.creator_fullname + ' (' + holiday.holiday_type + ')',
      allDay: false,
      start: new Date(holiday.start_date),
      end: new Date(holiday.end_date),
      desc: holiday.holiday_type + ' - Débutant le ' + dayjs(holiday.start_date).format('DD/MM/YYYY') + (holiday.begin_at_midday ? " en début d'après-midi" : ' matin') + ' et terminant le ' + dayjs(holiday.end_date).format('DD/MM/YYYY') + (holiday.end_at_midday ? ' en fin de matinée' : ' soir') + ' (' + parseFloat(holiday.duration.toString()) + 'j)',
    }
  ));

  const [open, setOpen] = useState(false);
  const [selectedHolidays, setSelectedHolidays] = useState<Holiday[]>([]);

  const handleSelected = (slotInfo: SlotInfo) => {
    const start = dayjs(slotInfo.start);
    const end = dayjs(slotInfo.end).subtract(1, 'day');

    const holidays: Holiday[] = props.holidays.filter(holiday => (start.diff(holiday.start_date, 'day') >= 0 && start.diff(holiday.end_date, 'day') <= 0) || (end.diff(holiday.start_date, 'day') >= 0 && end.diff(holiday.end_date, 'day') <= 0) || (start.diff(holiday.start_date, 'day') <= 0 && end.diff(holiday.end_date, 'day') >= 0));

    if (holidays.length > 0) {
      setSelectedHolidays(holidays);
      setOpen(true);
    }
  }

  return (
    <div>
      <Dialog
        onClose={() => { setOpen(false) }}
        open={open}
      >
        <Container sx={{ my: 4, width: 'auto' }}>
          <Typography align='left' variant='h4' sx={{ mb: 4 }}>Vacances dans ce créneau</Typography>
          {selectedHolidays.map((holiday: Holiday, index: number) => (
            <div key={index}>
              <Typography align='left' sx={{ mb: 2 }}>
                {holiday.creator_fullname + ' (' + holiday.holiday_type + ')' + ' - Débutant le ' + dayjs(holiday.start_date).format('DD/MM/YYYY') + (holiday.begin_at_midday ? " en début d'après-midi" : ' matin') + ' et terminant le ' + dayjs(holiday.end_date).format('DD/MM/YYYY') + (holiday.end_at_midday ? ' en fin de matinée' : ' soir') + ' (' + parseFloat(holiday.duration.toString()) + 'j)'}
              </Typography>
            </div>
          ))}
        </Container>
      </Dialog>

      <Calendar
        events={calendarEvents}
        selectable
        onSelectSlot={(slotInfo: SlotInfo) => handleSelected(slotInfo)}
        showAllEvents={true}
        startAccessor='start'
        endAccessor='end'
        defaultDate={moment().toDate()}
        defaultView='month'
        localizer={localizer}
        formats={formats}
        style={{ height: '70vh' }}
        components={{
          toolbar: CalendarToolbar,
          event: CustomEvent
        }}
        views={['month']}
        popup={false}
        dayPropGetter={date => {
          const dayOfWeek = date.getDay();
          if (dayOfWeek === 0 || dayOfWeek === 6) {
            return {
              style: {
                backgroundColor: grey[500],
              },
            };
          } else {
            return {};
          }
        }}
        className={css`
          font-family: 'Roboto','Helvetica','Arial',sans-serif;
          .rbc-event, .rbc-day-slot .rbc-background-event {
            //darkred
            background-color: #ef2322;
          }
          .rbc-today {
            background-color: #e6baa9;
          }
          .rbc-show-more {
            background-color: rgba(255, 255, 255, 0);
            color: #ef2322;
          }
          .rbc-row-content-scroll-container {
            height: auto;
            overflow-y: visible;
          }
          .rbc-month-row {
            height: auto;
            overflow: visible;
          }
          .rbc-row-content-scrollable {
            height: auto;
            overflow-y: visible;
          }
          .rbc-month-view {
            height: auto;
            overflow-y: scroll;
          }
        `}
      />
    </div>
  )
}