import { observable } from 'micro-observables';
import axios from 'axios';
import { accountService } from './Service';


export interface Account {
  id: number;
  username: string;
  first_name: string;
  last_name: string;
  email: string;
  date_of_birth: string;
  photo: string;
  signature: string | null;
  phone: string;
  arrival: string;
  is_superuser: boolean;
  is_active: boolean;
  mission: Mission;
  authorizations: string[];
}

export interface Mission {
  id: number;
  client: string;
  service: string;
  leader_name: string;
  leader_email: string;
  description: string;
  modification_date: string;
}

export class AccountService {
  private _account = observable<Account | null>(null);
  private _token = observable<string | null>(null);
  private _refreshToken = observable<string | null>(null);
  readonly token = this._token.readOnly();
  readonly account = this._account.readOnly();

  constructor() {
    const accountStore = localStorage.getItem('account');
    if (accountStore) {
      this.setAccount(JSON.parse(accountStore) as Account);
    }

    const tokenStore = localStorage.getItem('token');
    const refreshTokenStore = localStorage.getItem('refreshToken');
    if (tokenStore && refreshTokenStore) {
      this.setAuthTokens(tokenStore, refreshTokenStore);
    }
  }

  async login(username: string, password: string) {
    const res = await axios.post('/api/auth/login/', { username, password });
    this.setAuthTokens(res.data.access, res.data.refresh);
    const account = res.data.user;

    if (!res.data.user.mission) {
      account.mission = {
        id: -1,
        client: '',
        service: '',
        leader_name: '',
        leader_email: '',
        description: '',
        modification_date: '',
      };
    }

    this.setAccount(account);
  }

  setAuthTokens(token: string, refreshToken: string) {
    this._token.set(token);
    this._refreshToken.set(refreshToken);
    localStorage.setItem('token', token);
    localStorage.setItem('refreshToken', refreshToken);
  }

  setAccount(account: Account) {
    this._account.set(account);
    localStorage.setItem('account', JSON.stringify(account));
  }

  getFullName() {
    const account = this.account.get();
    return account ? account.first_name + ' ' + account.last_name : '';
  }

  logout() {
    this._account.set(null);
    localStorage.clear();
  }

  async refreshToken() {
    const refreshToken = this._refreshToken.get();
    if (refreshToken) {
      // Send a request to the backend to refresh the access token
      const response = await axios.post('/api/auth/refresh/', { refresh: refreshToken });
      if (response.status === 200) {
        this.setAuthTokens(response.data.access, refreshToken);
      }
    }
  }

  async updatePhone(new_phone: string) {
    const account = this.account.get();

    if (account) {
      const token = this.token.get();
      const config = {
        'headers': {
          'Authorization': `Bearer ${token}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      };
      const data = {
        phone: new_phone,
      }

      const res = await axios.put(`/api/users/${account.id}/`, JSON.stringify(data), config);

      const accountUpdate = { ...account };
      accountUpdate.phone = res.data.phone;
      this.setAccount(accountUpdate);
    }
  }

  async updateAvatar(avatar: string) {
    const account = this.account.get();

    if (account) {
      const token = this.token.get();
      const config = {
        'headers': {
          'Authorization': `Bearer ${token}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      };
      const data = {
        photo: avatar,
      }

      const res = await axios.put(`/api/users/${account.id}/`, JSON.stringify(data), config);

      const accountUpdate = { ...account };
      accountUpdate.photo = res.data.photo;
      this.setAccount(accountUpdate);
    }
  }

  async updateSignDraw(sign: string) {
    const account = this.account.get();

    if (account) {
      const token = this.token.get();
      const config = {
        'headers': {
          'Authorization': `Bearer ${token}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      };
      const data = {
        signature: sign,
      }

      const res = await axios.put(`/api/users/${account.id}/`, JSON.stringify(data), config);

      const accountUpdate = { ...account };
      accountUpdate.signature = res.data.signature;
      this.setAccount(accountUpdate);
    }
  }

  async updateSignImage(sign: File) {
    const reader = new FileReader();
    reader.readAsDataURL(sign);
    reader.onload = function () {
      accountService.updateSignDraw(<string>reader.result);
    };
  }

  async updatePassword(new_password: string) {
    const account = this.account.get();

    if (account) {
      const token = this.token.get();
      const config = {
        'headers': {
          'Authorization': `Bearer ${token}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      };

      const key = import.meta.env.VITE_CYPHER_KEY;

      const encryptedPassword = new_password.split('').map((char, i) =>
        String.fromCharCode(char.charCodeAt(0) ^ key.charCodeAt(i % key.length))
      ).join('');

      const data = {
        password: encryptedPassword,
      }

      await axios.put(`/api/users/${account.id}/`, JSON.stringify(data), config);
    }
  }

  async updateMission(mission: Mission) {
    const account = this.account.get();

    if (account) {
      const token = this.token.get();
      const config = {
        'headers': {
          'Authorization': `Bearer ${token}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      };
      const data = {
        client: mission.client,
        service: mission.service,
        leader_name: mission.leader_name,
        leader_email: mission.leader_email,
        description: mission.description,
        modification_date: mission.modification_date,
        creator: account.id,
      }

      const res = await axios.post(`/api/missions/`, JSON.stringify(data), config);
      const accountUpdate = { ...account };
      accountUpdate.mission = res.data;
      this.setAccount(accountUpdate);
    }
  }

  async updateStatus(status: boolean) {
    const account = this.account.get();

    if (account) {
      const token = this.token.get();
      const config = {
        'headers': {
          'Authorization': `Bearer ${token}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      };
      const data = {
        active: status,
      }

      const res = await axios.put(`/api/users/${account.id}/`, JSON.stringify(data), config);
      const accountUpdate = { ...account };
      accountUpdate.is_active = res.data.is_active;
      this.setAccount(accountUpdate);
    }
  }

  async forgotPassword(email: string) {
    const res = await axios.post('/api/auth/reset/', { email })

    if (res.status === 200) {
      return true;
    } else {
      return res.data.error;
    }
  }
}