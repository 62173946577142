import React, { useState } from 'react';
import { eventsService } from '../../services/Service';
import { Event } from '../../services/EventsService';
import { AlertMessage } from '../../common/dto';
import { formatError } from '../../common/formatError';
import DeleteConfirm from '../../common/DeleteConfirm';
import { useFormik } from 'formik';
import * as yup from 'yup'
import dayjs from 'dayjs';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Alert from '@mui/material/Alert';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CheckIcon from '@mui/icons-material/Check';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import Container from '@mui/material/Container';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Fab from '@mui/material/Fab';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { css } from '@emotion/css';


interface Props {
  event: Event;
  setNotification: (msg: AlertMessage) => void;
  close: () => void;
}

export default function EventFormular(props: Props) {

  const [eventCache] = useState<Event>(props.event);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<AlertMessage>({ message: '', severity: undefined });

  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [imageUrl, setImageUrl] = useState<string>(props.event.image);

  const transformImageToString = (image: File) => {
    const reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onload = function () {
      formik.setFieldValue('image', reader.result);
    };
  }

  const formik = useFormik({
    initialValues: {
      name: eventCache.name,
      description: eventCache.description,
      image: eventCache.image,
      address: eventCache.address,
      start_date: dayjs(eventCache.start_date, 'YYYY-MM-DD HH:mm').format('DD/MM/YYYY HH:mm'),
      end_date: dayjs(eventCache.end_date, 'YYYY-MM-DD HH:mm').format('DD/MM/YYYY HH:mm'),
      place: eventCache.place,
      deletedParticipants: Array<number>(),
      deleted: false,
    },
    validationSchema: yup.object({
      name: yup
        .string()
        .trim()
        .required("Nom de l'évènement requis"),
      description: yup
        .string()
        .trim()
        .required('Description requise'),
      address: yup
        .string()
        .trim()
        .required('Adresse requise'),
      start_date: yup
        .string()
        .trim()
        .test(
          'Date valide',
          'Date non valide',
          (value) => dayjs(value, 'DD/MM/YYYY HH:mm').isValid()
        )
        .test(
          'Date valide',
          "Dates de début et de fin d'évènement non valides",
          (value, context) => dayjs(context.parent.end_date, 'DD/MM/YYYY HH:mm').diff(dayjs(value, 'DD/MM/YYYY HH:mm'), 'minutes') > 0
        )
        .required('Date de début requise'),
      end_date: yup
        .string()
        .trim()
        .test(
          'Date valide',
          'Date non valide',
          (value) => dayjs(value, 'DD/MM/YYYY HH:mm').isValid()
        )
        .test(
          'Date future',
          'Date déjà passée',
          (value) => dayjs(value, 'DD/MM/YYYY HH:mm').diff(dayjs(), 'minutes') > 0
        )
        .required('Date de fin requise'),
      place: yup
        .number()
        .required()
        .default(-1)
        .test(
          'Nombre de place suffisant',
          'Pas assez de places par rapport aux participants déjà inscrits',
          function (value) {
            return value === -1 || value >= props.event.participants.length - this.parent.deletedParticipants.length;
          }
        ),
      deletedParticipants: yup
        .array(),
      deleted: yup
        .boolean()
    }),
    onSubmit: (values) => {
      setLoading(true);

      if (values.deleted) {
        handleDelete();
      } else {
        const newParticipants: number[] = [];
        const newParticipantFullnames: string[] = [];
        props.event.participants.forEach((participant, index) => !values.deletedParticipants.includes(index) && newParticipants.push(participant) && newParticipantFullnames.push(props.event.participant_fullnames[index]));

        const event: Event = {
          id: props.event.id,
          name: values.name,
          creator: props.event.creator,
          start_date: dayjs(values.start_date, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm').toString(),
          end_date: dayjs(values.end_date, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm').toString(),
          place: values.place,
          description: values.description,
          image: values.image,
          address: values.address,
          participants: newParticipants.length > 0 ? newParticipants : [],
          participant_fullnames: newParticipantFullnames.length > 0 ? newParticipantFullnames : [],
        }

        handleDemand(event);
      }
    },
  });

  const handleDelete = async () => {
    try {
      await eventsService.removeEvent(props.event);
      props.setNotification({ message: "L'évènement a bien été annulé.", severity: 'success' });
      props.close();
    } catch (err: any) {
      setMessage({ message: formatError(err), severity: 'error' });
    } finally {
      setLoading(false);
    }
  }

  const handleDemand = async (event: Event) => {
    try {
      await eventsService.updateEvent(event);
      props.setNotification({ message: "L'évènement a bien été modifié.", severity: 'success' });
      props.close();
    } catch (err: any) {
      setMessage({ message: formatError(err), severity: 'error' });
    } finally {
      setLoading(false);
    }
  }

  return (
    <Container sx={{ my: 4, width: 'auto' }}>
      {message.message.length > 0 &&
        <Alert variant='filled' severity={message.severity} sx={{ mb: 6 }}>
          {message.message}
        </Alert>
      }

      <form onSubmit={formik.handleSubmit}>
        <Typography align='left' variant='h4' sx={{ mb: 6 }}>Modifier un évènement</Typography>

        <Typography align='left' sx={{ mt: 4, mb: 2 }}>Nom de l&apos;évènement</Typography>
        <TextField
          name='name'
          label='nom'
          size='small'
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
        />

        <Typography align='left' sx={{ mt: 4, mb: 2 }}>Description</Typography>
        <TextField
          fullWidth
          label='description'
          name='description'
          size='small'
          multiline
          minRows={2}
          maxRows={10}
          value={formik.values.description}
          onChange={formik.handleChange}
          error={formik.touched.description && Boolean(formik.errors.description)}
          helperText={formik.touched.description && formik.errors.description}
        />

        <Typography align='left' sx={{ mt: 4, mb: 2 }}>Banière d&apos;évènement</Typography>
        <input
          type='file'
          accept='image/png, image/jpeg, image/jpg'
          multiple={false}
          id='select-image'
          style={{ display: 'none' }}
          onChange={(event) => {
            const files = event.target.files;
            if (files && files.length > 0) {
              transformImageToString(files[0]);
              setSelectedImage(files[0]);
              setImageUrl(URL.createObjectURL(files[0]));
            } else {
              formik.setFieldValue('image', '');
              setSelectedImage(null);
              setImageUrl('');
            }
          }}
        />
        <label htmlFor='select-image'>
          <Tooltip title="Upload une image de banière pour l'évènement, visible dans sa description">
            <Button variant='contained' component='span'>
              Upload Image
            </Button>
          </Tooltip>
        </label>
        {imageUrl !== '' && imageUrl !== 'data:image/png;base64,R0lGODlhAQABAAAAACwAAAAAAQABAAA=' && (
          <Box sx={{ mt: 4 }} textAlign='center'>
            <img src={imageUrl} alt={selectedImage ? selectedImage.name : ''} width='100%' />
          </Box>
        )}

        <Typography align='left' sx={{ mt: 4, mb: 2 }}>Adresse</Typography>
        <TextField
          fullWidth
          label='adresse'
          name='address'
          size='small'
          multiline
          minRows={1}
          maxRows={5}
          value={formik.values.address}
          onChange={formik.handleChange}
          error={formik.touched.address && Boolean(formik.errors.address)}
          helperText={formik.touched.address && formik.errors.address}
        />

        <Typography align='left' sx={{ mt: 4, mb: 2 }}>Début de l&apos;évènement</Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            inputFormat='DD/MM/YYYY HH:mm'
            value={dayjs(formik.values.start_date, 'DD/MM/YYYY HH:mm')}
            onChange={(value) => { formik.setFieldValue('start_date', dayjs(value).format('DD/MM/YYYY HH:mm'), true) }}
            renderInput={(params) =>
              <TextField
                name='start_date'
                label='début'
                size='small'
                error={formik.touched.start_date && Boolean(formik.errors.start_date)}
                helperText={formik.errors.start_date?.toString()}
                {...params} />}
          />
        </LocalizationProvider>

        <Typography align='left' sx={{ mt: 4, mb: 2 }}>Fin de l&apos;évènement</Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            inputFormat='DD/MM/YYYY HH:mm'
            value={dayjs(formik.values.end_date, 'DD/MM/YYYY HH:mm')}
            onChange={(value) => { formik.setFieldValue('end_date', dayjs(value).format('DD/MM/YYYY HH:mm'), true) }}
            renderInput={(params) =>
              <TextField
                name='end_date'
                label='fin'
                size='small'
                error={formik.touched.end_date && Boolean(formik.errors.end_date)}
                helperText={formik.errors.end_date?.toString()}
                {...params} />}
          />
        </LocalizationProvider>

        <Typography align='left' sx={{ mt: 4, mb: 2 }}>Nombre de place</Typography>

        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          sx={{ mt: 4, pr: 5 }}
        >
          <TextField
            label='place'
            name='place'
            size='small'
            type='number'
            value={formik.values.place !== -1 ? formik.values.place : ''}
            onChange={(value) => formik.setFieldValue('place', value.target.value.replace(/\D/g, ''), true)}
            error={formik.touched.place && Boolean(formik.errors.place)}
            helperText={formik.touched.place && formik.errors.place}
          />
          <Tooltip title='Pas de limite de place'>
            <Fab size='small' color={formik.values.place !== -1 ? 'default' : 'primary'} onClick={() => { formik.setFieldValue('place', -1, true) }}>
              <AllInclusiveIcon />
            </Fab>
          </Tooltip>
        </Stack>

        {
          props.event.participants.length > 0 ?
            <Accordion sx={{ my: 4 }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                <Typography>Participants</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {props.event.participant_fullnames.map((participant: string, index: number) => (
                  <Stack
                    direction='row'
                    justifyContent='space-between'
                    alignItems='center'
                    sx={{ mb: 0.5, px: 1 }}
                    key={index}
                  >
                    {
                      !formik.values.deletedParticipants.includes(index) ?
                        <Typography align='left' >{participant}</Typography>
                        :
                        <Typography align='left' className={css`text-decoration:line-through;`}>{participant}</Typography>
                    }

                    <Fab size='small' color='primary' style={{ transform: 'scale(0.9)' }} sx={{ mr: 1 }}>
                      {
                        formik.values.deletedParticipants.includes(index) ?
                          <CloseIcon onClick={() => {
                            formik.setFieldValue('deletedParticipants', formik.values.deletedParticipants.filter(val => val !== index))
                          }} />
                          :
                          <CheckIcon onClick={() => {
                            formik.setFieldValue('deletedParticipants', [...formik.values.deletedParticipants, index])
                          }} />
                      }
                    </Fab>
                  </Stack>
                ))}
              </AccordionDetails>
            </Accordion>
            :
            <div>
              <Typography align='left' sx={{ mt: 4, mb: 2 }}>Participants</Typography>
              <Typography align='left' sx={{ color: 'text.secondary' }}>
                Aucun participant pour le moment
              </Typography>
            </div>
        }

        {
          loading ?
            <Box
              display='flex'
              justifyContent='center'
              alignItems='center'
              sx={{ mt: 4 }}
            >
              <CircularProgress />
            </Box>
            :
            <Stack
              direction='row'
              justifyContent='space-between'
              alignItems='center'
              sx={{ mt: 4 }}
            >
              <Button variant='contained' type='submit' endIcon={<EditIcon />} disabled={loading}>
                Modifier
              </Button>
              <DeleteConfirm message={"Êtes vous sur de vouloir supprimer l'évènement?"} disabled={loading} action={() => { formik.setFieldValue(`deleted`, true); formik.submitForm() }} />
            </Stack>
        }
      </form>
    </Container >
  );
}