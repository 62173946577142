import { observable } from 'micro-observables';
import { AccountService } from './AccountService';
import axios from 'axios';


export type Severity = 'error' | 'success' | 'info' | 'warning' | undefined;

export interface Notification {
  id: number;
  creation_date: string;
  title: string;
  description: string;
  severity: Severity;
}

export class NotificationsService {
  private _notifications = observable<Notification[]>([]);
  readonly notifications = this._notifications.readOnly();

  constructor(private accountService: AccountService) { }

  async fetchNotifications() {
    const account = this.accountService.account.get();

    if (account) {
      const token = this.accountService.token.get();
      const config = {
        'headers': {
          'Authorization': `Bearer ${token}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      };
      const res = await axios.get(`/api/notifications/${account.id}/`, config);
      this._notifications.set(res.data);
      localStorage.setItem('notificationNumber', this.notifications.get().length.toString());
    }
  }

  async removeNotification(notification: Notification) {
    const account = this.accountService.account.get();
    
    if (account) {
      const token = this.accountService.token.get();
      const config = {
        'headers': {
          'Authorization': `Bearer ${token}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      };
      
      await axios.put(`/api/notifications/${account.id}/${notification.id}/`, '', config);

      this._notifications.update(notifications => notifications.filter(function (n: Notification) {
        return n !== notification
      }))
      localStorage.setItem('notificationNumber', this.notifications.get().length.toString());
    }
  }
}