import React, { useState, useEffect } from 'react';
import { usersService } from '../../services/Service';
import { User, Mission } from '../../services/UsersService';
import { websiteStructure } from '../../common/WebsiteStructure';
import { Group, Page, AlertMessage } from '../../common/dto';
import { CustomSwitch } from '../../common/CustomSwitch';
import { formatError } from '../../common/formatError';
import { useFormik } from 'formik';
import * as yup from 'yup'
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Paper from '@mui/material/Paper';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { green } from '@mui/material/colors';


export default function AddUser() {

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<AlertMessage>({ message: '', severity: undefined });

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [message])

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
      first_name: '',
      last_name: '',
      date_of_birth: dayjs().format('DD/MM/YYYY'),
      email: '',
      phone: '',
      client: '',
      service: '',
      leader_name: '',
      leader_email: '',
      description: '',
      authorizations: Array<string>(),
      is_active: true
    },
    validationSchema: yup.object({
      username: yup
        .string()
        .trim()
        .required('Username requis'),
      password: yup
        .string()
        .trim()
        .required('Password requis')
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])(?=.{8,})/,
          'Mot de passe avec au moins 8 caractères, une majuscule, une minuscule, un chiffre et un caractère spécial.'
        ),
      first_name: yup
        .string()
        .trim()
        .required('Prénom requis'),
      last_name: yup
        .string()
        .trim()
        .required('Nom requis'),
      date_of_birth: yup
        .string()
        .trim()
        .test(
          'Date valide',
          'Date non valide',
          (value) => dayjs(value, 'DD/MM/YYYY').isValid()
        )
        .required("Date d'anniversaire requise"),
      email: yup
        .string()
        .trim()
        .required('Email requis'),
      phone: yup
        .string()
        .trim()
        .matches(/^\+?1?\d{9,15}$/, "Phone number must be entered in the format: '+999999999'. Up to 15 digits allowed."),
    }),
    onSubmit: (values, { resetForm }) => {
      setLoading(true);

      const mission: Mission = {
        id: 1,
        client: values.client,
        service: values.service,
        leader_name: values.leader_name,
        leader_email: values.leader_email,
        description: values.description,
        modification_date: dayjs().format('YYYY-MM-DD').toString(),
        creator: 1,
      }

      const isMissionNull = values.client == '' && values.service == '' && values.leader_name == '' && values.leader_email == '' && values.description == '';

      const user: User = {
        id: 1,
        username: values.username,
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        date_of_birth: dayjs(values.date_of_birth, 'DD/MM/YYYY').format('YYYY-MM-DD').toString(),
        phone: values.phone,
        arrival: dayjs().format('YYYY-MM-DD').toString(),
        mission: isMissionNull ? null : mission,
        authorizations: values.authorizations,
        is_active: values.is_active
      }

      handleDemand(user, values.password, mission, resetForm);
    },
  });

  const handleDemand = async (user: User, password: string, mission: Mission, resetForm: () => void) => {
    try {
      await usersService.addUser(user, password);
      setMessage({ message: 'Nouvel utilisateur enregistré.', severity: 'success' });
      resetForm();
    } catch (err: any) {
      setMessage({ message: formatError(err), severity: 'error' });
    } finally {
      setLoading(false);
    }
  }

  return (
    <Container>
      {message.message.length > 0 &&
        <Alert variant='filled' severity={message.severity} sx={{ mb: 6 }}>
          {message.message}
        </Alert>
      }

      <form onSubmit={formik.handleSubmit}>

        <Typography align='left' variant='h4' sx={{ mt: 4, mb: 6 }}>Ajout d&apos;un nouvel utilisateur</Typography>

        <Paper elevation={3} sx={{ my: 2, py: 2 }}>
          <Container>
            <Grid
              container
              direction='row'
              justifyContent='flex-start'
              alignItems='center'
              spacing={1}
            >
              <Grid item>
                <Typography variant='h5'>Information du profil</Typography>
              </Grid>

              <Box sx={{ width: '100%' }} />

              <Grid item>
                <Box
                  display='column'
                  justifyContent='flex-start'
                  alignItems='flex-start'
                >
                  <Typography align='left' sx={{ my: 2 }}>Username *</Typography>
                  <TextField
                    name='username'
                    label='username'
                    id='username'
                    autoComplete='username'
                    size='small'
                    value={formik.values.username}
                    onChange={formik.handleChange}
                    error={formik.touched.username && Boolean(formik.errors.username)}
                    helperText={formik.touched.username && formik.errors.username}
                  />
                </Box>
              </Grid>

              <Grid item sx={{ flexGrow: 0.3 }} />

              <Grid item>
                <Box
                  display='column'
                  justifyContent='flex-start'
                  alignItems='flex-start'
                >
                  <Typography align='left' sx={{ my: 2 }}>Password *</Typography>
                  <TextField
                    type='password'
                    name='password'
                    autoComplete='off'
                    label='password'
                    size='small'
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={formik.touched.password && Boolean(formik.errors.password)}
                    helperText={formik.touched.password && formik.errors.password}
                  />
                </Box>
              </Grid>

              <Box sx={{ width: '100%' }} />

              <Grid item>
                <Box
                  display='column'
                  justifyContent='flex-start'
                  alignItems='flex-start'
                >
                  <Typography align='left' sx={{ my: 2 }}>Nom *</Typography>
                  <TextField
                    name='last_name'
                    label='nom'
                    id='last_name'
                    autoComplete='family-name'
                    size='small'
                    value={formik.values.last_name}
                    onChange={formik.handleChange}
                    error={formik.touched.last_name && Boolean(formik.errors.last_name)}
                    helperText={formik.touched.last_name && formik.errors.last_name}
                  />
                </Box>
              </Grid>

              <Grid item sx={{ flexGrow: 0.3 }} />

              <Grid item>
                <Box
                  display='column'
                  justifyContent='flex-start'
                  alignItems='flex-start'
                >
                  <Typography align='left' sx={{ my: 2 }}>Prénom *</Typography>
                  <TextField
                    name='first_name'
                    label='prénom'
                    id='first_name'
                    autoComplete='first_name'
                    size='small'
                    value={formik.values.first_name}
                    onChange={formik.handleChange}
                    error={formik.touched.first_name && Boolean(formik.errors.first_name)}
                    helperText={formik.touched.first_name && formik.errors.first_name}
                  />
                </Box>
              </Grid>

              <Box sx={{ width: '100%' }} />

              <Grid item>
                <Box
                  display='column'
                  justifyContent='flex-start'
                  alignItems='flex-start'
                >
                  <Typography align='left' sx={{ my: 2 }}>Date de naissance *</Typography>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      inputFormat='DD/MM/YYYY'
                      value={dayjs(formik.values.date_of_birth, 'DD/MM/YYYY')}
                      onChange={(value) => {
                        const newDate = dayjs(value).format('DD/MM/YYYY');
                        formik.setFieldValue('date_of_birth', newDate, true)
                      }}
                      renderInput={(params) =>
                        <TextField
                          name='birthday'
                          label='date de naissance'
                          size='small'
                          error={formik.touched.date_of_birth && Boolean(formik.errors.date_of_birth)}
                          helperText={formik.errors.date_of_birth?.toString()}
                          {...params} />}
                    />
                  </LocalizationProvider>
                </Box>
              </Grid>

              <Box sx={{ width: '100%' }} />

              <Grid item>
                <Box
                  display='column'
                  justifyContent='flex-start'
                  alignItems='flex-start'
                >
                  <Typography align='left' sx={{ my: 2 }}>Adresse e-mail *</Typography>
                  <TextField
                    label='Adresse e-mail'
                    name='email'
                    id='email'
                    autoComplete='email'
                    size='small'
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                </Box>
              </Grid>

              <Box sx={{ width: '100%' }} />

              <Grid item>
                <Box
                  display='column'
                  justifyContent='flex-start'
                  alignItems='flex-start'
                >
                  <Typography align='left' sx={{ my: 2 }}>Numéro de téléphone</Typography>
                  <TextField
                    label='numéro'
                    name='phone'
                    id='phone'
                    autoComplete='phone'
                    size='small'
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                    error={formik.touched.phone && Boolean(formik.errors.phone)}
                    helperText={formik.touched.phone && formik.errors.phone}
                  />
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Paper>

        <Paper elevation={3} sx={{ my: 2, py: 2 }}>
          <Container>
            <Grid
              container
              direction='row'
              justifyContent='flex-start'
              alignItems='center'
              spacing={1}
            >
              <Grid item>
                <Typography variant='h5'>Information sur la mission</Typography>
                <Typography align='left' variant='body2' sx={{ color: 'text.secondary' }}>(facultatif)</Typography>
              </Grid>

              <Box sx={{ width: '100%' }} />

              <Grid item>
                <Box
                  display='column'
                  justifyContent='flex-start'
                  alignItems='flex-start'
                >
                  <Typography align='left' sx={{ my: 2 }}>Client</Typography>
                  <TextField
                    label='client'
                    name='client'
                    id='client'
                    size='small'
                    value={formik.values.client}
                    onChange={formik.handleChange}
                    error={formik.touched.client && Boolean(formik.errors.client)}
                    helperText={formik.touched.client && formik.errors.client}
                  />
                </Box>
              </Grid>

              <Grid item sx={{ flexGrow: 0.3 }} />

              <Grid item>
                <Box
                  display='column'
                  justifyContent='flex-start'
                  alignItems='flex-start'
                >
                  <Typography align='left' sx={{ my: 2 }}>Service</Typography>
                  <TextField
                    label='service'
                    name='service'
                    id='service'
                    size='small'
                    value={formik.values.service}
                    onChange={formik.handleChange}
                    error={formik.touched.service && Boolean(formik.errors.service)}
                    helperText={formik.touched.service && formik.errors.service}
                  />
                </Box>
              </Grid>

              <Box sx={{ width: '100%' }} />

              <Grid item>
                <Box
                  display='column'
                  justifyContent='flex-start'
                  alignItems='flex-start'
                >
                  <Typography align='left' sx={{ my: 2 }}>Responsable</Typography>
                  <TextField
                    label='responsable'
                    name='leader_name'
                    id='leader_name'
                    size='small'
                    value={formik.values.leader_name}
                    onChange={formik.handleChange}
                    error={formik.touched.leader_name && Boolean(formik.errors.leader_name)}
                    helperText={formik.touched.leader_name && formik.errors.leader_name}
                  />
                </Box>
              </Grid>

              <Box sx={{ width: '100%' }} />

              <Grid item>
                <Box
                  display='column'
                  justifyContent='flex-start'
                  alignItems='flex-start'
                >
                  <Typography align='left' sx={{ my: 2 }}>E-mail du responsable</Typography>
                  <TextField
                    label='email'
                    name='leader_email'
                    id='leader_email'
                    size='small'
                    value={formik.values.leader_email}
                    onChange={formik.handleChange}
                    error={formik.touched.leader_email && Boolean(formik.errors.leader_email)}
                    helperText={formik.touched.leader_email && formik.errors.leader_email}
                  />
                </Box>
              </Grid>

              <Box sx={{ width: '100%' }} />

              <Grid item>
                <Box
                  display='column'
                  justifyContent='flex-start'
                  alignItems='flex-start'
                >
                  <Typography align='left' sx={{ my: 2 }}>Mission</Typography>
                  <TextField
                    fullWidth
                    label='description'
                    name='description'
                    id='description'
                    size='small'
                    multiline
                    minRows={3}
                    maxRows={10}
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    error={formik.touched.description && Boolean(formik.errors.description)}
                    helperText={formik.touched.description && formik.errors.description}
                  />
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Paper>


        <Paper elevation={3} sx={{ my: 2, py: 2 }}>
          <Container>
            <Stack
              direction='column'
              justifyContent='flex-start'
              alignItems='flex-start'
              spacing={2}
            >
              <Typography variant='h5'>Droits d&apos;administration</Typography>

              {websiteStructure.groups.map((group: Group) => (
                group.pages.map((page: Page) => (
                  page.requiredAuthorization &&
                  <FormGroup key={page.name}>
                    <FormControlLabel
                      control={<CustomSwitch switchcolor={green[600]} sx={{ m: 1 }} checked={formik.values.authorizations.includes(page.requiredAuthorization)} onChange={() => {
                        page.requiredAuthorization && formik.values.authorizations.includes(page.requiredAuthorization) ?
                          formik.setFieldValue('authorizations', formik.values.authorizations.filter(val => val !== page.requiredAuthorization))
                          :
                          formik.setFieldValue('authorizations', [...formik.values.authorizations, page.requiredAuthorization])
                      }}
                      />}
                      label={page.name}
                    />
                  </FormGroup>
                ))
              ))
              }

              {websiteStructure.otherPages.map((page: Page) => (
                page.requiredAuthorization &&
                <FormGroup key={page.name}>
                  <FormControlLabel
                    control={<CustomSwitch switchcolor={green[600]} sx={{ m: 1 }} checked={formik.values.authorizations.includes(page.requiredAuthorization)} onChange={() => {
                      page.requiredAuthorization && formik.values.authorizations.includes(page.requiredAuthorization) ?
                        formik.setFieldValue('authorizations', formik.values.authorizations.filter(val => val !== page.requiredAuthorization))
                        :
                        formik.setFieldValue('authorizations', [...formik.values.authorizations, page.requiredAuthorization])
                    }}
                    />}
                    label={page.name}
                  />
                </FormGroup>
              ))
              }
            </Stack>
          </Container>
        </Paper>

        <Box sx={{ mt: 6 }}>
          {
            loading ?
              <CircularProgress />
              :
              <Button variant='contained' type='submit' endIcon={<PersonAddIcon />} disabled={loading}>
                Ajouter
              </Button>
          }
        </Box>
      </form>
    </Container >
  );
}