import React, { useState, useEffect } from 'react';
import { useObservable, useMemoizedObservable } from 'micro-observables';
import { eventsService, holidaysService } from '../../services/Service';
import { Event } from '../../services/EventsService';
import { CustomSwitch } from '../../common/CustomSwitch';
import DesktopCalendar from './DesktopCalendar';
import dayjs from 'dayjs';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import { green } from '@mui/material/colors';
import { css } from '@emotion/css';


export default function Events() {

  const events = useObservable(eventsService.events);
  const holidays = useObservable(holidaysService.getHoldaysValidated());
  const frenchHolidays = useObservable(holidaysService.frenchHolidays);
  const futureEvents = useMemoizedObservable(() => eventsService.getFutureEvents(), []);

  const [open, setOpen] = useState(false);
  const [eventIndex, setEventIndex] = useState(-1);

  useEffect(() => {
    eventsService.fetchEvents();
    holidaysService.fetchUserHolidays();
    holidaysService.fetchFrenchHolidays();
  }, []);

  const handleOpen = (index: number) => {
    setEventIndex(index);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCheck = (e: React.ChangeEvent<HTMLInputElement>, event: Event) => {
    eventsService.subscribeToEvent(event.id);
  };

  return (
    <div>
      <Dialog onClose={handleClose} open={open}>
        {
          eventIndex !== -1 ?
            <Container sx={{ my: 4, width: 'auto' }}>
              {
                events[eventIndex].image !== '' && events[eventIndex].image !== 'data:image/png;base64,R0lGODlhAQABAAAAACwAAAAAAQABAAA=' &&
                  <img src={events[eventIndex].image} alt='Preview' width='100%' />
              }
              <Typography align='left' variant='h4' sx={{ mb: 4 }}>{events[eventIndex].description}</Typography>
              <Typography align='left' sx={{ mb: 2 }}>{`Du ${dayjs(events[eventIndex].start_date).format('DD/MM/YYYY')} à ${dayjs(events[eventIndex].start_date).format('HH:mm')} au ${dayjs(events[eventIndex].end_date).format('DD/MM/YYYY')} à ${dayjs(events[eventIndex].end_date).format('HH:mm')}`}</Typography>
              <Typography align='left' sx={{ mb: 2 }}>{`RDV ${events[eventIndex].address}.`}</Typography>
              <FormGroup>
                <FormControlLabel
                  control={<CustomSwitch switchcolor={green[600]} sx={{ m: 1 }} disabled={!eventsService.isParticipated(events[eventIndex]) && events[eventIndex].place !== -1 && events[eventIndex].place - events[eventIndex].participants.length <= 0} checked={eventsService.isParticipated(events[eventIndex])} onChange={(e: React.ChangeEvent<HTMLInputElement>) => { handleCheck(e, events[eventIndex]) }} />}
                  label={eventsService.isParticipated(events[eventIndex]) ? 'Je participe' : 'Je ne participe pas'}
                />
              </FormGroup>
            </Container>
            :
            <div />
        }
      </Dialog>

      <DesktopCalendar events={events} holidays={holidays} frenchHolidays={frenchHolidays} />

      <Typography align='left' variant='h4' sx={{ my: 4 }}>Évènements à venir</Typography>

      {
        futureEvents.length == 0 ?
          <Typography align='left' variant='h5' sx={{ color: 'text.secondary', my: 2 }}>Aucun évènement à venir.</Typography>
          :
          <Stack sx={{ width: '100%', my: 4 }} spacing={2}>
            {
              futureEvents.map((event: Event, index: number) => (
                <Alert
                  key={index}
                  className={css`text-align: start;`}
                  severity={eventsService.isParticipated(event) ? 'success' : (event.place !== -1 && event.place - event.participants.length > 0 ? 'info' : 'error')}
                  action={
                    <IconButton
                      aria-label='zoom'
                      color='inherit'
                      size='large'
                      onClick={() => { handleOpen(index) }}
                    >
                      <ZoomInIcon fontSize='inherit' />
                    </IconButton>
                  }
                >
                  <AlertTitle>{event.name}</AlertTitle>
                  {event.place !== -1 ? `${event.description}  -  ${event.place - event.participants.length} places restantes` : 'Pas de limite de place'}
                </Alert>
              ))
            }
          </Stack>
      }
    </div >
  );
}