import React from 'react';
import Footer from './Footer';
import Header from './Header';
import Troll from './Troll';
import Box from '@mui/material/Box';
import { css } from '@emotion/css';


interface Props {
  content: JSX.Element;
}

export default function PageStructure(props: Props) {

  return (
    <div>
      <div className={css`position: relative; min-height: 86vh;`}>
        <div className='App'>
          <Header />
        </div>

        <div className={css`padding-bottom: 200px;`}>
          <Box sx={{ my: 8 }}>
            <Troll />
            {props.content}
          </Box>
        </div>

        <div className={css`position: absolute; bottom: 0; width: 100%; height: 200px;`}>
          <Footer />
        </div>
      </div>
    </div>
  );
}