import React, { useState } from 'react';
import { useObservable } from 'micro-observables';
import { galleryService } from '../../services/Service';
import { Album } from '../../services/GalleryService';
import { AlertMessage } from '../../common/dto';
import { formatError } from '../../common/formatError';
import dayjs from 'dayjs';
import AlbumFormular from './AlbumFormular';
import Alert from '@mui/material/Alert';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Container from '@mui/material/Container';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';


export default function ViewAlbums() {

  const [expanded, setExpanded] = useState<number | false>(false);
  const [message, setMessage] = useState<AlertMessage>({ message: '', severity: undefined });

  const albums = useObservable(galleryService.albums);

  const handlePannelChange =
    (index: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? index : false);
      getAlbumContent(albums[index].id);
    };

  const getAlbumContent = async (id: number) => {
    try {
      await galleryService.fetchAlbum(id);
    } catch (err: any) {
      setMessage({ message: formatError(err), severity: 'error' });
    }
  }

  return (
    <Container sx={{ padding: 0 }}>
      <Typography align='left' variant='h4' sx={{ mt: 4, mb: 6 }}>Gestion des album photo</Typography>
      {message.message.length > 0 &&
        <Alert variant='filled' severity={message.severity} sx={{ mb: 6 }}>
          {message.message}
        </Alert>
      }

      {
        albums.length == 0 ?
          <Typography align='left' variant='h5' sx={{ color: 'text.secondary', my: 4 }}>Aucun album photo n&apos;a été créé pour le moment.</Typography>
          :
          albums.map((album: Album, index: number) => (
            <Accordion expanded={expanded === index} onChange={handlePannelChange(index)} key={index} sx={{ my: 1 }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                <Typography sx={{ display: 'flex', justifyContent: 'flex-start' }}>{album.name}</Typography>
                <Typography sx={{ display: 'flex', justifyContent: 'flex-end', flexGrow: 1, color: 'text.secondary', mr: 4 }}>{dayjs(album.creation_date).format('DD/MM/YYYY')}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <AlbumFormular album={album} setNotification={setMessage} />
              </AccordionDetails>
            </Accordion >
          ))
      }
    </Container >
  );
}