import { observable } from 'micro-observables';
import { AccountService } from './AccountService';
import dayjs from 'dayjs';
import axios from 'axios';
import { ChartData } from 'chart.js';


export const holidayType = ['Congé payé', 'RTT', 'Congé maladie', 'Congé maternité', 'Congé paternité', 'Évènement familial', 'Autre'];
export type HolidaysType = typeof holidayType[number];

export interface User {
  firstname: string;
  lastname: string;
}

export interface Holiday {
  id: number;
  holiday_type: HolidaysType;
  creator: number;
  creator_fullname: string;
  start_date: string;
  end_date: string;
  begin_at_midday: boolean;
  end_at_midday: boolean;
  duration: number;
  asking_date: string;
  validation_date: string | null;
  reason: string;
  is_accepted: boolean;
}

export interface FrenchHoliday {
  name: string;
  day: string;
}

interface Data {
  id: number;
  user: string;
  type: HolidaysType;
  start_date: string;
  end_date: string;
  duration: number;
  asking_date: string;
  status: string;
  validation_date: string | null;
  reason: string;
}

interface GraphData {
  holiday_pie: ChartData<'doughnut', { key: string, value: number }[]>;
  holiday_chart: ChartData<'bar', { key: string, value: number }[]>;
}

export class HolidaysService {
  private _holidays = observable<Holiday[]>([]);
  private _frenchHolidays = observable<FrenchHoliday[]>([]);
  readonly holidays = this._holidays.readOnly();
  readonly frenchHolidays = this._frenchHolidays.readOnly();
  private _chartData = observable<GraphData | null>(null);
  readonly chartData = this._chartData.readOnly();

  constructor(private accountService: AccountService) { }

  async fetchUserHolidays() {
    const account = this.accountService.account.get();

    if (account) {
      const token = this.accountService.token.get();
      const config = {
        'headers': {
          'Authorization': `Bearer ${token}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      };
      const res = await axios.get(`/api/user_holidays/${account.id}/`, config);

      const holidays = res.data;
      holidays.sort((a: Holiday, b: Holiday) => a.start_date < b.start_date ? 1 : -1);
      this._holidays.set(holidays);
    }
  }

  async fetchHolidays() {
    const token = this.accountService.token.get();
    const config = {
      'headers': {
        'Authorization': `Bearer ${token}`
      }
    }
    const res = await axios.get('/api/holidays/', config);

    const holidays = res.data;
    holidays.sort((a: Holiday, b: Holiday) => a.start_date < b.start_date ? 1 : -1);
    this._holidays.set(res.data);
  }

  async addHoliday(holiday: Holiday) {
    const account = this.accountService.account.get();

    if (account) {
      const token = this.accountService.token.get();
      const config = {
        'headers': {
          'Authorization': `Bearer ${token}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      };
      const data = {
        holiday_type: holiday.holiday_type,
        creator: account.id,
        start_date: holiday.start_date,
        end_date: holiday.end_date,
        begin_at_midday: holiday.begin_at_midday,
        end_at_midday: holiday.end_at_midday,
        duration: holiday.duration,
        asking_date: holiday.asking_date,
      };

      await axios.post('/api/holidays/', JSON.stringify(data), config);

      const holidays = [...this.holidays.get(), holiday];
      holidays.sort((a: Holiday, b: Holiday) => a.start_date < b.start_date ? 1 : -1);
      this._holidays.set(holidays);
    }
  }

  async updateHoliday(holiday: Holiday) {
    const token = this.accountService.token.get();
    const config = {
      'headers': {
        'Authorization': `Bearer ${token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    };
    const data = {
      holiday_type: holiday.holiday_type,
      creator: holiday.creator,
      start_date: holiday.start_date,
      end_date: holiday.end_date,
      begin_at_midday: holiday.begin_at_midday,
      end_at_midday: holiday.end_at_midday,
      duration: holiday.duration,
      asking_date: holiday.asking_date,
      validation_date: holiday.validation_date,
      reason: holiday.reason,
      is_accepted: holiday.is_accepted,
    };

    const res = await axios.put(`/api/holidays/${holiday.id}/`, JSON.stringify(data), config);
    const holidays = this.holidays.get().filter(holiday => holiday.id !== res.data.id);
    holidays.push(res.data);
    holidays.sort((a: Holiday, b: Holiday) => a.start_date < b.start_date ? 1 : -1);
    this._holidays.set(holidays);
  }

  async removeHoliday(holiday: Holiday) {
    const token = this.accountService.token.get();
    const config = {
      'headers': {
        'Authorization': `Bearer ${token}`
      }
    }
    await axios.delete(`/api/holidays/${holiday.id}/`, config);
    this._holidays.update(holidays => holidays.filter((h) => h.id !== holiday.id));
  }

  async fetchFrenchHolidays() {
    const frenchHolidays: FrenchHoliday[] = [];
    const res = await axios.get('https://calendrier.api.gouv.fr/jours-feries/metropole.json');

    for (const day in res.data) {
      frenchHolidays.push({
        name: res.data[day],
        day: day,
      });
    }

    this._frenchHolidays.set(frenchHolidays);
    return frenchHolidays;
  }

  getHolidaysRequested() {
    return this.holidays.select(arr => arr.filter(holiday => !holiday.validation_date));
  }

  getHoldaysValidated() {
    return this.holidays.select(arr => arr.filter(holiday => holiday.validation_date));
  }

  getHoldaysAccepted() {
    return this.holidays.select(arr => arr.filter(holiday => holiday.validation_date && holiday.is_accepted));
  }

  isRTTValid(date: string | undefined, id = -1) {
    const rtt = this.holidays.get().filter(holiday => holiday.holiday_type === 'RTT' && holiday.id != id && !(holiday.validation_date && !holiday.is_accepted));

    for (const rttHoliday of rtt) {
      //RTT the day before
      if (dayjs(date, 'DD/MM/YYYY').add(1, 'day').isSame(dayjs(rttHoliday.start_date), 'day')) {
        return false;
      }
      //RTT the day after
      if (dayjs(date, 'DD/MM/YYYY').subtract(1, 'day').isSame(dayjs(rttHoliday.start_date), 'day')) {
        return false;
      }
    }
    return true;
  }

  getTableRows() {
    return this.holidays.select(arr => this.constructTableRows(arr));
  }

  getHolidayDuration(start_date: string, end_date: string, begin_at_midday: boolean, end_at_midday: boolean) {
    const start = dayjs(start_date, 'DD/MM/YYYY');
    const end = dayjs(end_date, 'DD/MM/YYYY')

    let duration: number = end.diff(start, 'day') + 1;

    //remove weekend days
    const d = start;
    const gap = duration;
    for (let i = 0; i < gap; i++) {
      if (d.add(i, 'day').day() === 6 || d.add(i, 'day').day() === 0) {
        duration -= 1;
      }
    }
    //remove french holidays 
    const frenchHolidays = this.frenchHolidays.get();
    for (const frenchHoliday of frenchHolidays) {
      if (dayjs(frenchHoliday.day, 'YYYY-MM-DD').day() !== 6 && dayjs(frenchHoliday.day, 'YYYY-MM-DD').day() !== 0) {
        if (start.diff(dayjs(frenchHoliday.day, 'YYYY-MM-DD')) <= 0 && end.diff(dayjs(frenchHoliday.day, 'YYYY-MM-DD')) >= 0) {
          duration -= 1;
        }
      }
    }

    if (begin_at_midday) {
      duration -= 0.5;
    }
    if (end_at_midday) {
      duration -= 0.5;
    }

    return duration;
  }

  constructTableRows(holidays: Holiday[]) {
    const rows: Data[] = [];

    for (let i = 0; i < holidays.length; i++) {
      let status = '';

      if (holidays[i].validation_date) {
        if (holidays[i].is_accepted) {
          status = 'Validé';
        } else {
          status = 'Refusé';
        }
      } else {
        status = 'En attente de validation'
      }

      rows.push({
        id: i,
        user: holidays[i].creator_fullname,
        type: holidays[i].holiday_type,
        start_date: holidays[i].begin_at_midday ? dayjs(holidays[i].start_date).format('DD/MM/YYYY') + ' apres-midi' : dayjs(holidays[i].start_date).format('DD/MM/YYYY') + ' matin',
        end_date: holidays[i].end_at_midday ? dayjs(holidays[i].end_date).format('DD/MM/YYYY') + ' fin de matinée' : dayjs(holidays[i].end_date).format('DD/MM/YYYY') + ' soir',
        duration: holidays[i].duration,
        asking_date: dayjs(holidays[i].asking_date).format('DD/MM/YYYY'),
        status: status,
        validation_date: holidays[i].validation_date ? dayjs(holidays[i].validation_date).format('DD/MM/YYYY') : '',
        reason: holidays[i].reason,
      });
    }
    return rows;
  }

  async fetchUsersHolidayData(id: number, year: number) {
    const token = this.accountService.token.get();
    const config = {
      'headers': {
        'Authorization': `Bearer ${token}`
      }
    }
    const res = await axios.get(`/api/holiday_data/${id}/${year}/`, config);

    const chartData = res.data;
    this._chartData.set(chartData);
  }

  async fetchUserHolidayData(year: number) {
    const account = this.accountService.account.get();

    if (account) {
      const token = this.accountService.token.get();
      const config = {
        'headers': {
          'Authorization': `Bearer ${token}`
        }
      }
      const res = await axios.get(`/api/holiday_data/${account.id}/${year}/`, config);

      const chartData = res.data;
      this._chartData.set(chartData);
    }
  }

  resetCharData() {
    this._chartData.set(null);
  }
}