import React, { useState } from 'react';
import { holidaysService } from '../../services/Service';
import { Holiday } from '../../services/HolidaysService';
import { AlertMessage } from '../../common/dto';
import { formatError } from '../../common/formatError';
import { useFormik } from 'formik';
import * as yup from 'yup'
import dayjs from 'dayjs';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CheckIcon from '@mui/icons-material/Check';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import Collapse from '@mui/material/Collapse';
import Container from '@mui/material/Container';
import EditIcon from '@mui/icons-material/Edit';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';


interface Props {
  holiday: Holiday;
  setNotification: (msg: AlertMessage) => void;
  close: () => void;
}

export default function HolidaysDemandFormular(props: Props) {

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<AlertMessage>({ message: '', severity: undefined });
  const [firstclick, setFirstClick] = useState(false);

  const formik = useFormik({
    initialValues: {
      reason: '',
      is_accepted: true,
    },
    validationSchema: yup.object({
      reason: yup
        .string()
        .trim(),
      is_accepted: yup
        .boolean(),
    }),
    onSubmit: (values) => {
      setLoading(true);

      const holiday: Holiday = {
        id: props.holiday.id,
        holiday_type: props.holiday.holiday_type,
        creator: props.holiday.creator,
        creator_fullname: props.holiday.creator_fullname,
        start_date: props.holiday.start_date,
        end_date: props.holiday.end_date,
        begin_at_midday: props.holiday.begin_at_midday,
        end_at_midday: props.holiday.end_at_midday,
        duration: props.holiday.duration,
        asking_date: props.holiday.asking_date,
        validation_date: dayjs().format('YYYY-MM-DD').toString(),
        reason: values.is_accepted ? '' : values.reason,
        is_accepted: values.is_accepted,
      }
      handleUpdate(holiday);
    },
  });

  const handleUpdate = async (holiday: Holiday) => {
    try {
      await holidaysService.updateHoliday(holiday);
      props.setNotification({ message: "La demande d'absence a bien été modifiée.", severity: 'success' });
      props.close();
    } catch (err: any) {
      setMessage({ message: formatError(err), severity: 'error' });
    } finally {
      setLoading(false);
    }
  }

  return (
    <Container sx={{ my: 4, width: 'auto' }}>
      {message.message.length > 0 &&
        <Alert variant='filled' severity={message.severity} sx={{ mb: 6 }}>
          {message.message}
        </Alert>
      }

      <form onSubmit={formik.handleSubmit}>
        <Typography align='left' variant='h4' sx={{ mb: 6 }}>Validation de la demande d&apos;absence</Typography>

        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          sx={{ mt: 4 }}
        >
          <Button
            variant='contained'
            endIcon={<CheckIcon />}
            style={{ backgroundColor: firstclick ? formik.values.is_accepted ? 'green' : 'grey' : 'grey'}}
            onClick={() => { formik.setFieldValue(`is_accepted`, true); setFirstClick(true); }}
          >
            Accepter
          </Button>
          <Button
            variant='contained'
            endIcon={<CloseIcon />}
            style={{ backgroundColor: firstclick ? formik.values.is_accepted ? 'grey' : 'red' : 'grey'}}
            onClick={() => { formik.setFieldValue(`is_accepted`, false); setFirstClick(true); }}
          >
            Refuser
          </Button>
        </Stack>

        <Collapse in={!formik.values.is_accepted} timeout='auto' unmountOnExit>
          <Typography align='left' sx={{ mt: 4, mb: 2 }}>Raison</Typography>
          <TextField
            fullWidth
            label='reason'
            name='reason'
            size='small'
            multiline
            minRows={2}
            maxRows={10}
            value={formik.values.reason}
            onChange={formik.handleChange}
            error={formik.touched.reason && Boolean(formik.errors.reason)}
            helperText={formik.touched.reason && formik.errors.reason}
          />
        </Collapse>

        <Box sx={{ mt: 6 }}>
          {
            loading ?
              <CircularProgress />
              :
              <Button variant='contained' type='submit' endIcon={<EditIcon />} disabled={loading || !firstclick}>
                Valider
              </Button>
          }
        </Box>
      </form>
    </Container >
  );
}