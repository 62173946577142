import React from 'react';
import { websiteStructure } from './WebsiteStructure';
import { Group, Page } from './dto';
import { Routes, Route } from 'react-router-dom';
import PageStructure from './PageStructure';
import ProtectedRoute from './ProtectedRoute'


export default function AppRouter() {

  return (
    <Routes>
      {websiteStructure.groups.map((group: Group) => (
        group.pages.map((page: Page) => (
          page.isProtected ?
            <Route key={page.name} path={page.path} element={
              <ProtectedRoute authorization={page.requiredAuthorization} content={<PageStructure content={page.content} />} />
            } />
            :
            <Route key={page.name} path={page.path} element={page.content} />
        ))
      ))
      }

      {websiteStructure.otherPages.map((page: Page) => (
        page.isProtected ?
          <Route key={page.name} path={page.path} element={
            <ProtectedRoute authorization={page.requiredAuthorization} content={<PageStructure content={page.content} />} />
          } />
          :
          <Route key={page.name} path={page.path} element={page.content} />
      ))
      }
    </Routes>
  );
}