import { AccountService } from './AccountService';
import { EventsService } from './EventsService';
import { ExpensesService } from './ExpensesService';
import { GalleryService } from './GalleryService';
import { HolidaysService } from './HolidaysService';
import { NotificationsService } from './NotificationsService';
import { OrganizationalService } from './OrganizationalService';
import { RMAService } from './RMAService';
import { UsersService } from './UsersService';


export const accountService = new AccountService();
export const notificationsService = new NotificationsService(accountService);
export const holidaysService = new HolidaysService(accountService);
export const expensesService = new ExpensesService(accountService);
export const usersService = new UsersService(accountService);
export const organizationalService = new OrganizationalService(accountService);
export const galleryService = new GalleryService(accountService);
export const eventsService = new EventsService(accountService);
export const rmaService = new RMAService(accountService);