import React, { useState, useEffect } from 'react';
import { useObservable } from 'micro-observables';
import { galleryService } from '../../services/Service';
import { Album } from '../../services/GalleryService';
import { AlertMessage } from '../../common/dto';
import { formatError } from '../../common/formatError';
import MasonryGallery from './MasonryGallery';
import dayjs from 'dayjs';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Alert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FolderIcon from '@mui/icons-material/Folder';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList';
import Typography from '@mui/material/Typography';
import { css } from '@emotion/css';


export default function Gallery() {

  const albums = useObservable(galleryService.albums);
  const [albumIndex, setAlbumIndex] = useState<null | number>(null);
  const [message, setMessage] = useState<AlertMessage>({ message: '', severity: undefined });

  useEffect(() => {
    galleryService.fetchAlbums();
  }, []);

  const handleClick = (index: number) => {
    setAlbumIndex(index);
    getAlbumContent(albums[index].id);
  };

  const getAlbumContent = async (id: number) => {
    try {
      await galleryService.fetchAlbum(id);
    } catch (err: any) {
      setMessage({ message: formatError(err), severity: 'error' });
    }
  }

  return (
    <div>
      {message.message.length > 0 &&
        <Alert variant='filled' severity={message.severity} sx={{ mb: 6 }}>
          {message.message}
        </Alert>
      }

      {
        albums.length == 0 ?
          <Typography align='center' variant='h5' sx={{ color: 'text.secondary', my: 10 }}>Aucun album photo de disponible pour le moment.</Typography>
          :
          <div>
            <Accordion sx={{ mb: 2 }} defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                <Typography variant='h5'>Albums photo</Typography>
              </AccordionSummary>
              <AccordionDetails className={css`max-height: 30vh; overflow: auto`}>
                <MenuList>
                  {albums.map((album: Album, index: number) => (
                    <MenuItem key={index} onClick={() => { handleClick(index) }}>
                      <ListItemAvatar>
                        <Avatar>
                          <FolderIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <Typography variant='h5' textAlign='left'>
                            {album.name}
                          </Typography>
                        }
                        secondary={
                          <Typography textAlign='left' sx={{ color: 'text.secondary' }}>
                            {dayjs(album.creation_date).format('DD/MM/YYYY')}
                          </Typography>
                        }
                      />
                    </MenuItem>
                  ))}
                </MenuList>
              </AccordionDetails>
            </Accordion>
            {
              albumIndex !== null ?
                <div>
                  <Typography variant='h4' align='center' sx={{ my: 4 }}>
                    {albums[albumIndex].name + ' - ' + dayjs(albums[albumIndex].creation_date).format('DD/MM/YYYY')}
                  </Typography>

                  <MasonryGallery photos={albums[albumIndex].photos} />
                </div>
                :
                <div />
            }
          </div>
      }
    </div>
  );
}