import React, { useState, useEffect } from 'react';
import { useObservable } from 'micro-observables';
import { User, HistoryEvent } from '../../services/UsersService';
import { usersService } from '../../services/Service';
import { CustomSwitch } from '../../common/CustomSwitch';
import { AlertMessage } from '../../common/dto';
import { formatError } from '../../common/formatError';
import dayjs from 'dayjs';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Alert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import Box from '@mui/material/Box';
import CelebrationIcon from '@mui/icons-material/Celebration';
import DownloadIcon from '@mui/icons-material/Download';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Fab from '@mui/material/Fab';
import FormControlLabel from '@mui/material/FormControlLabel';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import ReceiptIcon from '@mui/icons-material/Receipt';
import Stack from '@mui/material/Stack';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import WorkIcon from '@mui/icons-material/Work';
import { css } from '@emotion/css';
import { green, lightBlue, red, yellow } from '@mui/material/colors';


export default function UserHistory() {

  const users = useObservable(usersService.users);
  const userHistory = useObservable(usersService.history);
  const [message, setMessage] = useState<AlertMessage>({ message: '', severity: undefined });
  const [userIndex, setUserIndex] = useState(-1);
  const [eventFilter, setEventFilter] = useState(['Évènement', 'Mission', 'Remboursement', 'Absence']);

  useEffect(() => {
    usersService.fetchUsers();
  }, []);

  const updateEventFilter = (filter: string) => {
    setEventFilter(prevState => prevState.includes(filter) ? prevState.filter(val => val !== filter) : [...prevState, filter]);
  };

  const handleClick = (index: number) => {
    setUserIndex(index);
    const user = users[index];
    getUserHistory(user);
  };

  const getUserHistory = async (user: User) => {
    try {
      await usersService.fetchUserHistory(user);
      setEventFilter(['Évènement', 'Mission', 'Remboursement', 'Absence']);
    } catch (err: any) {
      setMessage({ message: formatError(err), severity: 'error' });
    }
  }

  const downloadPDF = async (user: User) => {
    try {
      await usersService.downloadPDF(user);
    } catch (err: any) {
      setMessage({ message: formatError(err), severity: 'error' });
    }
  }

  return (
    <div className={'MuiContainer-root MuiContainer-maxWidthLg css-1oqqzyl-MuiContainer-root'}>
      <Accordion sx={{ mb: 2 }} >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} >
          <Typography variant='h5' sx={{ mx: 2 }}>Utilisateurs</Typography>
        </AccordionSummary>
        <AccordionDetails className={css`max-height: 30vh; overflow: auto`}>
          <MenuList>
            {users.map((user: User, index: number) => (
              <MenuItem key={index} onClick={() => { handleClick(index) }}>
                <ListItemAvatar>
                  <Avatar
                    alt='avatar'
                    src={user.photo}
                    sx={{ width: 50, height: 50, mr: 1 }} />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography variant='h6' textAlign='left'>
                      {user.first_name + ' ' + user.last_name}
                    </Typography>
                  }
                />
              </MenuItem>
            ))}
          </MenuList>
        </AccordionDetails>
      </Accordion>

      {
        userIndex >= 0 &&
        <div>

          <FormControlLabel
            control={<CustomSwitch switchcolor={green[500]} sx={{ m: 1 }} checked={eventFilter.includes('Évènement')} onChange={() => { updateEventFilter('Évènement') }}
            />}
            label={'Évènement'}
          />
          <FormControlLabel
            control={<CustomSwitch switchcolor={red[900]} sx={{ m: 1 }} checked={eventFilter.includes('Mission')} onChange={() => { updateEventFilter('Mission') }}
            />}
            label={'Mission'}
          />
          <FormControlLabel
            control={<CustomSwitch switchcolor={lightBlue[800]} sx={{ m: 1 }} checked={eventFilter.includes('Remboursement')} onChange={() => { updateEventFilter('Remboursement') }}
            />}
            label={'Remboursement'}
          />
          <FormControlLabel
            control={<CustomSwitch switchcolor={yellow[800]} sx={{ m: 1 }} checked={eventFilter.includes('Absence')} onChange={() => { updateEventFilter('Absence') }}
            />}
            label={'Absence'}
          />

          <Stack
            direction='row'
            justifyContent='center'
            alignItems='center'
            spacing={4}
          >
            <Typography variant='h4' align='center' sx={{ my: 4 }}>
              {users[userIndex].first_name + ' ' + users[userIndex].last_name}
            </Typography>

            <Tooltip title="Télécharger l'historique de l'utilisateur">
              <Fab size='small' color='primary' style={{ transform: 'scale(1.2)' }} onClick={() => { downloadPDF(users[userIndex]) }} sx={{ mx: 4 }}>
                <DownloadIcon />
              </Fab>
            </Tooltip>
          </Stack>


          {message.message.length > 0 &&
            <Alert variant='filled' severity={message.severity} sx={{ mb: 6 }}>
              {message.message}
            </Alert>
          }

          {
            userIndex >= 0 && userHistory.length == 0 ?
              <Typography align='left' variant='h5' sx={{ color: 'text.secondary', my: 4 }}>Aucun historique disponible pour le moment.</Typography>
              :
              <Timeline position='alternate' nonce={undefined} onResize={undefined} onResizeCapture={undefined}>
                {userHistory.filter((event: HistoryEvent) => eventFilter.includes(event.event_type)).map((event: HistoryEvent, index: number) => (
                  {
                    'Évènement':
                      <CustomTimelineItem date={event.date} eventType={event.event_type} eventColor={green[500]} description={event.description} icon={<CelebrationIcon />} key={index} />,
                    'Mission':
                      <CustomTimelineItem date={event.date} eventType={event.event_type} eventColor={red[900]} description={event.description} icon={<WorkIcon />} key={index} />,
                    'Remboursement':
                      <CustomTimelineItem date={event.date} eventType={event.event_type} eventColor={lightBlue[800]} description={event.description} icon={<ReceiptIcon />} key={index} />,
                    'Absence':
                      <CustomTimelineItem date={event.date} eventType={event.event_type} eventColor={yellow[800]} description={event.description} icon={<BeachAccessIcon />} key={index} />
                  }[event.event_type]
                ))}
              </Timeline>
          }
        </div>
      }
    </div >
  );
}

dayjs.Ls.en.months = [
  'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet',
  'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'
]

interface Props {
  date: string;
  eventType: string;
  eventColor: string;
  description: string;
  icon: JSX.Element;
}

export function CustomTimelineItem(props: Props) {

  return (
    <TimelineItem>
      <TimelineOppositeContent sx={{ mt: 1.8 }} variant='body2' color='text.secondary'>
        {dayjs(props.date, 'DD/MM/YYYY').format('MMMM YYYY')}
      </TimelineOppositeContent>

      <TimelineSeparator>
        <TimelineDot sx={{ bgcolor: props.eventColor }}>
          {props.icon}
        </TimelineDot>
        <TimelineConnector />
      </TimelineSeparator>

      <TimelineContent sx={{ py: '12px', px: 2 }}>
        <Paper elevation={3}>
          <Box bgcolor={props.eventColor} sx={{ flexGrow: 1, py: 2, borderRadius: 1 }}>
            <Typography align='center' variant='body2' color='text.secondary'>
              {dayjs(props.date, 'DD/MM/YYYY').format('DD MMMM YYYY')}
            </Typography>
          </Box>
          <Typography align='center' variant='h6' sx={{ py: 2 }}>
            {props.eventType}
          </Typography>

          <Typography align='center' sx={{ pb: 2 }}>
            {props.description}
          </Typography>
        </Paper>
      </TimelineContent>
    </TimelineItem>
  );
}