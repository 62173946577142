import React, { useState } from 'react';
import Footer from '../../common/Footer'
import logo from '../../assets/xrays_logo_white.svg';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import EditIcon from '@mui/icons-material/Edit';
import TextField from '@mui/material/TextField';
import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import { css } from '@emotion/css';
import { useNavigate } from 'react-router-dom';
import { AlertMessage } from '../../common/dto';
import { formatError } from '../../common/formatError';
import { useFormik } from 'formik';
import * as yup from 'yup'
import axios from 'axios';

export default function ForgotPassword() {

  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const refreshToken = urlParams.get('token');
  const userId = Number(urlParams.get('user_id'));

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<AlertMessage>({ message: '', severity: undefined });

  const formik = useFormik({
    initialValues: {
      new_password: '',
      confirm_new_password: '',
    },
    validationSchema: yup.object({
      new_password: yup
        .string()
        .trim()
        .required('Password requis')
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-+={}[\]:;?/<>,.]).{8,}$/,
          'Mot de passe avec au moins 8 caractères, une majuscule, une minuscule, un chiffre et un caractère spécial.'
        ),
      confirm_new_password: yup
        .string()
        .trim()
        .required('Password requis')
        .test(
          'Password non valide',
          'Les password ne correspondent pas',
          function (value) {
            return value === this.parent.new_password
          }
        ),
    }),
    onSubmit: (values) => {
      setLoading(true);
      handlePasswordUpdate(values.new_password);
    },
  });

  const updatePassword = async (id: number, new_password: string): Promise<void> => {
    const config = {
      'headers': {
        'Authorization': `Bearer ${refreshToken}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    };

    const key = import.meta.env.VITE_CYPHER_KEY;

    const encryptedPassword = new_password.split('').map((char, i) =>
      String.fromCharCode(char.charCodeAt(0) ^ key.charCodeAt(i % key.length))
    ).join('');

    const data = {
      password: encryptedPassword,
    }

    await axios.put(`/api/users/${id}/`, JSON.stringify(data), config);
  }

  const handlePasswordUpdate = async (new_password: string) => {
    try {
      await updatePassword(userId, new_password);
      setMessage({ message: 'Password modifié.', severity: 'success' });
    } catch (err: any) {
      setMessage({ message: formatError(err), severity: 'error' });
    } finally {
      setLoading(true);
    }
  }

  return (
    <div>
      <div className={css`position: relative; min-height: 86vh; padding-top: 86px`}>
        <Box sx={{ flexGrow: 1 }}>
          <AppBar position='fixed'>
            <Toolbar>
              <Box component='img' alt='XRAYS TRADING logo' src={logo} onClick={() => { navigate('/login') }} sx={{ height: '40px' }} />
            </Toolbar>
          </AppBar>
        </Box>

        <div className={css`padding-bottom: 200px;`}>
          {message.message.length > 0 &&
            <Alert variant='filled' severity={message.severity} sx={{ mb: 6 }}>
              {message.message}
            </Alert>
          }

          <form onSubmit={formik.handleSubmit}>
            <Typography align='left' variant='h4' sx={{ mb: 6 }}>Changer le password</Typography>

            <Typography align='center' sx={{ mt: 4, mb: 2 }}>Nouveau password</Typography>

            <TextField
              type='password'
              name='new_password'
              label='nouveau password'
              size='small'
              value={formik.values.new_password}
              onChange={formik.handleChange}
              error={formik.touched.new_password && Boolean(formik.errors.new_password)}
              helperText={
                "Créez un mot de passe d'au moins 8 caractères qui combine : une majuscule (A-Z), une minuscule (a-z), un chiffre (0-9) Un caractère spécial parmi les suivants : [!@#$%^&*()\\-+={}[]:;?/<>,.]"
              }
            />

            <Typography align='center' sx={{ mt: 4, mb: 2 }}>Confirmer password</Typography>
            <TextField
              type='password'
              name='confirm_new_password'
              label='confirmer password'
              size='small'
              value={formik.values.confirm_new_password}
              onChange={formik.handleChange}
              error={formik.touched.confirm_new_password && Boolean(formik.errors.confirm_new_password)}
              helperText={formik.touched.confirm_new_password && formik.errors.confirm_new_password}
            />

            <Box sx={{ mt: 4 }}>
              {
                loading ?
                  <Button variant='contained' onClick={() => navigate('/login')}>
                    Return to Login
                  </Button>
                  :
                  <Button variant='contained' type='submit' endIcon={<EditIcon />} disabled={loading}>
                    Edit
                  </Button>
              }
            </Box>
          </form>
        </div>
        <div className={css`position: absolute; bottom: 0; width: 100%; height: 200px;`}>
          <Footer />
        </div>
      </div>
    </div>
  );
}