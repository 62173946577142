import React from 'react';
import Events from '../pages/Events/Events';
import EventManagement from '../pages/EventManagement/EventManagement';
import AlbumManagement from '../pages/AlbumManagement/AlbumManagement';
import Expense from '../pages/Expense/Expense';
import ExpenseManagement from '../pages/ExpenseManagement/ExpenseManagement';
import Gallery from '../pages/Gallery/Gallery';
import Home from '../pages/Home';
import Holidays from '../pages/Holidays/Holidays';
import HolidaysCalendar from '../pages/HolidaysCalendar.tsx/HolidaysCalendar';
import HolidaysManagement from '../pages/HolidaysManagement/HolidaysManagement';
import Login from '../pages/Login';
import ForgotPassword from '../pages/ForgotPassword/ForgotPassword';
import Organizational from '../pages/Organizational/Organizational';
import Profile from '../pages/Profile/Profile';
import RMAList from '../pages/RMA/RMAList';
import RMAEditor from '../pages/RMA/RMAEditor';
import RMAManagement from '../pages/RMAManagement/RMAManagement';
import UserManagement from '../pages/UserManagement/UserManagement';
import ResetPassword from '../pages/ForgotPassword/ResetPassword';

import { WebsiteStructure } from './dto';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import EventIcon from '@mui/icons-material/Event';
import EventNoteIcon from '@mui/icons-material/EventNote';
import GroupsIcon from '@mui/icons-material/Groups';
import HomeIcon from '@mui/icons-material/Home';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import ReceiptIcon from '@mui/icons-material/Receipt';
import RequestPageIcon from '@mui/icons-material/RequestPage';
import SurfingIcon from '@mui/icons-material/Surfing';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';



export const websiteStructure: WebsiteStructure = {
  groups: [
    {
      pages: [
        {
          name: 'Accueil',
          path: '/home',
          content: <Home />,
          isProtected: true,
          icon: <HomeIcon />,
        },
        {
          name: 'Profil',
          path: '/profile',
          content: <Profile />,
          isProtected: true,
          icon: <AccountCircleIcon />,
        },
      ]
    },
    {
      name: 'Xrays',
      pages: [
        {
          name: 'Mes collègues',
          path: '/orga',
          content: <Organizational />,
          isProtected: true,
          icon: <GroupsIcon />,
        },
        {
          name: 'Événements',
          path: '/events',
          content: <Events />,
          isProtected: true,
          icon: <EventIcon />,
        },
        {
          name: 'Galerie',
          path: '/gallery',
          content: <Gallery />,
          isProtected: true,
          icon: <PhotoLibraryIcon />,
        },
      ]
    },
    {
      name: 'Administratif',
      pages: [
        {
          name: 'RMA',
          path: '/rma',
          content: <RMAList />,
          isProtected: true,
          icon: <CalendarMonthIcon />,
        },
        {
          name: 'Mes absences',
          path: '/holidays',
          content: <Holidays />,
          isProtected: true,
          icon: <BeachAccessIcon />,
        },
        {
          name: 'Fiche de frais',
          path: '/expense',
          content: <Expense />,
          isProtected: true,
          icon: <ReceiptIcon />,
        },
      ]
    },
    {
      name: 'Administration',
      pages: [
        {
          name: 'Gestion des utilisateurs',
          path: '/user_management',
          content: <UserManagement />,
          isProtected: true,
          icon: <PersonAddIcon />,
          requiredAuthorization: 'can_manage_users',
        },
        {
          name: 'Planning des absences',
          path: '/calendar',
          content: <HolidaysCalendar />,
          isProtected: true,
          icon: <EventBusyIcon />,
          requiredAuthorization: 'can_see_vacations',
        },
        {
          name: 'Gestion des RMA',
          path: '/rma_management',
          content: <RMAManagement />,
          isProtected: true,
          icon: <WorkHistoryIcon />,
          requiredAuthorization: 'can_manage_rma',
        },
        {
          name: 'Gestion des absences',
          path: '/holidays_management',
          content: <HolidaysManagement />,
          isProtected: true,
          icon: <SurfingIcon />,
          requiredAuthorization: 'can_manage_holidays',
        },
        {
          name: 'Gestion des remboursements',
          path: '/expense_management',
          content: <ExpenseManagement />,
          isProtected: true,
          icon: <RequestPageIcon />,
          requiredAuthorization: 'can_manage_refunds',
        },
        {
          name: 'Gestion des évènements',
          path: '/event_management',
          content: <EventManagement />,
          isProtected: true,
          icon: <EventNoteIcon />,
          requiredAuthorization: 'can_manage_events',
        },
        {
          name: 'Gestion des albums',
          path: '/album_management',
          content: <AlbumManagement />,
          isProtected: true,
          icon: <PhotoLibraryIcon />,
          requiredAuthorization : 'can_manage_albums',
        }
      ]
    },
  ],
  otherPages: [
    {
      name: 'Éditer mon RMA',
      path: '/rma_editor',
      content: <RMAEditor />,
      isProtected: true,
    },
    {
      name: 'Login',
      path: '/login',
      content: <Login />,
      isProtected: false,
    },
    {
      name: 'ForgotPassword',
      path: '/forgot-password',
      content: <ForgotPassword />,
      isProtected: false,
    },
    {
      name : 'ResetPassword',
      path: '/reset-password',
      content: <ResetPassword />,
      isProtected: false,
    },
    {
      name: 'Default',
      path: '/',
      content: <Home />,
      isProtected: true,
    },
  ]
}