import React, { useState, Fragment } from 'react';
import { useMemoizedObservable } from 'micro-observables';
import { holidaysService } from '../../services/Service';
import { Holiday } from '../../services/HolidaysService';
import dayjs from 'dayjs';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import Box from '@mui/material/Box';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';


export default function ListView() {

  const holidaysValidate = useMemoizedObservable(() => holidaysService.getHoldaysValidated(), []);

  const [open, setOpen] = useState<number[]>([]);
  const itemsPerPage = 50;
  const [page, setPage] = useState(1);
  const [noOfPages] = useState(Math.ceil(holidaysValidate.length / itemsPerPage));

  const handleChange = (event: React.ChangeEvent<any>, value: number) => {
    setPage(value);
  };

  const handleClick = (index: number) => {
    setOpen(prevState => prevState.includes(index) ? prevState.filter(val => val !== index) : [...prevState, index]);
  };

  return (
    <div>
      {
        holidaysValidate.length == 0 ?
          <Typography align='left' variant='h5' sx={{ color: 'text.secondary', my: 4 }}>Aucune demande d&apos;absence réalisée pour le moment.</Typography>
          :
          <div>
            <List sx={{ width: '100%', mb: 4 }} component='nav' >
              {holidaysValidate.slice((page - 1) * itemsPerPage, page * itemsPerPage).map((holiday: Holiday, index: number) => (
                <div key={index}>
                  <ListItemButton onClick={() => { handleClick(index) }}>
                    <ListItemIcon>
                      {
                        holiday.is_accepted ?
                          <CheckIcon sx={{ color: 'green' }} />
                          :
                          <CloseIcon sx={{ color: 'red' }} />
                      }
                    </ListItemIcon>
                    <ListItemText
                      primary={holiday.creator_fullname + ' - ' + holiday.holiday_type}
                      secondary={
                        <Fragment >
                          {
                            holiday.holiday_type === 'RTT' ?
                              <Typography component='span' variant='body2' >
                                {dayjs(holiday.start_date).format('DD/MM/YYYY')}
                              </Typography>
                              :
                              <Typography component='span' variant='body2' >
                                {dayjs(holiday.start_date).format('DD/MM/YYYY')}
                                <ArrowRightAltIcon style={{ verticalAlign: 'middle' }} sx={{ mx: 1 }} />
                                {dayjs(holiday.end_date).format('DD/MM/YYYY')}
                              </Typography>
                          }
                        </Fragment>
                      } />
                    {open.includes(index) ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse in={open.includes(index)} timeout='auto' unmountOnExit>
                    <Stack
                      direction='column'
                      justifyContent='flex-start'
                      alignItems='flex-start'
                      sx={{ pl: 9 }}
                    >
                      {
                        holiday.holiday_type === 'RTT' ?
                          <Typography align='left' sx={{ my: 1, color: 'text.secondary' }}>Absence le {dayjs(holiday.start_date).format('DD/MM/YYYY')}.</Typography>
                          :
                          <Typography align='left' sx={{ my: 1, color: 'text.secondary' }}>Absence débutant le {dayjs(holiday.start_date).format('DD/MM/YYYY')} {holiday.begin_at_midday ? "en début d'après-midi" : 'matin'} et terminant le {dayjs(holiday.end_date).format('DD/MM/YYYY')} {holiday.end_at_midday ? 'en fin de matinée' : 'soir'}.</Typography>
                      }
                      {
                        holiday.is_accepted ?
                          <Typography align='left' sx={{ mb: 1, color: 'text.secondary' }}>
                            Demande réalisée le {dayjs(holiday.asking_date).format('DD/MM/YYYY').toString()} et accéptée le {dayjs(holiday.validation_date).format('DD/MM/YYYY').toString()}.
                          </Typography>
                          :
                          <div>
                            <Typography align='left' sx={{ color: 'text.secondary' }}>
                              Demande réalisée le {dayjs(holiday.asking_date).format('DD/MM/YYYY').toString()} et refusée le {dayjs(holiday.validation_date).format('DD/MM/YYYY').toString()}.
                            </Typography>
                            <Typography align='left' sx={{ mb: 1, color: 'text.secondary' }}>
                              Raison du refus: {holiday.reason != '' ? holiday.reason : 'Pas de raison spécfiée'}.
                            </Typography>
                          </div>
                      }
                    </Stack>
                  </Collapse>
                  <Divider sx={{ my: 0 }} />
                </div>
              ))}
            </List>
            <Box
              display='flex'
              justifyContent='center'
              alignItems='center'
              sx={{ mt: 2 }}
            >
              <Pagination
                count={noOfPages}
                page={page}
                defaultPage={1}
                onChange={handleChange}
                size='large'
                showFirstButton
                showLastButton
                color='primary'
              />
            </Box>
          </div>
      }
    </div >
  );
}