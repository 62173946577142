import React, { useState } from 'react';
import { useObservable } from 'micro-observables';
import { accountService } from '../../services/Service';
import AvatarEditor from './AvatarEditor'
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';


export default function AvatarInfo() {

  const account = useObservable(accountService.account);
  const [open, setOpen] = useState(false);

  const openEditor = () => {
    setOpen(true);
  };

  const closeEditor = () => {
    setOpen(false);
  };

  if (!account) {
    return <div/>
  }

  return (
    <Box>
      <Box>
        <Dialog onClose={closeEditor} open={open}>
          <AvatarEditor />
        </Dialog>
      </Box>

      <Box>
        <Badge
          overlap='circular'
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          badgeContent={
            <Fab size='medium' color='primary' aria-label='change image' onClick={openEditor}>
              <PhotoCameraIcon />
            </Fab>
          }
        >
          <Avatar
            alt='profile image'
            src={account.photo}
            sx={{ width: 150, height: 150 }} />
        </Badge>
      </Box>
    </Box>
  );
}