import React, { useState } from 'react';
import { expensesService } from '../../services/Service';
import { useMemoizedObservable } from 'micro-observables';
import DataAnalysis from './DataAnalysis';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef, GridToolbar, gridClasses } from '@mui/x-data-grid';
import { css } from '@emotion/css';


const columns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Nom',
    minWidth: 200,
    flex: 1,
    editable: false,
    align: 'left',
  },
  {
    field: 'description',
    headerName: 'Description',
    minWidth: 200,
    flex: 1,
    editable: false,
    align: 'left',
  },
  {
    field: 'expense_date',
    headerName: 'Date de la dépense',
    width: 150,
    editable: false,
    align: 'left',
  },
  {
    field: 'amount',
    headerName: 'Montant',
    type: 'number',
    width: 80,
    editable: false,
    align: 'left',
  },
  {
    field: 'tva',
    headerName: 'TVA',
    type: 'number',
    width: 60,
    editable: false,
    align: 'left',
  },
  {
    field: 'asking_date',
    headerName: 'Demande',
    width: 100,
    editable: false,
    align: 'left',
  },
  {
    field: 'validation_date',
    headerName: 'Validation',
    width: 100,
    editable: false,
    align: 'left',
  },
  {
    field: 'paiement_date',
    headerName: 'Paiement',
    width: 100,
    editable: false,
    align: 'left',
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 180,
    editable: false,
    align: 'left',
  },
  {
    field: 'reason',
    headerName: 'Raison',
    minWidth: 200,
    flex: 1,
    editable: false,
    align: 'left',
  },
];

export default function TableView() {

  const rows = useMemoizedObservable(() => expensesService.getTableRows(), []);
  const [pageSize, setPageSize] = useState<number>(25);

  return (
    <div>
      <Box sx={{ height: 200 + 52 * Math.min(rows.length, pageSize), width: '100%' }} className={css`min-width: 60vw; position: relative; left: calc(-30vw + 50%);`}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[25, 50, 100]}
          pagination
          getRowHeight={() => 'auto'}
          sx={{
            [`& .${gridClasses.cell}`]: {
              py: 2,
            },
          }}
          disableSelectionOnClick
          disableDensitySelector
          components={{
            Toolbar: GridToolbar,
          }}
        />
      </Box>
      <DataAnalysis />
    </div>
  );
}