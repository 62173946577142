import React, { useState, Fragment } from 'react';
import { useMemoizedObservable } from 'micro-observables';
import { expensesService } from '../../services/Service';
import { Expense, Fee } from '../../services/ExpensesService';
import { AlertMessage } from '../../common/dto';
import { formatError } from '../../common/formatError';
import dayjs from 'dayjs';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import DownloadIcon from '@mui/icons-material/Download';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Fab from '@mui/material/Fab';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Pagination from '@mui/material/Pagination';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';


export default function ListView() {

  const expensesValidate = useMemoizedObservable(() => expensesService.getExpensesValidated(), []);

  const [open, setOpen] = useState<number[]>([]);
  const itemsPerPage = 50;
  const [page, setPage] = useState(1);
  const [noOfPages] = useState(Math.ceil(expensesValidate.length / itemsPerPage));
  const [message, setMessage] = useState<AlertMessage>({ message: '', severity: undefined });

  const handleChange = (event: React.ChangeEvent<any>, value: number) => {
    setPage(value);
  };

  const handleClick = (index: number) => {
    setOpen(prevState => prevState.includes(index) ? prevState.filter(val => val !== index) : [...prevState, index]);
  };

  const downloadDocuments = async (id: number, date: string, creator: string) => {
    try {
      await expensesService.downloadPDF(id, date, creator);
      await expensesService.downloadDocuments(id, creator);
    } catch (err: any) {
      setMessage({ message: formatError(err), severity: 'error' });
    }
  }

  return (
    <div>
      {message.message.length > 0 &&
        <Alert variant='filled' severity={message.severity} sx={{ mb: 6 }}>
          {message.message}
        </Alert>
      }

      {
        expensesValidate.length == 0 ?
          <Typography align='left' variant='h5' sx={{ color: 'text.secondary', my: 4 }}>Aucune demande de remboursement réalisée pour le moment.</Typography>
          :
          <div>
            <List sx={{ width: '100%', mb: 4 }} component='nav' >
              {expensesValidate.slice((page - 1) * itemsPerPage, page * itemsPerPage).map((expense: Expense, index: number) => (
                <div key={index}>
                  <ListItemButton onClick={() => { handleClick(index) }}>
                    <ListItemIcon>
                      {
                        expense.is_accepted ?
                          <CheckIcon sx={{ color: 'green' }} />
                          :
                          <CloseIcon sx={{ color: 'red' }} />
                      }
                    </ListItemIcon>
                    <ListItemText
                      primary={expense.creator_fullname + ' - ' + expense.description}
                      secondary={
                        <Fragment >
                          <Typography component='span' variant='body2' >
                            demande faite le {dayjs(expense.asking_date).format('DD/MM/YYYY')}
                          </Typography>
                        </Fragment>
                      } />
                    {open.includes(index) ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse in={open.includes(index)} timeout='auto' unmountOnExit>
                    {
                      expense.fees.map((fee: Fee, index: number) => (
                        <Typography align='left' sx={{ ml: 9, my: 1, color: 'text.secondary' }} key={index}>
                          {`Demande de reboursement d'une valeure TTC de ${fee.amount}€ (dont ${fee.tva}€ de TVA) pour le motif suivant: ${fee.description} (${dayjs(fee.expense_date).format('DD/MM/YYYY')}).`}
                        </Typography>

                      ))}

                    {
                      !expense.is_accepted &&
                      <Typography align='left' sx={{ ml: 9, my: 1, color: 'text.secondary' }}>
                        Raison du refus: {expense.reason != '' ? expense.reason : 'Pas de raison spécfiée'}.
                      </Typography>
                    }

                    <Tooltip title='Télécharger la fiche de frais et les justificatifs'>
                      <Fab size='small' color='primary' sx={{ my: 2 }} onClick={() => { downloadDocuments(expense.id, expense.asking_date, expense.creator_fullname.replace(' ', '_')) }}>
                        <DownloadIcon />
                      </Fab>
                    </Tooltip>

                  </Collapse>
                  <Divider sx={{ my: 0 }} />
                </div>
              ))}
            </List>
            <Box
              display='flex'
              justifyContent='center'
              alignItems='center'
              sx={{ mt: 2 }}
            >
              <Pagination
                count={noOfPages}
                page={page}
                defaultPage={1}
                onChange={handleChange}
                size='large'
                showFirstButton
                showLastButton
                color='primary'
              />
            </Box>
          </div>
      }
    </div >
  );
}