import React from 'react';
import { User } from '../../services/UsersService';
import Container from '@mui/material/Container';
import EditIcon from '@mui/icons-material/Edit';
import Fab from '@mui/material/Fab';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';


interface Props {
    user: User;
    openAuthorizationEditorWindow: () => void;
  }
  
export default function AuthorizationsInfo(props: Props) {

  return (
    <Paper elevation={3} sx={{ my: 3, py: 2 }}>
      <Container>
        <Grid
          container
          direction='row'
          justifyContent='flex-start'
          alignItems='center'
          spacing={2}
        >
          <Grid item>
            <Typography variant='h6'>Modifier les droits d&apos;administration</Typography>
          </Grid>

          <Grid item sx={{ flexGrow: 1 }} />

          <Grid item>
            <Fab size='small' color='primary' aria-label='change image' onClick={ () => props.openAuthorizationEditorWindow() }>
              <EditIcon />
            </Fab>
          </Grid>
        </Grid>
      </Container>
    </Paper>
  );
}