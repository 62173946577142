import React, { useState } from 'react';
import { holidaysService } from '../../services/Service';
import { Holiday } from '../../services/HolidaysService';
import { AlertMessage } from '../../common/dto';
import { formatError } from '../../common/formatError';
import DeleteConfirm from '../../common/DeleteConfirm';
import { useFormik } from 'formik';
import * as yup from 'yup'
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import EditIcon from '@mui/icons-material/Edit';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';


interface Props {
  holiday: Holiday;
  setNotification: (msg: AlertMessage) => void;
  close: () => void;
}

export default function RTTFormular(props: Props) {

  const [holidayCache] = useState<Holiday>(props.holiday);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<AlertMessage>({ message: '', severity: undefined });

  const formik = useFormik({
    initialValues: {
      date: dayjs(holidayCache.start_date, 'YYYY-MM-DD').format('DD/MM/YYYY').toString(),
      deleted: false,
    },
    validationSchema: yup.object({
      date: yup
        .string()
        .trim()
        .required("Date d'absence requise")
        .test(
          'Date valide',
          'Date non valide',
          (value) => dayjs(value, 'DD/MM/YYYY').isValid()
        )
        .test(
          'RTT valide',
          'Vous ne pouvez pas poser 2 RTT consécutifs',
          (value) => holidaysService.isRTTValid(value, holidayCache.id)
        ),
      deleted: yup
        .boolean()
    }),
    onSubmit: (values) => {
      setLoading(true);

      if (values.deleted) {
        handleDelete();
      } else {
        const holiday: Holiday = {
          id: props.holiday.id,
          holiday_type: props.holiday.holiday_type,
          creator: props.holiday.creator,
          creator_fullname: props.holiday.creator_fullname,
          start_date: dayjs(values.date, 'DD/MM/YYYY').format('YYYY-MM-DD').toString(),
          end_date: dayjs(values.date, 'DD/MM/YYYY').format('YYYY-MM-DD').toString(),
          begin_at_midday: false,
          end_at_midday: false,
          duration: 1,
          asking_date: dayjs().format('YYYY-MM-DD').toString(),
          validation_date: props.holiday.validation_date,
          reason: props.holiday.reason,
          is_accepted: props.holiday.is_accepted,
        }
        handleUpdate(holiday);
      }
    },
  });

  const handleDelete = async () => {
    try {
      await holidaysService.removeHoliday(props.holiday);
      props.setNotification({ message: "La demande d'absence a bien été annulée.", severity: 'success' });
      props.close();
    } catch (err: any) {
      setMessage({ message: formatError(err), severity: 'error' });
    } finally {
      setLoading(false);
    }
  }

  const handleUpdate = async (holiday: Holiday) => {
    try {
      await holidaysService.updateHoliday(holiday);
      setMessage({ message: "La demande d'absence a bien été modifiée.", severity: 'success' });
    } catch (err: any) {
      setMessage({ message: formatError(err), severity: 'error' });
    } finally {
      setLoading(false);
    }
  }

  return (
    <Container sx={{ my: 4, width: 'auto' }}>
      {message.message.length > 0 &&
        <Alert variant='filled' severity={message.severity} sx={{ mb: 6 }}>
          {message.message}
        </Alert>
      }

      <form onSubmit={formik.handleSubmit}>
        <Typography align='left' variant='h4' sx={{ mb: 4, wordBreak: 'break-word' }}>Modifier une demande d&apos;absence</Typography>

        <Typography align='left' variant='h5' sx={{ mt: 2, mb: 2, wordBreak: 'break-word' }}>{props.holiday.holiday_type}</Typography>

        <Typography align='left' sx={{ mt: 4, mb: 2, wordBreak: 'break-word' }}>Date du RTT</Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            label='Jour'
            inputFormat='DD/MM/YYYY'
            value={dayjs(formik.values.date, 'DD/MM/YYYY')}
            onChange={(value) => {
              const newDate = dayjs(value).format('DD/MM/YYYY');
              formik.setFieldValue('date', newDate, true);
            }}
            renderInput={(params) =>
              <TextField
                name='date'
                label='date'
                size='small'
                error={formik.touched.date && Boolean(formik.errors.date)}
                helperText={formik.errors.date?.toString()}
                {...params} />}
          />
        </LocalizationProvider>

        {
          loading ?
            <Box
              display='flex'
              justifyContent='center'
              alignItems='center'
              sx={{ mt: 4 }}
            >
              <CircularProgress />
            </Box>
            :
            <Stack
              direction='row'
              justifyContent='space-between'
              alignItems='center'
              sx={{ mt: 4, flexWrap: 'wrap'}}
            >
              <Button variant='contained' type='submit' endIcon={<EditIcon />} disabled={loading}>
                Modifier
              </Button>
              <DeleteConfirm message={'Êtes vous sur de vouloir supprimer le RTT?'} disabled={loading} action={() => { formik.setFieldValue(`deleted`, true); formik.submitForm() }} />
            </Stack>
        }
      </form>
    </Container >
  );
}