import React, { useEffect, useState } from 'react';
import { useObservable } from 'micro-observables';
import { holidaysService, organizationalService } from '../../services/Service';
import { Profil } from '../../services/OrganizationalService';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement, PointElement, LineElement, Filler } from 'chart.js';
import { Bar, Doughnut } from 'react-chartjs-2';
import dayjs from 'dayjs';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { css } from '@emotion/css';


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

export default function DataAnalysis() {

  const chartData = useObservable(holidaysService.chartData);
  const [year, setYear] = useState(dayjs().year().toString());
  const users = useObservable(organizationalService.profils);
  const [user, setUser] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    organizationalService.fetchProfils();
    holidaysService.resetCharData();
  }, []);

  const handleUserChange = async (event: SelectChangeEvent) => {
    const newUser = event.target.value as string;
    setUser(newUser);
    setLoading(true);

    try {
      await holidaysService.fetchUsersHolidayData(parseInt(newUser), parseInt(year));
    } catch (err: any) {
      holidaysService.resetCharData();
    } finally {
      setLoading(false);
    }
  };

  const handleYearChange = async (event: SelectChangeEvent) => {
    const newYear = event.target.value as string;
    setYear(newYear);
    setLoading(true);

    try {
      await holidaysService.fetchUsersHolidayData(parseInt(user), parseInt(newYear));
    } catch (err: any) {
      holidaysService.resetCharData();
    } finally {
      setLoading(false);
    }
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
        display: true,
        labels: {
          filter: function (label: any) {
            if (label.text !== '') return true;
            else return false;
          }
        }
      },
      title: {
        display: true,
        text: 'Nombre de jours de congé restants',
      },
    },
    scales: {
      x: {
        ticks: {
          autoSkip: true,
          maxRotation: 70,
          minRotation: 70
        }
      },
    },
  };

  return (
    <div>
      {
        loading ?
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            sx={{ mt: 4 }}
          >
            <CircularProgress />
          </Box>
          :
          <div>
            <Stack
              direction='row'
              justifyContent='center'
              alignItems='center'
              spacing={2}
            >
              <Box display='flex' justifyContent='center' alignItems='center' sx={{ my: 4 }}>
                <FormControl sx={{ width: 200 }}>
                  <InputLabel>Utilisateurs</InputLabel>
                  <Select
                    value={user}
                    label="Utilisateur"
                    onChange={handleUserChange}
                  >
                    {
                      users.map((user: Profil, index: number) =>
                        <MenuItem value={user.id} key={index}>{user.first_name + ' ' + user.last_name}</MenuItem>
                      )
                    }
                  </Select>
                </FormControl>
              </Box>

              <Box display='flex' justifyContent='center' alignItems='center' sx={{ my: 4 }}>
                <FormControl sx={{ width: 200 }}>
                  <InputLabel>Année</InputLabel>
                  <Select
                    value={year}
                    label="Année"
                    onChange={handleYearChange}
                  >
                    {
                      Array.from({ length: 10 }).map((item: any, index: number) =>
                        <MenuItem value={dayjs().year() - index} key={index}>{dayjs().year() - index}</MenuItem>
                      )
                    }
                  </Select>
                </FormControl>
              </Box>
            </Stack>

            {
              chartData ?
                <Container>
                  <Grid
                    container
                    alignItems='center'
                    direction={{ xs: 'column', sm: 'column', md: 'row' }
                    }
                    spacing={5}
                    sx={{ my: 6 }}
                    className={css`min-width: 70vw; position: relative; left: calc(-35vw + 50%);`}
                  >
                    <Grid item xs={4}>
                      <Doughnut options={options} data={chartData.holiday_pie} />
                    </Grid>

                    <Grid item xs={8}>
                      <Bar options={options} data={chartData.holiday_chart} />
                    </Grid>
                  </Grid >
                </Container >
                :
                <Typography align='center' sx={{ my: 4, color: 'text.secondary' }}>Aucune donnée disponible</Typography>
            }
          </div>
      }
    </div >
  );
}