import React, { useState } from 'react';
import { galleryService } from '../../services/Service';
import { Album } from '../../services/GalleryService';
import { AlertMessage } from '../../common/dto';
import { formatError } from '../../common/formatError';
import { useFormik } from 'formik';
import * as yup from 'yup'
import dayjs from 'dayjs';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import SendIcon from '@mui/icons-material/Send';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import UploadFileIcon from '@mui/icons-material/UploadFile';


export default function CreateAlbum() {

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<AlertMessage>({ message: '', severity: undefined });

  const formik = useFormik({
    initialValues: {
      name: '',
      content: Array<File>(),
    },
    validationSchema: yup.object({
      name: yup
        .string()
        .trim()
        .required("Nom de l'album requis"),
      content: yup
        .array()
        .min(1, 'Au moins 1 photo est requise')
        .required('Au moins 1 photo est requise'),
    }),
    onSubmit: (values, { resetForm }) => {
      setLoading(true);

      const album: Album = {
        id: 1,
        name: values.name,
        creator: '',
        creation_date: dayjs().format('YYYY-MM-DD'),
        photos: [],
      }

      handleDemand(album, values.content, resetForm);
    },
  });

  const handleDemand = async (album: Album, photos: File[], resetForm: () => void) => {
    try {
      await galleryService.addAlbum(album, photos);
      setMessage({ message: 'Album créé.', severity: 'success' });
      resetForm();
    } catch (err: any) {
      setMessage({ message: formatError(err), severity: 'error' });
    } finally {
      setLoading(false);
    }
  }

  return (
    <Container sx={{ padding: 0 }}>
      {message.message.length > 0 &&
        <Alert variant='filled' severity={message.severity} sx={{ mb: 6 }}>
          {message.message}
        </Alert>
      }

      <form onSubmit={formik.handleSubmit}>
        <Typography align='left' variant='h4' sx={{ mt: 4, mb: 6 }}>Créer un album photo</Typography>

        <Typography align='left' sx={{ my: 2 }}>Nom de l&apos;album</Typography>
        <Box
          display='flex'
          justifyContent='flex-start'
          alignItems='flex-start'
        >
          <TextField
            name='name'
            label="Nom de l'album"
            size='small'
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
        </Box>

        <Box
          display='flex'
          justifyContent='flex-start'
          alignItems='flex-start'
        >
          <Button
            sx={{ mt: 4, mb: 2 }}
            variant='contained'
            component='label'
            endIcon={<UploadFileIcon />}>
            Sélectionnez les photos
            <input
              name='content'
              type='file'
              accept='image/png, image/jpeg, image/jpg'
              hidden
              onChange={(event) => {
                const files = event.target.files;
                if (!files) {
                  formik.setFieldValue('content', []);
                } else {
                  formik.setFieldValue('content', Array.from(files));
                }
              }}
            />
          </Button>
        </Box>

        <Typography align='left' color='#d32f2f' sx={{ fontSize: '0.75rem' }}>{formik.errors.content?.toString()}</Typography>

        <Stack
          direction="column"
          display='flex'
          justifyContent='flex-start'
          alignItems='flex-start'
          spacing={1}
          sx={{ px: 3 }}>
          {formik.values.content.map((value: File, index) => (
            <Typography key={index} variant='body2' align='left' sx={{ color: 'text.secondary' }} >{value.name}</Typography>
          ))}
        </Stack>

        <Box
          display='flex'
          justifyContent='flex-start'
          alignItems='flex-start'
          sx={{ mt: 4 }}
        >
          {
            loading ?
              <CircularProgress />
              :
              <Button variant='contained' type='submit' endIcon={<SendIcon />} disabled={loading}>
                Créer un nouvel album
              </Button>
          }
        </Box>
      </form>
    </Container >
  );
}