import React, { useState, useEffect } from 'react';
import { useObservable } from 'micro-observables';
import { expensesService } from '../../services/Service';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement, PointElement, LineElement, Filler } from 'chart.js';
import { Doughnut, Line } from 'react-chartjs-2';
import dayjs from 'dayjs';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { css } from '@emotion/css';


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

export default function DataAnalysis() {

  const chartData = useObservable(expensesService.chartData);
  const [year, setYear] = useState(dayjs().year().toString());
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    expensesService.resetCharData();
    expensesService.fetchUserExpenseData(dayjs().year());
  }, []);

  const handleChange = async (event: SelectChangeEvent) => {
    const newYear = event.target.value as string;
    setYear(newYear);
    setLoading(true);

    try {
      await expensesService.fetchUserExpenseData(parseInt(newYear));
    } catch (err: any) {
      expensesService.resetCharData();
    } finally {
      setLoading(false);
    }
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: 'Dépense par catégorie',
      },
    },
  };

  return (
    <div>
      {
        loading ?
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            sx={{ mt: 4 }}
          >
            <CircularProgress />
          </Box>
          :
          <div>
            <Box display='flex' justifyContent='center' alignItems='center' sx={{ my: 4 }}>
              <FormControl sx={{ width: 200 }}>
                <InputLabel>Année</InputLabel>
                <Select
                  value={year}
                  label="Année"
                  onChange={handleChange}
                >
                  {
                    Array.from({ length: 10 }).map((item: any, index: number) =>
                      <MenuItem value={dayjs().year() - index} key={index}>{dayjs().year() - index}</MenuItem>
                    )
                  }
                </Select>
              </FormControl>
            </Box>

            {
              chartData ?
                <Container>
                  <Grid
                    container
                    alignItems='center'
                    direction={{ xs: 'column', sm: 'column', md: 'row' }
                    }
                    spacing={5}
                    sx={{ my: 6 }}
                    className={css`min-width: 70vw; position: relative; left: calc(-35vw + 50%);`}
                  >
                    <Grid item xs={4}>
                      <Doughnut options={options} data={chartData.expense_pie} />
                    </Grid>

                    <Grid item xs={8}>
                      <Line options={options} data={chartData.expense_chart} />
                    </Grid>
                  </Grid >
                </Container >
                :
                <Typography align='center' sx={{ my: 4, color: 'text.secondary' }}>Aucune donnée disponible</Typography>
            }
          </div>
      }
    </div >
  );
}