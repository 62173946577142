import { observable } from 'micro-observables';
import { AccountService } from './AccountService';
import dayjs from 'dayjs';
import axios from 'axios';
import download from 'downloadjs';


export interface User {
  id: number;
  username: string;
  first_name: string;
  last_name: string;
  email: string;
  date_of_birth: string;
  photo?: string;
  phone: string;
  arrival: string;
  mission: Mission | null;
  authorizations: string[];
  is_active: boolean;
}

export interface Mission {
  id: number;
  client: string;
  service: string;
  leader_name: string;
  leader_email: string;
  description: string;
  modification_date: string;
  creator: number;
}

export const eventType = ['Évènement', 'Mission', 'Remboursement', 'Absence'];
export type EventType = typeof eventType[number];

export interface HistoryEvent {
  date: string;
  event_type: EventType;
  description: string;
}

export class UsersService {
  private _users = observable<User[]>([]);
  readonly users = this._users.readOnly();

  private _history = observable<HistoryEvent[]>([]);
  readonly history = this._history.readOnly();

  constructor(private accountService: AccountService) { }

  async fetchUsers() {
    const token = this.accountService.token.get();
    const config = {
      'headers': {
        'Authorization': `Bearer ${token}`
      }
    }
    const res = await axios.get('/api/users/', config);

    const users = res.data;
    users.sort((a: User, b: User) => a.first_name.toLowerCase() + ' ' + a.last_name.toLowerCase() > b.first_name.toLowerCase() + ' ' + b.last_name.toLowerCase() ? 1 : -1);
    this._users.set(users);
  }

  async addUser(user: User, password: string) {
    const token = this.accountService.token.get();
    const config = {
      'headers': {
        'Authorization': `Bearer ${token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    };
    const data = {
      username: user.username,
      password: password,
      first_name: user.first_name,
      last_name: user.last_name,
      date_of_birth: user.date_of_birth,
      email: user.email,
      phone: user.phone,
      mission: user.mission,
      authorizations: JSON.stringify(user.authorizations),
    };

    await axios.post('/api/users/', JSON.stringify(data), config);
  }

  async updateUser(user: User) {
    const token = this.accountService.token.get();
    const config = {
      'headers': {
        'Authorization': `Bearer ${token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    };
    const data = {
      username: user.username,
      first_name: user.first_name,
      last_name: user.last_name,
      date_of_birth: user.date_of_birth,
      email: user.email,
      phone: user.phone,
    };

    const res = await axios.put(`/api/users/${user.id}/`, JSON.stringify(data), config);
    const users = this.users.get().filter(user => user.id !== res.data.id);
    users.push(res.data);
    users.sort((a: User, b: User) => a.first_name.toLowerCase() + ' ' + a.last_name.toLowerCase() > b.first_name.toLowerCase() + ' ' + b.last_name.toLowerCase() ? 1 : -1);
    this._users.set(users);
  }

  async updateMission(mission: Mission) {
    const token = this.accountService.token.get();
    const config = {
      'headers': {
        'Authorization': `Bearer ${token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    };
    const data = {
      client: mission.client,
      service: mission.service,
      leader_name: mission.leader_name,
      leader_email: mission.leader_email,
      description: mission.description,
      modification_date: mission.modification_date,
      creator: mission.creator,
    }

    await axios.post(`/api/missions/`, JSON.stringify(data), config);
    this.fetchUsers();
  }

  async updatePassword(id: number, new_password: string) {
    const token = this.accountService.token.get();
    const config = {
      'headers': {
        'Authorization': `Bearer ${token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    };
    const data = {
      password: new_password,
    }

    await axios.put(`/api/users/${id}/`, JSON.stringify(data), config);
  }

  async updateStatus(id: number, status: boolean) {
    const token = this.accountService.token.get();
    const config = {
      'headers': {
        'Authorization': `Bearer ${token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    };
    const data = {
      active: status,
    }

    await axios.put(`/api/users/${id}/`, JSON.stringify(data), config);
  }

  async updateAuthorization(id: number, new_authorizations: string[]) {
    const token = this.accountService.token.get();
    const config = {
      'headers': {
        'Authorization': `Bearer ${token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    };
    const data = {
      authorizations: JSON.stringify(new_authorizations),
    }

    const res = await axios.put(`/api/users/${id}/`, JSON.stringify(data), config);
    const users = this.users.get().filter(user => user.id !== res.data.id);
    users.push(res.data);
    users.sort((a: User, b: User) => a.first_name.toLowerCase() + ' ' + a.last_name.toLowerCase() > b.first_name.toLowerCase() + ' ' + b.last_name.toLowerCase() ? 1 : -1);
    this._users.set(users);
  }

  async removeUser(user: User) {
    const token = this.accountService.token.get();
    const config = {
      'headers': {
        'Authorization': `Bearer ${token}`
      }
    }
    await axios.delete(`/api/users/${user.id}/`, config);
    this._users.update(users => users.filter((u) => u.id !== user.id));
  }

  isBirthday(user: User) {
    return dayjs(user.date_of_birth, 'YYYY-MM-DD').format('MM/DD').toString() === dayjs().format('MM/DD').toString();
  }

  async fetchUserHistory(user: User) {
    const token = this.accountService.token.get();
    const config = {
      'headers': {
        'Authorization': `Bearer ${token}`
      }
    }
    const res = await axios.get(`/api/history/${user.id}/`, config);

    const history = res.data;
    this._history.set(history);
  }

  async downloadPDF(user: User) {
    const account = this.accountService.account.get();

    if (account) {
      const token = this.accountService.token.get();

      const res = await axios.get(
        `/api/history_pdf/${user.id}/`,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          responseType: 'blob'
        }
      );

      const filetype = res.headers['content-type'];
      const filename = `history_${user.first_name}_${user.last_name}`;
      download(res.data, filename, filetype);
    }
  }

  getUsernameList() {
    return this.users.select(arr => arr.map((user: User) => user.first_name + ' ' + user.last_name));
  }
}