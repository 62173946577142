import React, { useState, useEffect } from 'react';
import { useObservable } from 'micro-observables';
import { organizationalService } from '../../services/Service';
import { Profil } from '../../services/OrganizationalService';
import dayjs from 'dayjs';
import CustomBadge from './CustomBadge';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import CakeIcon from '@mui/icons-material/Cake';
import CircleIcon from '@mui/icons-material/Circle';
import Container from '@mui/material/Container';
import Fab from '@mui/material/Fab';
import Paper from '@mui/material/Paper';
import PhoneDisabledIcon from '@mui/icons-material/PhoneDisabled';
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useMediaQuery } from '@mui/material';

function getBadge(profil: Profil) {
  if (profil.statut === 'birthday') {
    return <CustomBadge tooltip='Fête son anniversaire' profil={profil} badgeColor='#E625E6' icon={<CakeIcon style={{ color: '#E650AB', backgroundColor: '#FFFFFF' }} />} />;
  } else if (profil.statut === 'vacation') {
    return <CustomBadge tooltip='En vacances' profil={profil} badgeColor='#E6120D' icon={<CircleIcon sx={{ color: '#E6120D', bgcolor: '#E6120D' }} />} />;
  } else {
    return <CustomBadge tooltip='Présent' profil={profil} badgeColor='#4BCE25' icon={<CircleIcon sx={{ color: '#4BCE25', bgcolor: '#4BCE25' }} />} />;
  }
}

export default function Organizational() {
  const profils = useObservable(organizationalService.profils);
  const userList = useObservable(organizationalService.getUsernameList());
  const [selectedUser, setSelectedUser] = useState<null | string>(null);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    organizationalService.fetchProfils();
  }, []);

  return (
    <Container>
      <Autocomplete
        disablePortal
        options={userList}
        sx={{ width: 300 }}
        renderInput={(params) => <TextField {...params} label='Utilisateur' />}
        onChange={(event, value) => setSelectedUser(value)}
      />

      {profils.filter(profil => !selectedUser || profil.first_name + ' ' + profil.last_name === selectedUser).map((profil: Profil, index: number) => (
        <Paper elevation={3} sx={{ my: 3, py: 3 }} key={index}>
          <Container>
            <Stack direction='row' justifyContent='center' alignItems='center' flexWrap='wrap'>
              {getBadge(profil)}

              <Box sx={{ ml: 4 }}>
                <Typography variant={isSmallScreen ? 'body1' : 'h4'} align='left' gutterBottom>{profil.first_name + ' ' + profil.last_name}</Typography>
                <Typography variant={isSmallScreen ? 'body2' : 'h6'} align='left' sx={{ color: 'text.secondary' }}>{profil.email}</Typography>
                <Typography variant={isSmallScreen ? 'body2' : 'h6'} align='left' sx={{ color: 'text.secondary' }}>{profil.phone}</Typography>
              </Box>

              <Box sx={{ flexGrow: 1 }} />

              <Box>
                <Typography variant={isSmallScreen ? 'body2' : 'h6'} align='left' sx={{ color: 'text.secondary' }}>Travaille chez XRAYS TRADING depuis {dayjs(profil.arrival, 'YYYY-MM-DD').format('DD/MM/YYYY')}</Typography>
                <Box display='flex' justifyContent='flex-end' sx={{ mt: 1 }}>
                  {profil.statut === 'vacation' || profil.phone === '' ? (
                    <Fab size='small' disabled color={'primary'} aria-label="disabled phone button">
                      <PhoneDisabledIcon fontSize='small' />
                    </Fab>
                  ) : (
                    <Tooltip title={'Appeler ' + profil.first_name + ' ' + profil.last_name}>
                      <Fab size='small' color={'primary'} onClick={() => { window.location.href = `tel:${profil.phone}`; }} aria-label="phone button">
                        <PhoneEnabledIcon fontSize='small' />
                      </Fab>
                    </Tooltip>
                  )}
                </Box>
              </Box>
            </Stack>
          </Container>
        </Paper>
      ))}
    </Container>
  );
}