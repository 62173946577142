import React, { useContext } from 'react'
import { ColorModeContext } from '../App';
import blackLogo from '../assets/xrays_logo_black.svg';
import whiteLogo from '../assets/xrays_logo_white.svg';
import dayjs from 'dayjs';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Fab from '@mui/material/Fab';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const LazyImage = (props: React.ImgHTMLAttributes<HTMLImageElement>) => <img loading="lazy" {...props} />;

export default function Footer() {

  const { mode } = useContext(ColorModeContext);

  return (
    <Container>
      <Box
        component={LazyImage}
        alt='XRAYS TRADING logo'
        src={mode === 'light' ? blackLogo : whiteLogo}
        sx={{ height: '80px', width: '162px', display: 'flex' }}
      />
      <Divider sx={{ my: 2 }} />

      <Stack
        direction='row'
        justifyContent='center'
        alignItems='center'
        spacing={2}
      >
        <Typography align='left' sx={{ color: 'text.secondary' }}>{`${dayjs().year()} XRAYS TRADING.`}</Typography>

        <Box sx={{ flexGrow: 1 }} />

        <Fab size='small' color='primary' aria-label='change image' onClick={() => window.open('https://www.linkedin.com/company/xrays-engineering/', '_blank')}>
          <LinkedInIcon />
        </Fab>

        <Fab size='small' color='primary' aria-label='change image' onClick={() => window.open('https://www.instagram.com/xraystrading/', '_blank')}>
          <InstagramIcon />
        </Fab>
      </Stack>
    </Container>
  );
}