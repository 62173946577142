import { observable } from 'micro-observables';
import { AccountService } from './AccountService';
import axios from 'axios';


export interface Album {
  id: number;
  name: string;
  creator: string;
  creation_date: string;
  photos: Photo[];
}

export interface Photo {
  id: number;
  title: string;
  folder: string;
  image: string;
}

export class GalleryService {
  private _albums = observable<Album[]>([]);
  readonly albums = this._albums.readOnly();

  constructor(private accountService: AccountService) { }

  async fetchAlbums() {
    if (this.albums.get().length > 0) return;

    const token = this.accountService.token.get();
    const config = {
      'headers': {
        'Authorization': `Bearer ${token}`
      }
    }
    const res = await axios.get('/api/albums/', config);
    const albums = res.data;
    albums.sort((a: Album, b: Album) => a.creation_date + a.name > b.creation_date + b.name ? 1 : -1);
    this._albums.set(albums);
  }

  async fetchAlbum(id: number, refresh=false) {
    if (this.albums.get().filter(album => album.id === id).length > 0 && this.albums.get().filter(album => album.id === id)[0]['photos'].length > 0 && !refresh) return ;

    const token = this.accountService.token.get();
    const config = {
      'headers': {
        'Authorization': `Bearer ${token}`
      }
    }
    const res = await axios.get(`/api/albums/${id}/`, config);
    const albums = this.albums.get().filter(album => album.id !== id);
    albums.push(res.data);
    albums.sort((a: Album, b: Album) => a.creation_date + a.name > b.creation_date + b.name ? 1 : -1);
    this._albums.set(albums);
  }

  async addAlbum(album: Album, photos: File[]) {
    const account = this.accountService.account.get();

    if (account) {
      const formData = new FormData();
      photos.forEach((photo: File) => formData.append(photo.name, photo));

      formData.append('name', album.name);
      formData.append('creator', account.first_name + ' ' + account.last_name);
      formData.append('creation_date', album.creation_date);

      const token = this.accountService.token.get();
      const config = {
        'headers': {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        }
      };

      const res = await axios.post('/api/albums/', formData, config);
      const albums = this.albums.get()
      albums.push(res.data);
      this._albums.set(albums);
    }
  }

  async updateAlbum(album: Album, photosToAdd: File[], photoToDelete: number[]) {
    const token = this.accountService.token.get();
    const config = {
      'headers': {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      }
    };
    const formData = new FormData();
    photosToAdd.forEach((photo: File) => formData.append(photo.name, photo));

    formData.append('name', album.name);
    formData.append('creator', album.creator);
    formData.append('creation_date', album.creation_date);

    formData.append('photo_to_delete', photoToDelete.toString());

    const res = await axios.put(`/api/albums/${album.id}/`, formData, config);
    const albums = this.albums.get().filter(a => a.id !== album.id);
    albums.push(res.data);
    albums.sort((a: Album, b: Album) => a.creation_date + a.name > b.creation_date + b.name ? 1 : -1);
    this._albums.set(albums);
  }

  async removeAlbum(album: Album) {
    const token = this.accountService.token.get();
    const config = {
      'headers': {
        'Authorization': `Bearer ${token}`
      }
    }
    await axios.delete(`/api/albums/${album.id}/`, config);
    this._albums.update(albums => albums.filter((a) => a.id !== album.id));
  }
}