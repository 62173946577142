import { observable } from 'micro-observables';
import { AccountService } from './AccountService';
import dayjs from 'dayjs';
import axios from 'axios';
import { ChartData } from 'chart.js';
import download from 'downloadjs';


export const expenseType = ['Transport', 'Restauration', 'Logement', 'Autre'];
export type ExpenseType = typeof expenseType[number];

export interface Bill {
  id: number;
  title: string;
  folder: string;
  document: string;
}

export interface Fee {
  id: number;
  expense_date: string;
  expense_type: string;
  description: string;
  amount: number;
  tva: number;
}

export interface Expense {
  id: number;
  creator: number;
  creator_fullname: string;
  description: string;
  asking_date: string;
  paiement_date: string | null;
  validation_date: string | null;
  reason: string;
  is_accepted: boolean;
  fees: Fee[];
  bills: Bill[];
  is_signed: boolean;
  sign_date?: string;
}

interface Data {
  id: number;
  user: string;
  name: string;
  asking_date: string;
  description: string;
  amount: number;
  tva: number;
  expense_date: string;
  validation_date: string | null;
  paiement_date: string | null;
  status: 'Validée' | 'Refusée' | 'En attende de validation';
  reason: string;
}

interface GraphData {
  expense_pie: ChartData<'doughnut', { key: string, value: number }[]>;
  expense_chart: ChartData<'line', { key: string, value: number }[]>;
  user_pie: ChartData<'doughnut', { key: string, value: number }[]>;
  user_chart: ChartData<'bar', { key: string, value: number }[]>;
}

export class ExpensesService {
  private _expenses = observable<Expense[]>([]);
  readonly expenses = this._expenses.readOnly();
  private _chartData = observable<GraphData | null>(null);
  readonly chartData = this._chartData.readOnly();

  constructor(private accountService: AccountService) { }

  async fetchUserExpenses() {
    const account = this.accountService.account.get();

    if (account) {
      const token = this.accountService.token.get();
      const config = {
        'headers': {
          'Authorization': `Bearer ${token}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      };
      const res = await axios.get(`/api/user_expenses/${account.id}/`, config);

      const expenses = res.data;
      expenses.sort((a: Expense, b: Expense) => a.asking_date + a.description < b.asking_date + b.description ? 1 : -1);
      this._expenses.set(expenses);
    }
  }

  async fetchExpenses() {
    const token = this.accountService.token.get();
    const config = {
      'headers': {
        'Authorization': `Bearer ${token}`
      }
    }
    const res = await axios.get('/api/expenses/', config);

    const expenses = res.data;
    expenses.sort((a: Expense, b: Expense) => a.asking_date < b.asking_date ? 1 : -1);
    this._expenses.set(expenses);
  }

  async addExpense(expense: Expense, bills: File[]) {
    const account = this.accountService.account.get();

    if (account) {
      const formData = new FormData();
      bills.forEach((bill: File) => formData.append(bill.name, bill));

      formData.append('creator', account.id.toString());
      formData.append('description', expense.description);
      formData.append('asking_date', expense.asking_date);
      formData.append('fees', JSON.stringify(expense.fees));

      const token = this.accountService.token.get();
      const config = {
        'headers': {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        }
      };

      await axios.post('/api/expenses/', formData, config);
      this.fetchUserExpenses();
    }
  }

  async updateExpense(expense: Expense, bills: File[]) {
    const token = this.accountService.token.get();
    const config = {
      'headers': {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      }
    };
    const formData = new FormData();
    bills.forEach((bill: File) => formData.append(bill.name, bill));

    formData.append('creator', expense.creator.toString());
    formData.append('description', expense.description);
    formData.append('asking_date', expense.asking_date);
    if (expense.paiement_date) formData.append('paiement_date', expense.paiement_date);
    formData.append('is_signed', expense.is_signed.toString());
    if (expense.validation_date) {
      formData.append('validation_date', expense.validation_date);
      formData.append('reason', expense.reason);
      formData.append('is_accepted', expense.is_accepted.toString());
    }

    const res = await axios.put(`/api/expenses/${expense.id}/`, formData, config);
    const expenses = this.expenses.get().filter(expense => expense.id !== res.data.id);
    expenses.push(res.data);
    expenses.sort((a: Expense, b: Expense) => a.asking_date < b.asking_date ? 1 : -1);
    this._expenses.set(expenses);
  }

  async signRequest(request: Expense) {
    const token = this.accountService.token.get();
    const config = {
      'headers': {
        'Authorization': `Bearer ${token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    };
    const data = {
      creator: request.creator,
      description: request.description,
      asking_date: request.asking_date,
      is_signed: !request.is_signed,
      sign_date: dayjs().format('YYYY-MM-DD').toString(),
    };

    const res = await axios.put(`/api/expenses/${request.id}/`, JSON.stringify(data), config);
    const expenses = this.expenses.get().filter(expense => expense.id !== res.data.id);
    expenses.push(res.data);
    expenses.sort((a: Expense, b: Expense) => a.asking_date + a.description < b.asking_date + b.description ? 1 : -1);
    this._expenses.set(expenses);
  }

  async removeExpense(expense: Expense) {
    const token = this.accountService.token.get();
    const config = {
      'headers': {
        'Authorization': `Bearer ${token}`
      }
    }
    await axios.delete(`/api/expenses/${expense.id}/`, config);
    this._expenses.update(expenses => expenses.filter((e) => e.id !== expense.id));
  }

  async downloadPDF(id: number, date: string, creator: string) {
    const account = this.accountService.account.get();

    if (account) {
      const token = this.accountService.token.get();

      const res = await axios.get(
        `/api/expense_pdf/${id}/`,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          responseType: 'blob'
        }
      );

      const filetype = res.headers['content-type'];
      const month = dayjs(date).format('MM_YYYY').toString();
      const filename = `${creator}_${month}`;
      download(res.data, filename, filetype);
    }
  }

  async downloadDocuments(id: number, creator: string) {
    const account = this.accountService.account.get();

    if (account) {
      const token = this.accountService.token.get();

      const res = await axios.get(
        `/api/expense_documents/${id}/`,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          responseType: 'blob'
        }
      );

      const filetype = res.headers['content-type'];
      download(res.data, creator, filetype);
    }
  }

  getExpensesRequested() {
    return this.expenses.select(arr => arr.filter(expense => !expense.validation_date));
  }

  getExpensesValidated() {
    return this.expenses.select(arr => arr.filter(expense => expense.validation_date));
  }

  getExpensesAccepted() {
    return this.expenses.select(arr => arr.filter(expense => expense.validation_date && expense.is_accepted));
  }

  getTableRows() {
    return this.expenses.select(arr => this.constructTableRows(arr));
  }

  constructTableRows(expenses: Expense[]) {
    const rows: Data[] = [];
    let index = 0;

    for (const expense of expenses) {
      for (const fee of expense.fees) {
        rows.push({
          id: index,
          user: expense.creator_fullname,
          name: expense.description,
          description: fee.description,
          expense_date: dayjs(fee.expense_date).format('DD/MM/YYYY'),
          amount: fee.amount,
          tva: fee.tva,
          asking_date: dayjs(expense.asking_date).format('DD/MM/YYYY'),
          validation_date: expense.validation_date ? dayjs(expense.validation_date).format('DD/MM/YYYY') : '',
          status: expense.is_accepted ? 'Validée' : 'Refusée',
          reason: expense.reason,
          paiement_date: expense.paiement_date ? dayjs(expense.paiement_date).format('DD/MM/YYYY') : '',
        });
        if (rows[index].validation_date == '') {
          rows[index].status = 'En attende de validation';
        }
        index += 1;
      }
    }

    return rows;
  }

  async fetchExpenseData(year: number) {
    const token = this.accountService.token.get();
    const config = {
      'headers': {
        'Authorization': `Bearer ${token}`
      }
    }
    const res = await axios.get(`/api/expense_data/${year}/`, config);

    const chartData = res.data;
    this._chartData.set(chartData);
  }

  async fetchUserExpenseData(year: number) {
    const account = this.accountService.account.get();

    if (account) {
      const token = this.accountService.token.get();
      const config = {
        'headers': {
          'Authorization': `Bearer ${token}`
        }
      }
      const res = await axios.get(`/api/expense_data/${account.id}/${year}/`, config);

      const chartData = res.data;
      this._chartData.set(chartData);
    }
  }

  resetCharData() {
    this._chartData.set(null);
  }
}