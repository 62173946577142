import React, { useEffect } from 'react';
import { useObservable } from 'micro-observables';
import { accountService, notificationsService } from '../services/Service';
import { Notification } from '../services/NotificationsService';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Avatar from '@mui/material/Avatar';
import CheckIcon from '@mui/icons-material/Check';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { css } from '@emotion/css';
import { useNavigate } from 'react-router-dom';


export default function Home() {

  const account = useObservable(accountService.account);
  const notifications = useObservable(notificationsService.notifications);
  const navigate = useNavigate();

  useEffect(() => {
    notificationsService.fetchNotifications()
  }, [])

  if (!account) {
    return <div />
  }

  const handleClick = (notification: Notification) => {
    notificationsService.removeNotification(notification);
  };

  const handleRedirection = (notification: Notification) => {
    if (notification.description.includes('Vous n\'avez pas encore rempli votre RMA ce mois ci!'))
      navigate('/rma')
    else if (notification.description.includes('RMA créé après la date limite'))
      navigate('/rma_management')
    else if (notification.description.includes('RMA modifié après la date limite'))
      navigate('/rma_management')
    else if (notification.description.includes('Nouvel évènement'))
      navigate('/events')
    else if (notification.description.includes('Nouvelle demande d\'absence'))
      navigate('/holidays_management')
    else if (notification.description.includes('Validation de votre demande d\'absence'))
      navigate('/holidays')
    else if (notification.description.includes('Refus de votre demande d\'absence'))
      navigate('/holidays')
    else if (notification.description.includes('Nouvel album photo'))
      navigate('/gallery')
    else if (notification.description.includes('Nouvelle demande de remboursement'))
      navigate('/expense_management')
    else if (notification.description.includes('Validation de votre demande de remboursement'))
      navigate('/expense')
    else if (notification.description.includes('Refus de votre demande de remboursement'))
      navigate('/expense')
  };

  return (
    <div>
      <Paper elevation={3} sx={{ mt: 3, mb: 5, py: 3 }}>
        <Container>
          <Stack
            direction='row'
            justifyContent='flex-start'
            alignItems='center'
            spacing={3}
          >
              <Avatar
                alt='profile image'
                src={account.photo}
                sx={{ width: 150, height: 150 }} />

              <Typography variant='h4'>{accountService.getFullName()}</Typography>
          </Stack>
        </Container>
      </Paper>

      <Link href='/gallery' color='inherit' underline='hover'>
        <Typography align='left' variant='h4' sx={{ my: 2 }}>Galerie photo</Typography>
      </Link>
      <Link href='/orga' color='inherit' underline='hover'>
        <Typography align='left' variant='h4' sx={{ my: 2 }}>Mes collègues</Typography>
      </Link>
      <Link href='/rma' color='inherit' underline='hover'>
        <Typography align='left' variant='h4' sx={{ my: 2 }}>Mes RMA</Typography>
      </Link>

      {
        notifications.length == 0 ?
          <Typography align='left' variant='h5' sx={{ color: 'text.secondary', my: 4 }}>Vous n&apos;avez aucune notification pour le moment.</Typography>
          :
          <Stack sx={{ width: '100%', my: 4 }} spacing={2}>

            {notifications.map((notification: Notification, index) => (
              <Alert
                key={index}
                className={css`text-align: start;`}
                severity={notification.severity}
                action={
                  <IconButton
                    aria-label='close'
                    color='inherit'
                    size='large'
                    onClick={() => { handleClick(notification) }}
                  >
                    <CheckIcon fontSize='inherit' />
                  </IconButton>
                }
              >
                <AlertTitle
                  onClick={() => { handleRedirection(notification) }}
                  sx={{ cursor: 'pointer' }}
                >
                  {notification.title}
                </AlertTitle>
                {notification.description}
              </Alert>
            ))}
          </Stack>
      }
    </div>
  );
}