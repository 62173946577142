import React, { useEffect } from 'react';
import { expensesService } from '../../services/Service';
import { MyTab } from '../../common/dto';
import TabSelector from '../../common/TabSelector';
import DeclareExpense from './DeclareExpense';
import ViewExpenses from './ViewExpenses';


export default function UserManagement() {
  
  const tabs: MyTab[] = [
    {label: 'Créer une fiche de frais', content: <DeclareExpense />},
    {label: 'Mes fiches de frais', content: <ViewExpenses />},
  ]

  useEffect(() => {
    expensesService.fetchUserExpenses();
  }, []);

  return (
    <TabSelector tabs={tabs} />
  );
}