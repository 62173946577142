import React from 'react';
import { MyTab } from '../../common/dto';
import TabSelector from '../../common/TabSelector';
import CreateEvent from './CreateEvent';
import ViewEvents from './ViewEvents';


export default function EventManagement() {
  
  const tabs: MyTab[] = [
    {label: 'Créer un évènement', content: <CreateEvent />},
    {label: 'Gestion des évènements', content: <ViewEvents />}
  ]

  return (
    <TabSelector tabs={tabs} />
  );
}