import React, { useState } from 'react';
import { holidaysService, accountService } from '../../services/Service';
import { Holiday, holidayType } from '../../services/HolidaysService';
import { AlertMessage } from '../../common/dto';
import { formatError } from '../../common/formatError';
import { Form, Formik } from 'formik';
import * as yup from 'yup'
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Dialog from '@mui/material/Dialog';
import FormControl from '@mui/material/FormControl';
import Fab from '@mui/material/Fab';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import InputLabel from '@mui/material/InputLabel';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import SendIcon from '@mui/icons-material/Send';
import Stack from '@mui/material/Stack';
import Step from '@mui/material/Step';
import Stepper from '@mui/material/Stepper';
import StepButton from '@mui/material/StepButton';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';


export default function Holidays() {

  const steps = ["Type d'absence", "Détails de l'absence", 'Confirmer la demande'];
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<AlertMessage>({ message: '', severity: undefined });
  const [activeStep, setActiveStep] = useState(0);
  const [open, setOpen] = useState(false);

  const totalSteps = () => {
    return steps.length;
  };

  const handleNext = () => {
    const newActiveStep = activeStep + 1;
    if (newActiveStep < totalSteps()) {
      setActiveStep(newActiveStep);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  const openHelp = () => {
    setOpen(true);
  };

  const closeHelp = () => {
    setOpen(false);
  };

  const validationSchema = yup.object().shape({
    holidaysType: yup
      .string()
      .trim()
      .required("Type d'absence requis"),
    start_date: yup
      .string()
      .trim()
      .test(
        'Date valide',
        'Date non valide',
        (value) => dayjs(value, 'DD/MM/YYYY').isValid()
      )
      .test(
        'RTT valide',
        'Vous ne pouvez pas poser 2 RTT consécutifs',
        (value, context) => context.parent.holidaysType !== 'RTT' || (context.parent.holidaysType === 'RTT' && holidaysService.isRTTValid(value))
      )
      .test(
        'Date valide',
        "Date de début ne peut pas être antérieure au 2 années précédentes",
        (value) => dayjs(value, 'DD/MM/YYYY').isAfter(dayjs().subtract(2, 'year'))
      )
      .test(
        'Date valide',
        "Date de début ne peut pas être postérieure à 2 années futures",
        (value) => dayjs(value, 'DD/MM/YYYY').isBefore(dayjs().add(2, 'year'))
      )
      .required("Date de début d'absence' requise"),
    end_date: yup
      .string()
      .trim()
      .test(
        'Date valide',
        'Date non valide',
        (value) => dayjs(value, 'DD/MM/YYYY').isValid()
      )
      .test(
        'RTT valide',
        'Vous ne pouvez pas poser 2 RTT consécutifs',
        (value, context) => context.parent.holidaysType !== 'RTT' || (context.parent.holidaysType === 'RTT' && holidaysService.isRTTValid(value))
      )
      .test(
        'Date valide',
        "Date de début ne peut pas être antérieure au 2 années précédentes",
        (value) => dayjs(value, 'DD/MM/YYYY').isAfter(dayjs().subtract(2, 'year'))
      )
      .test(
        'Date valide',
        "Date de début ne peut pas être postérieure à 2 années futures",
        (value) => dayjs(value, 'DD/MM/YYYY').isBefore(dayjs().add(2, 'year'))
      )
      .required("Date de fin d'absence requise"),
    begin_at_midday: yup
      .boolean()
      .test(
        'Date valide',
        'Date de début et de fin ne peuvent pas être le même jour quand vous commencez l apres-midi et terminez le matin',
        (value, context) => (value !== true || context.parent.end_at_midday !== true || context.parent.start_date !== context.parent.end_date)
      )
  });

  const handleDemand = async (holiday: Holiday, resetForm: () => void) => {
    try {
      await holidaysService.addHoliday(holiday);
      setMessage({ message: "Demande d'absence envoyée.", severity: 'success' });
      setActiveStep(0);
      resetForm();
    } catch (err: any) {
      setMessage({ message: formatError(err), severity: 'error' });
    } finally {
      setLoading(false);
    }
  }

  return (
    <Container sx={{ my: 6, width: '100%' }}>
      {message.message.length > 0 &&
        <Alert variant='filled' severity={message.severity} sx={{ mb: 6 }}>
          {message.message}
        </Alert>
      }

      <Formik
        initialValues={{
          holidaysType: holidayType[0] || '',
          start_date: dayjs().format('DD/MM/YYYY'),
          end_date: dayjs().format('DD/MM/YYYY'),
          begin_at_midday: false,
          end_at_midday: false
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => {
          setLoading(true);

          const start_date = dayjs(values.start_date, 'DD/MM/YYYY');
          const end_date = values.holidaysType === 'RTT' ? start_date : dayjs(values.end_date, 'DD/MM/YYYY');
          const begin_at_midday = values.holidaysType === 'RTT' ? false : values.begin_at_midday;
          const end_at_midday = values.holidaysType === 'RTT' ? false : values.end_at_midday;
          const duration = holidaysService.getHolidayDuration(start_date.format('DD/MM/YYYY'), end_date.format('DD/MM/YYYY'), begin_at_midday, end_at_midday);

          const holiday: Holiday = {
            id: 1,
            holiday_type: values.holidaysType,
            creator: 1,
            creator_fullname: accountService.getFullName(),
            start_date: start_date.format('YYYY-MM-DD'),
            end_date: end_date.format('YYYY-MM-DD'),
            begin_at_midday: begin_at_midday,
            end_at_midday: end_at_midday,
            duration: duration,
            asking_date: dayjs().format('YYYY-MM-DD').toString(),
            validation_date: '',
            reason: '',
            is_accepted: false,
          }
          handleDemand(holiday, resetForm);
        }}
      >
        {({ values, touched, errors, handleChange, setFieldValue, resetForm }) => (
          <Form noValidate autoComplete='off'>
            <Stepper alternativeLabel activeStep={activeStep}>
              {steps.map((label, index) => (
                <Step key={label} >
                  <StepButton color='inherit' onClick={handleStep(index)}>
                    {label}
                  </StepButton>
                </Step>
              ))}
            </Stepper>

            <div>
              <Dialog onClose={closeHelp} open={open}>
                <Container sx={{ my: 4, width: 'auto' }}>
                  <Typography align='left' variant='h4' sx={{ mb: 4, wordBreak: 'break-word' }}>Types d&apos;absences</Typography>
                  <Typography align='left' variant='h6' sx={{ mb: 1, wordBreak: 'break-word' }}>Congés payés</Typography>
                  <Typography align='left' sx={{ mb: 2, color: 'text.secondary', wordBreak: 'break-word' }}>Les congés payés sont accumulés sur la période du 1er juin au 31 mai de l&apos;année suivante. le compteur de congés N-1 doit être soldé au 31/05</Typography>
                  <Typography align='left' variant='h6' sx={{ mb: 1, wordBreak: 'break-word' }}>RTT</Typography>
                  <Typography align='left' sx={{ mb: 2, color: 'text.secondary', wordBreak: 'break-word' }}>Un RTT est une journée de récupération du temps de travail. Vous ne pouvez pas poser un demi RTT (vous devez poser une journée entière). Vous ne pouvez pas poser deux RTT consécutifs, Pour un jour d&apos;absence, il faut privilégier 1 RTT s&apos;il est acquis. Vous devez avoir utilisé tous vos RTT de l&apos;année en cours avant le 31 Décembre.</Typography>
                  <Typography align='left' variant='h6' sx={{ mb: 1, wordBreak: 'break-word' }}>Congé maladie</Typography>
                  <Typography align='left' sx={{ mb: 2, color: 'text.secondary', wordBreak: 'break-word' }}>La salariée est tenu d&apos;informer et fournir à son employeur une attestation sous 48h.</Typography>
                  <Typography align='left' variant='h6' sx={{ mb: 1, wordBreak: 'break-word' }}>Congé maternité</Typography>
                  <Typography align='left' sx={{ mb: 2, color: 'text.secondary', wordBreak: 'break-word' }}>Le congé maternité est divisé en deux parties: le congé prénatal d&apos;une durée de 6 semaines (8 à partir du 3ème enfant), et le congé postnatal d&apos;une durée de 10 semaines (18 à partir du 3ème enfant). Ce congé est obligatoire. Le salarié est tenu d&apos;informer son employeur de manière officielle (avec avis de réception).</Typography>
                  <Typography align='left' variant='h6' sx={{ mb: 1, wordBreak: 'break-word' }}>Congé paternité</Typography>
                  <Typography align='left' sx={{ mb: 2, color: 'text.secondary', wordBreak: 'break-word' }}>Le congé paternité comporte deux périodes distinctes. Une première période obligatoire de 4 jours calendaires prise immédiatement après la naissance et consécutivement aux 3 jours de naissance pris au moment de cet évènement et payés par l&apos;employeur. Une seconde période de 21 jours calendaires, pouvant être fractionnée en 2 périodes au plus d&apos;une durée minimale de 5 jours. Cette deuxième période doit être posée dans les 6 mois qui suivent la naissance.</Typography>
                  <Typography align='left' variant='h6' sx={{ mb: 1, wordBreak: 'break-word' }}>Congé pour évènements familiaux</Typography>
                  <Typography align='left' sx={{ mb: 2, color: 'text.secondary', wordBreak: 'break-word' }}>En cas de naissance, mariage, conclusion d&apos;un PACS ou décès d&apos;un proche, le salarié peut s&apos;absenter pendant une durée minimale de 1 à 7 jours, selon les circonstances: 4j pour le mariage ou le PACS du salarié, 1j pour le mariage d&apos;un enfant, 5j pour le décès d&apos;un enfant (7 si l&apos;enfant est agé de moins de 25ans), 3j pour le décès du conjoint, concubin, père, mère, beau-père, belle-mère, frère ou soeur (Toutefois, un accord applicable dans l&apos;entreprise peut prévoir un congé en cas de décès d&apos;autres membres de la famille). La demande d&apos;absence doit être adressée à l&apos;employeur jointe d&apos;un justificatif (certificat de décès...). Ces congés n&apos;entraînent pas de réduction de la rémunération qui tient compte, le cas échéant, des indemnités versées par la Sécurité sociale, et sont assimilés à du temps de travail effectif pour la détermination de la durée du congé payé annuel.</Typography>
                  <Typography align='left' variant='h6' sx={{ mb: 1, wordBreak: 'break-word' }}>Autre</Typography>
                  <Typography align='left' sx={{ mb: 2, color: 'text.secondary', wordBreak: 'break-word' }}>Congé parental d&apos;éducation, congé de proche aidant, congé sans solde, congé sabbatique, congé d&apos;examen...</Typography>
                </Container>
              </Dialog>

              <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                sx={{ mt: 4 }}
              >
                <Typography variant='h5' sx={{ my: 3, py: 1 }}>{steps[activeStep]}</Typography>
                {
                  activeStep == 0 &&
                  <Fab size='small' color='default' aria-label='help' style={{ transform: 'scale(0.6)' }} onClick={() => openHelp()} sx={{ ml: 2 }}>
                    <HelpOutlineIcon />
                  </Fab>
                }
              </Box>

              {{
                0:
                  <Container sx={{ minHeight: 'auto', mt: 2 }}>
                    <Box sx={{ minWidth: 200, maxwidth: 400 }}>
                      <FormControl sx={{ minWidth: 200, maxwidth: 400 }}>
                        <InputLabel>Types d&apos;absences</InputLabel>
                        <Select
                          name='holidaysType'
                          value={values.holidaysType}
                          label="Type d'absence"
                          onChange={(event) => {
                            resetForm();
                            handleChange(event);
                          }}
                        >
                          {holidayType.map((item, index) => (
                            <MenuItem value={item} key={index}>{item}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </Container>,
                1:
                  values.holidaysType === 'RTT' ?
                    <Container sx={{ minHeight: 'auto' }}>
                      <Box sx={{ mx: 3, mt: 2 }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DesktopDatePicker
                            label='Jour'
                            inputFormat='DD/MM/YYYY'
                            value={dayjs(values.start_date, 'DD/MM/YYYY')}
                            onChange={(value) => { setFieldValue('start_date', dayjs(value).format('DD/MM/YYYY'), true); }}
                            renderInput={(params) =>
                              <TextField
                                name='start_date'
                                label='start_date'
                                error={touched.start_date && Boolean(errors.start_date)}
                                helperText={errors.start_date?.toString()}
                                {...params} />}
                          />
                        </LocalizationProvider>
                      </Box>
                    </Container>
                    :
                    <Container sx={{ minHeight: 'auto' }}>
                      <Stack
                        direction='row'
                        justifyContent='center'
                        alignItems='center'
                        alignContent='center'
                        spacing={{ xs: 0, sm: 10 }}
                        sx={{ mt: 2, mb: 5 , flexWrap: 'wrap', gap: 2}}
                      >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DesktopDatePicker
                            label='Premier jour'
                            inputFormat='DD/MM/YYYY'
                            value={dayjs(values.start_date, 'DD/MM/YYYY')}
                            onChange={(value) => {
                              const startDate = dayjs(value, 'DD/MM/YYYY').startOf('day');
                              const endDate = dayjs(values.end_date, 'DD/MM/YYYY').startOf('day');
                              if (endDate.isBefore(startDate))
                                setFieldValue('end_date', dayjs(value, 'DD/MM/YYYY').format('DD/MM/YYYY'), true);
                              setFieldValue('start_date', dayjs(value, 'DD/MM/YYYY').format('DD/MM/YYYY'), true);
                            }}
                            renderInput={(params) =>
                              <TextField
                                name='start_date'
                                label='start_date'
                                error={touched.start_date && Boolean(errors.start_date)}
                                helperText={errors.start_date?.toString()}
                                {...params} />}
                          />
                        </LocalizationProvider>

                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DesktopDatePicker
                            label='Dernier jour'
                            inputFormat='DD/MM/YYYY'
                            value={dayjs(values.end_date, 'DD/MM/YYYY')}
                            onChange={(value) => {
                              const endDate = dayjs(value, 'DD/MM/YYYY').startOf('day');
                              const startDate = dayjs(values.start_date, 'DD/MM/YYYY').startOf('day');
                              if (startDate.isAfter(endDate))
                                setFieldValue('end_date', dayjs(values.start_date, 'DD/MM/YYYY').format('DD/MM/YYYY'), true);
                              else
                                setFieldValue('end_date', dayjs(value, 'DD/MM/YYYY').format('DD/MM/YYYY'), true);
                            }}
                            renderInput={(params) =>
                              <TextField
                                name='end_date'
                                label='end_date'
                                error={touched.end_date && Boolean(errors.end_date)}
                                helperText={errors.end_date?.toString()}
                                {...params} />}
                          />
                        </LocalizationProvider>
                      </Stack>

                      <Typography sx={{ mb: 2 }}>
                        Votre absence démarre l&apos;après-midi de votre premier jour ?
                      </Typography>
                      <Switch
                        name='begin_at_midday'
                        checked={values.begin_at_midday}
                        onChange={handleChange}
                      />
                      <Typography sx={{ my: 2 }}>
                        Votre absence termine en fin de matinée de votre dernier jour ?
                      </Typography>
                      <Switch
                        name='end_at_midday'
                        checked={values.end_at_midday}
                        onChange={handleChange}
                      />
                    </Container>,
                2:
                  <Container sx={{ minHeight: 'auto' }}>
                    <Typography sx={{ mt: 1 }}>
                      Types d&apos;absences : {values.holidaysType}
                    </Typography>
                    {
                      values.holidaysType === 'RTT' ?
                        <Typography sx={{ my: 3 }}>
                          {"Demande d'absence le " + values.start_date + ' (1j).'}
                        </Typography>
                        :
                        <Typography sx={{ my: 3 }}>
                          {'Votre absence débute le ' + values.start_date + (values.begin_at_midday ? " en début d'après-midi" : ' matin') + ' et termine le ' + values.end_date + (values.end_at_midday ? ' en fin de matinée (' : ' soir (') + holidaysService.getHolidayDuration(values.start_date, values.end_date, values.begin_at_midday, values.end_at_midday) + 'j).'}
                        </Typography>
                    }
                    <Box sx={{ mt: 6 }}>
                      {
                        loading ?
                          <CircularProgress />
                          :
                          <Button variant='contained' type='submit' endIcon={<SendIcon />} disabled={loading}>
                            Envoyer la demande d&apos;absence
                          </Button>
                      }
                    </Box>
                  </Container>
              }[activeStep]}

              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button
                  color='inherit'
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  Back
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />

                {activeStep < totalSteps() - 1 &&
                  <Button
                    onClick={() => {
                      handleNext();
                    }}
                    sx={{ mr: 1 }}
                    disabled={activeStep == 1 && Object.keys(errors).length > 0}>
                    Next
                  </Button>
                }
              </Box>
            </div>
          </Form>
        )}
      </Formik>
    </Container >
  );
}