import React, { useState } from 'react';
import { usersService } from '../../services/Service';
import { User } from '../../services/UsersService';
import { AlertMessage } from '../../common/dto';
import { formatError } from '../../common/formatError';
import DeleteConfirm from '../../common/DeleteConfirm';
import { useFormik } from 'formik';
import * as yup from 'yup'
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import EditIcon from '@mui/icons-material/Edit';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';


interface Props {
  user: User;
  setNotification: (msg: AlertMessage) => void;
  close: () => void;
}

export default function UserEditor(props: Readonly<Props>) {

  const [userCache] = useState<User>(props.user);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<AlertMessage>({ message: '', severity: undefined });

  const formik = useFormik({
    initialValues: {
      username: userCache.username,
      first_name: userCache.first_name,
      last_name: userCache.last_name,
      date_of_birth: dayjs(userCache.date_of_birth, 'YYYY-MM-DD').format('DD/MM/YYYY').toString(),
      email: userCache.email,
      phone: userCache.phone,
      deleted: false,
    },
    validationSchema: yup.object({
      username: yup
        .string()
        .trim()
        .required('Username requis'),
      first_name: yup
        .string()
        .trim()
        .required('Prénom requis'),
      last_name: yup
        .string()
        .trim()
        .required('Nom requis'),
      date_of_birth: yup
        .string()
        .trim()
        .test(
          'Date valide',
          'Date non valide',
          (value) => dayjs(value, 'DD/MM/YYYY').isValid()
        )
        .required('Date de naissance requise'),
      email: yup
        .string()
        .trim()
        .required('Email requis'),
      phone: yup
        .string()
        .trim().matches(/^\+?1?\d{9,15}$/, "Phone number must be entered in the format: '+999999999'. Up to 15 digits allowed."),
      deleted: yup
        .boolean(),
    }),
    onSubmit: (values) => {
      setLoading(true);

      if (values.deleted) {
        handleDelete();
      } else {
        const user: User = {
          id: props.user.id,
          username: values.username,
          first_name: values.first_name,
          last_name: values.last_name,
          email: values.email,
          date_of_birth: dayjs(values.date_of_birth, 'DD/MM/YYYY').format('YYYY-MM-DD').toString(),
          photo: props.user.photo,
          phone: values.phone,
          arrival: props.user.arrival,
          mission: props.user.mission,
          authorizations: props.user.authorizations,
          is_active: props.user.is_active
        }
        handleDemand(user);
      }
    },
  });

  const handleDelete = async () => {
    try {
      await usersService.removeUser(props.user);
      props.setNotification({ message: "L'utilisateur a bien été supprimé.", severity: 'success' });
      props.close();
    } catch (err: any) {
      setMessage({ message: formatError(err), severity: 'error' });
    } finally {
      setLoading(false);
    }
  }

  const handleDemand = async (user: User) => {
    try {
      await usersService.updateUser(user);
      setMessage({ message: 'Utilisateur modifié.', severity: 'success' });
    } catch (err: any) {
      setMessage({ message: formatError(err), severity: 'error' });
    } finally {
      setLoading(false);
    }
  }

  return (
    <Container sx={{ my: 4, width: 'auto' }}>
      {message.message.length > 0 &&
        <Alert variant='filled' severity={message.severity} sx={{ mb: 6 }}>
          {message.message}
        </Alert>
      }

      <form onSubmit={formik.handleSubmit}>
        <Typography align='left' variant='h4' sx={{ mb: 6 }}>Information du profil</Typography>

        <Typography align='left' sx={{ mt: 4, mb: 2 }}>Nom *</Typography>
        <TextField
          name='last_name'
          label='nom'
          size='small'
          value={formik.values.last_name}
          onChange={formik.handleChange}
          error={formik.touched.last_name && Boolean(formik.errors.last_name)}
          helperText={formik.touched.last_name && formik.errors.last_name}
        />

        <Typography align='left' sx={{ mt: 4, mb: 2 }}>Prénom *</Typography>
        <TextField
          name='first_name'
          label='prénom'
          size='small'
          value={formik.values.first_name}
          onChange={formik.handleChange}
          error={formik.touched.first_name && Boolean(formik.errors.first_name)}
          helperText={formik.touched.first_name && formik.errors.first_name}
        />

        <Typography align='left' sx={{ mt: 4, mb: 2 }}>Username *</Typography>
        <TextField
          name='username'
          label='username'
          size='small'
          value={formik.values.username}
          onChange={formik.handleChange}
          error={formik.touched.username && Boolean(formik.errors.username)}
          helperText={formik.touched.username && formik.errors.username}
        />

        <Typography align='left' sx={{ mt: 4, mb: 2 }}>Date de naissance *</Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            inputFormat='DD/MM/YYYY'
            label='date de naissance'
            value={dayjs(formik.values.date_of_birth, 'DD/MM/YYYY')}
            onChange={(value) => {
              const newDate = dayjs(value).format('DD/MM/YYYY');
              formik.setFieldValue('date_of_birth', newDate, true)
            }}
            renderInput={(params) =>
              <TextField
                name='birthday'
                label='date de naissance'
                size='small'
                error={formik.touched.date_of_birth && Boolean(formik.errors.date_of_birth)}
                helperText={formik.errors.date_of_birth?.toString()}
                {...params} />}
          />
        </LocalizationProvider>

        <Typography align='left' sx={{ mt: 4, mb: 2 }}>Adresse e-mail *</Typography>
        <TextField
          name='email'
          label='email'
          size='small'
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />

        <Typography align='left' sx={{ mt: 4, mb: 2 }}>Numéro de téléphone</Typography>
        <TextField
          name='phone'
          label='numéro'
          size='small'
          value={formik.values.phone}
          onChange={formik.handleChange}
          error={formik.touched.phone && Boolean(formik.errors.phone)}
          helperText={formik.touched.phone && formik.errors.phone}
        />

        {
          loading ?
            <Box
              display='flex'
              justifyContent='center'
              alignItems='center'
              sx={{ mt: 6 }}
            >
              <CircularProgress />
            </Box>
            :
            <Stack
              direction='row'
              justifyContent='space-between'
              alignItems='center'
              sx={{ mt: 6 }}
            >
              <Button variant='contained' type='submit' endIcon={<EditIcon />} disabled={loading}>
                Modifier
              </Button>
              <DeleteConfirm message={"Êtes vous sur de vouloir supprimer l'utilisateur?"} disabled={loading} action={() => { formik.setFieldValue(`deleted`, true); formik.submitForm() }} />
            </Stack>
        }
      </form>
    </Container >
  );
}