import React, { useState, Fragment } from 'react';
import { useMemoizedObservable } from 'micro-observables';
import { expensesService } from '../../services/Service';
import { Expense, Fee } from '../../services/ExpensesService';
import { AlertMessage } from '../../common/dto';
import { formatError } from '../../common/formatError';
import ExpenseDemandFormular from './ExpenseDemandFormular';
import dayjs from 'dayjs';
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import Divider from '@mui/material/Divider';
import DownloadIcon from '@mui/icons-material/Download';
import EditIcon from '@mui/icons-material/Edit';
import Fab from '@mui/material/Fab';
import InfoIcon from '@mui/icons-material/Info';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';


export default function DemandInProgress() {

  const expensesRequested = useMemoizedObservable(() => expensesService.getExpensesRequested(), []);

  const [openDetail, setOpenDetail] = useState<number>(-1);
  const [openValidation, setOpenValidation] = useState<number>(-1);
  const [message, setMessage] = useState<AlertMessage>({ message: '', severity: undefined });

  const openDetailWindow = (index: number) => {
    setOpenDetail(index);
  };

  const closeDetailWindow = () => {
    setOpenDetail(-1);
  };

  const openValidationWindow = (index: number) => {
    setOpenValidation(index);
  };

  const closeValidationWindow = () => {
    setOpenValidation(-1);
  };

  const downloadPDF = async (id: number, date: string, creator: string) => {
    try {
      await expensesService.downloadPDF(id, date, creator);
    } catch (err: any) {
      setMessage({ message: formatError(err), severity: 'error' });
    }
  }

  const downloadDocuments = async (id: number, creator: string) => {
    try {
      await expensesService.downloadDocuments(id, creator);
    } catch (err: any) {
      setMessage({ message: formatError(err), severity: 'error' });
    }
  }

  return (
    <Container>
      {message.message.length > 0 &&
        <Alert variant='filled' severity={message.severity} sx={{ mb: 6 }}>
          {message.message}
        </Alert>
      }

      <Typography align='left' variant='h4' sx={{ mt: 4, mb: 6 }}>Demandes de remboursement en cours</Typography>

      {
        expensesRequested.length == 0 ?
          <Typography align='left' variant='h5' sx={{ color: 'text.secondary', my: 4 }}>Vous n&apos;avez aucune demande de remboursement en cours pour le moment.</Typography>
          :
          <List sx={{ width: '100%', bgcolor: 'background.paper', overflowWrap: 'anywhere' }}>
            <ListItem disablePadding >
              <ListItemText primary='Demande' sx={{ width: '14%', fontSize: { xs: '10px', sm: '12px', md: '14px', lg: '16px', xl: '18px'}}} />
              <ListItemText primary='Salarié' sx={{ width: '20%', fontSize: { xs: '10px', sm: '12px', md: '14px', lg: '16px', xl: '18px'}}} />
              <ListItemText primary='Description' sx={{ width: '24%', fontSize: { xs: '10px', sm: '12px', md: '14px', lg: '16px', xl: '18px'}}} />
              <ListItemText primary='Signature' sx={{ width: '12%', textAlign: 'center', fontSize: { xs: '10px', sm: '12px', md: '14px', lg: '16px', xl: '18px'}}} />
              <ListItemText primary='Détails' sx={{ width: '10%', textAlign: 'center', fontSize: { xs: '10px', sm: '12px', md: '14px', lg: '16px', xl: '18px'}}} />
              <ListItemText primary='Valider' sx={{ width: '10%', textAlign: 'center', fontSize: { xs: '10px', sm: '12px', md: '14px', lg: '16px', xl: '18px'}}} />
              <ListItemText primary='Exporter' sx={{ width: '10%', textAlign: 'center', fontSize: { xs: '10px', sm: '12px', md: '14px', lg: '16px', xl: '18px'}}} />
            </ListItem>

            <Divider sx={{ my: 2 }} />

            {expensesRequested.map((request: Expense, index: number) => (
              <div key={index}>
                <Dialog onClose={() => { closeValidationWindow() }} open={index === openValidation}>
                  <ExpenseDemandFormular expense={request} setNotification={setMessage} />
                </Dialog>

                <Dialog onClose={() => { closeDetailWindow() }} open={openDetail === index}>
                  <Container sx={{ my: 4, width: 'auto' }}>
                    <Typography align='left' variant='h4' sx={{ mb: 4 }}>{request.description}</Typography>

                    <Typography align='left' variant='h5' sx={{ mb: 4 }}>Dépenses</Typography>
                    {
                      request.fees.map((fee: Fee, index: number) => (
                        <Typography align='left' sx={{ my: 1 }} key={index}>
                          {`Demande de reboursement d'une valeure TTC de ${fee.amount}€ (dont ${fee.tva}€ de TVA) pour le motif suivant: ${fee.description} (${dayjs(fee.expense_date).format('YYYY/MM/DD')}).`}
                        </Typography>
                      ))}

                    <Typography align='left' variant='h5' sx={{ my: 4 }}>Justificatifs</Typography>
                    {
                      request.bills.length > 0 ?
                        <div>
                          <Typography align='left' sx={{ color: 'text.secondary' }} key={index}>
                            {request.bills.map(bill => bill.title).join(', ')}
                          </Typography>
                          <Fab size='small' color='primary' sx={{ mt: 2 }} onClick={() => downloadDocuments(request.id, request.creator_fullname.replace(' ', '_'))}>
                            <DownloadIcon />
                          </Fab>
                        </div>
                        :
                        <Typography align='left' sx={{ my: 1, color: 'text.secondary' }} key={index}>
                          Aucun justificatif transmis
                        </Typography>
                    }
                  </Container>
                </Dialog>

                <ListItem key={index} disablePadding >
                  <ListItemText primary={dayjs(request.asking_date).format('DD/MM/YYYY')} sx={{ width: '14%', fontSize: { xs: '10px', sm: '12px', md: '14px', lg: '16px', xl: '18px'} }} />
                  <ListItemText primary={request.creator_fullname} sx={{ width: '20%', fontSize: { xs: '10px', sm: '12px', md: '14px', lg: '16px', xl: '18px'} }} />
                  <ListItemText primary={request.description} sx={{ width: '24%', fontSize: { xs: '10px', sm: '12px', md: '14px', lg: '16px', xl: '18px'} }} />

                  <ListItemText
                    sx={{ width: '12%', textAlign: 'center' }}
                    primary={
                      <Fragment >
                        {
                          request.is_signed ?
                            <CheckIcon sx={{ color: 'green' }} />
                            :
                            <CloseIcon sx={{ color: 'red' }} />
                        }
                      </Fragment>
                    } />

                  <ListItemText
                    sx={{ width: '16.5%', textAlign: 'center', fontSize: { xs: '10px', sm: '12px', md: '14px', lg: '16px', xl: '18px'} }}
                    primary={
                        <Fab size='small' color='primary' style={{ transform: 'scale(0.9)' }} onClick={() => { openDetailWindow(index) }}>
                          <InfoIcon />
                        </Fab>
                    } />

                  <ListItemText
                    sx={{ width: '16.5%', textAlign: 'center', fontSize: { xs: '10px', sm: '12px', md: '14px', lg: '16px', xl: '18px'} }}
                    primary={
                        <Fab size='small' color='primary' style={{ transform: 'scale(0.9)' }} onClick={() => { openValidationWindow(index) }}>
                          <EditIcon />
                        </Fab>
                    } />

                  <ListItemText
                    sx={{ width: '16.5%', textAlign: 'center', fontSize: { xs: '10px', sm: '12px', md: '14px', lg: '16px', xl: '18px'} }}
                    primary={
                        <Fab size='small' color='primary' style={{ transform: 'scale(0.9)' }} onClick={() => downloadPDF(request.id, request.asking_date, request.creator_fullname.replace(' ', '_'))}>
                          <DownloadIcon />
                        </Fab>
                    } />
                </ListItem>
              </div>
            ))}
          </List>
      }
    </Container>
  );
}