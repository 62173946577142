import React from 'react';
import ViewSelector from '../../common/ViewSelector';
import { Views } from '../../common/dto';
import ListView from './ListView';
import TableView from './TableView';


export default function ViewExpenses() {

  const views: Views = {
    listView: <ListView />,
    tableView: <TableView />,
  }

  return (
    <ViewSelector title='Mes fiches de frais' views={views} />
  );
}